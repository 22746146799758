// import {
//    translateChartsValue,
//    translateMonthsFromCharts,
//    translatePercentageFromCharts,
//  } from "../../../../helper/helperFunctions";
//  import { i18n } from "../../languageSelector";
import ApexChart from "./area-chart";
//  import { HeaderDataInterfae } from "../main-page/analytics-main";

function SingleDummyChart({
  selectedItem,
  className,
  analyticsXaxisData,
  totalResult,
  title,
  color,
  dateToShow,
}: {
  selectedItem?: any;
  className?: string;
  analyticsXaxisData: string[];
  totalResult: number;
  title: string;
  color: string;
  dateToShow?: any;
}) {
  const headData = selectedItem;
  // console.log(analyticsXaxisData, "months translated");
  return (
    <div
      className={`rounded mt-2 w-100`}
      style={{
        backgroundColor: "#ffffff",
        padding: "1rem",
        paddingTop: "12px",

        maxHeight: dateToShow ? "220px" : "200px",
        width: "370px",
        height: "100%",
      }}
    >
      <div style={{ height: "28px" }} className="d-flex  align-items-center justify-content-between ">
        <p className="m-0 ml-3 mt-3 mb-3" style={{ color: "#333333", fontSize: "17px", fontWeight: 400 }}>
          {title}
        </p>
        <div className="mr-3 mt-3 mb-3" style={{ color: "#333333", fontSize: "22px", fontWeight: 500 }}>
          {headData?.value}
        </div>{" "}
      </div>
      <div>
        <ApexChart totalResult={totalResult} analyticsXaxisData={analyticsXaxisData} headData={headData} dateToShow={dateToShow!} color={color} />
      </div>
    </div>
  );
}

export default SingleDummyChart;
