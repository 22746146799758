import { RadioButton } from "../../../Components/form-input/RadioButton";

function SingleRoleDetails({ setSelectedOption, roleForEdit }: { setSelectedOption: any; roleForEdit: any }) {
  // const [isChecked, setisChecked] = useState(true);
  // const rolePreferences = [
  //   {
  //     item: { name: "Access Forms", isChecked: true },

  //     subItems: [
  //       { name: "Create", isChecked: true },
  //       { name: "Edit", isChecked: true },
  //       { name: "Archive", isChecked: true },
  //       { name: "Delete", isChecked: true },
  //       { name: "View History", isChecked: false },
  //       { name: "Create & Edit dispatch roles", isChecked: true },
  //       { name: "Delete dispatch roles", isChecked: false },
  //       { name: "manage approval rights", isChecked: false },
  //     ],
  //   },
  //   {
  //     item: { name: "Access Reports", isChecked: true },

  //     subItems: [
  //       { name: "Delete archived reports", isChecked: true },
  //       { name: "Restore deleted reports", isChecked: true },
  //       { name: "Export other reports", isChecked: true },
  //       { name: "View history of other reports", isChecked: true },
  //     ],
  //   },
  //   {
  //     item: { name: "Access Company Setting", isChecked: true },
  //     subItems: [
  //       { name: "Manage sites", isChecked: true },
  //       { name: "Manage users", isChecked: true },
  //       { name: "Manage roles", isChecked: true },
  //       { name: "Manage  company information", isChecked: true },
  //       { name: "Manage  subscription", isChecked: true },
  //     ],
  //   },
  // ];
  // const handleChange = (e: any) => {
  //   setisChecked(e.value);
  // };
  // console.log(roleForEdit, "roleForEdit");
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Role</div>
        <button className="back-btn" onClick={() => setSelectedOption("roles")}>
          Back
        </button>
      </div>
      <div className="subscription-id">ID {roleForEdit?.buisnessId}</div>
      <div className="subscription-invoice-wrap mt-2 ">
        <div className="row">
          <div className="col-3">Role</div>
          <div className="col">{roleForEdit?.name}</div>
        </div>
      </div>
      <div className="subscription-invoice-wrap mt-3">
        <div className={`row ${roleForEdit?.name === "guest" ? "pt-4" : ""}`}>
          {roleForEdit && (
            <>
              <div className="col-3">Manager portal</div>
              <div className="col">
                <div className="mt-2">
                  <RadioButton disabled={false} isSelected={roleForEdit?.managerRole} name="radio" id="radio" onChange={() => {}} />
                </div>
                {/* <div className="roles-wrap"> */}
                {roleForEdit?.name !== "guest" &&
                  roleForEdit?.managerRole &&
                  roleForEdit?.managerPortal?.map((item: any, index: number) => {
                    return (
                      <div className="roles-wrap" key={index}>
                        <div className="role-checkbox-wrap my-3">
                          <input
                            type="checkbox"
                            className="custom-user-checkbox"
                            style={{
                              height: "15px",
                              width: "15px",
                              border: "none",
                              background: "#F2F2F2",
                            }}
                            checked={item?.selected}
                          />
                          <div>{item.name}</div>
                        </div>
                        {item?.subItem?.map((element: any, index: number) => {
                          return (
                            <div className="sub-items" key={index}>
                              <input
                                type="checkbox"
                                className="custom-user-checkbox"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  border: "none",
                                  background: "#F2F2F2",
                                }}
                                checked={element?.selected}
                                disabled={element?.disabled}
                              />
                              <div>{element?.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
        <div className={`row mt-4 ${roleForEdit?.name === "guest" ? "pb-5" : ""}`}>
          {/* inspector portal */}
          {roleForEdit && (
            <>
              <div className="col-3">Inspector portal</div>
              <div className="col">
                <div className="mt-3">
                  <RadioButton disabled={false} isSelected={roleForEdit?.inspectorRole} name="radio" id="radio" onChange={() => {}} />
                </div>

                {/* <div className="roles-wrap"> */}

                {roleForEdit?.name !== "guest" &&
                  roleForEdit?.inspectorRole &&
                  roleForEdit?.inspectorPortal?.map((item: any, index: number) => {
                    return (
                      <div className="roles-wrap" key={index}>
                        <div className="role-checkbox-wrap my-3">
                          <input
                            type="checkbox"
                            className="custom-user-checkbox"
                            style={{
                              height: "15px",
                              width: "15px",
                              border: "none",
                              background: "#F2F2F2",
                            }}
                            checked={item?.selected}
                          />
                          <div>{item.name}</div>
                        </div>
                        {item?.subItem?.map((element: any, index: number) => {
                          return (
                            <div className="sub-items" key={index}>
                              <input
                                type="checkbox"
                                className="custom-user-checkbox"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  border: "none",
                                  background: "#F2F2F2",
                                }}
                                checked={element?.selected}
                                disabled={element?.disabled}
                              />
                              <div>{element?.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleRoleDetails;
