import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/images/CloseIcon.svg";
import SearchImage from "../../assets/images/Search.svg";
import CompaniesHeader from "./CompaniesHeader";
import { GenaricListDTO } from "./DTO/formDTO";
import ColumnFilter from "./columnFilter/ColumnFilter";
import FilterForm from "./filterForm/FilterForm";
import SuccessMessage from "./modal/SuccessMessage";
import Table from "./table/Table";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import * as companyActions from "../.././redux/companies/companiesAction";
import CompanyActionModal from "../../Components/modal/CompanyActionModal";
import { refactorCompanies, refactorStatusListBasedOnSelectedPlan, sortTableColumns } from "../../helper/helperFunction";
import * as authActions from "../.././redux/auth/authAction";
import { StoreState } from "../../redux/storeState/storeState";
import { useSidebar } from "../useSidebarProvider";
function Companies() {
  const [filterDropdownOpen, setfilterDropdownOpen] = useState(false);
  const [columnDropdownOpen, setColumnDropdownOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [groupActionOpen, setGroupActionOpen] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [deleteModaOpen, setdeleteModaOpen] = useState(false);
  const [suspendModaOpen, setSuspendModaOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [showSuccessDeletion, setshowSuccessDeletion] = useState(false);
  const [showSuccessSuspend, setShowSuccessSuspend] = useState(false);
  const [showSuccessEmail, setShowSuccessEmail] = useState(false);
  const [isDeleteCompanies, setisDeleteCompanies] = useState(false);
  const [showSuccessResume, setShowSuccessResume] = useState(false);
  const [showResumeOption, setshowResumeOption] = useState(false);
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState<string>("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(0);

  const [isCompanySuspended, setisCompanySuspended] = useState({ value: false, id: 0 });
  const [countryList, setCountryList] = useState<GenaricListDTO[]>([]);
  const [planList, setPlanList] = useState<GenaricListDTO[]>([
    {
      id: "1",
      isSelected: true,
      label: "Free",
      value: "Free",
    },
    {
      id: "2",
      isSelected: true,
      label: "Trial",
      value: "Trial",
    },
    {
      id: "3",
      isSelected: true,
      label: "Monthly Subscription",
      value: "Monthly Subscription",
    },
    {
      id: "4",
      isSelected: true,
      label: "Annual Subscription",
      value: "Yearly Subscription",
    },
  ]);
  const [seatsList, setSeatsList] = useState<GenaricListDTO[]>([
    {
      id: "1",
      isSelected: true,
      label: "1-5",
      value: "1",
    },

    {
      id: "2",
      isSelected: true,
      label: "6-20",
      value: "6-20",
    },

    {
      id: "3",
      isSelected: true,
      label: "21-50",
      value: "21-50",
    },

    {
      id: "4",
      isSelected: true,
      label: "51-100",
      value: "51-100",
    },

    {
      id: "5",
      isSelected: true,
      label: "101-200",
      value: "101-200",
    },
    {
      id: "6",
      isSelected: true,
      label: "201-500",
      value: "201-500",
    },
    {
      id: "7",
      isSelected: true,
      label: "500 +",
      value: "500 +",
    },
  ]);
  const [statusList, setstatusList] = useState<GenaricListDTO[]>([
    {
      id: "1",
      isSelected: true,
      label: "Active",
      value: "free",
    },
    {
      id: "2",
      isSelected: true,
      label: "Running",
      value: "running",
    },
    {
      id: "3",
      isSelected: true,
      label: "Expired",
      value: "expired",
    },
    {
      id: "4",
      isSelected: true,
      label: "Trial is running",
      value: "trial",
    },
    {
      id: "5",
      isSelected: true,
      label: "Paused",
      value: "paused",
    },
    {
      id: "6",
      isSelected: true,
      label: "Suspended",
      value: "suspended",
    },
    {
      id: "7",
      isSelected: true,
      label: "Unsubscribed, subscription is running",
      value: "Unsubscribed, subscription is running",
    },
  ]);

  const [columnHeaders, setColumnHeaders] = useState<GenaricListDTO[]>([
    {
      id: "1",
      label: "Business ID",
      value: "buisnessId",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "2",
      label: "Business name",
      value: "businessName",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "3",
      label: "Owner name",
      value: "ownerName",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "4",
      label: "Company email",
      value: "companyEmail",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "5",
      label: "Country",
      value: "country",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "6",
      label: "Plan",
      value: "plan",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "7",
      label: "Seats",
      value: "seats",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "8",
      label: "Status",
      value: "status",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "9",
      label: "Actions",
      value: "Actions",
      isSelected: true,
    },
  ]);
  const [companiesList, setcompaniesList] = useState<GenaricListDTO[]>([]);
  const filterRef = useRef<any>();
  const columnRef = useRef<any>();
  const searchRef = useRef<any>();
  const groupActionRef = useRef<any>();
  const [companyLoading, setcompanyLoading] = useState(false);
  const [deleteTextColor, setDeleteTextColor] = useState("#EB5757");
  const [isfilterBtnColorApplied, setIsfilterBtnColorApplied] = useState(false);
  const [isColumnFilterBtnColorApplied, setIsColumnFilterBtnColorApplied] = useState(false);
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  const [deletedCompanyLoading, setdeletedCompanyLoading] = useState(true);
  const [selectedHeader, setselectedHeader] = useState<GenaricListDTO>(columnHeaders[0]);
  const [countryLoading, setcountryLoading] = useState(true);
  const [countryListChangeLoading, setcountryListChangeLoading] = useState(false);
  const [feildToSort, setfeildToSort] = useState("buisnessId");
  // eslint-disable-next-line
  const [sortBy, setSortBy] = useState("asc");

  // use selector
  const { companiesData, countries, companiesPage, companiesTotal, user, companiesSize } = useSelector((state: StoreState | any) => ({
    companiesData: state?.company?.companies,
    companiesTotal: state?.company?.total,
    companiesPage: state?.company?.page,
    countries: state?.company?.countries,
    user: state?.auth?.user,
    companiesSize: state?.company?.size,
  }));

  // const authState = useSelector((state: StoreState) => state.auth);
  // console.log(authState,'user from auth')

  // console.log(sortBy);
  useEffect(() => {
    dispatch(authActions.whoAmI());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      let tableHeaderObject = columnHeaders.map((item, index) => {
        return {
          ...item,
          isSelected:
            user && user?.companySettings && user?.companySettings?.companyTableToShow?.length > 0
              ? user?.companySettings?.companyTableToShow?.includes(item?.label)
                ? true
                : false
              : true,
        };
      });
      setColumnHeaders([...tableHeaderObject]);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (companiesTotal > 0 && total === 0) {
      setPage(companiesPage);
      setTotal(companiesTotal);
      setSize(companiesSize);
    }
    // setSize();

    // eslint-disable-next-line
  }, [companiesTotal]);

  const handleOutsideClick = (event: any) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setfilterDropdownOpen(false);
    }
  };
  const handleOutsideClickColumn = (event: any) => {
    if (columnRef.current && !columnRef.current.contains(event.target)) {
      setColumnDropdownOpen(false);
    }
  };
  const handleOutsideClickSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      if (!closeSearchBarIfValue) {
        // console.log("i ran for open should");
        setSearchBarOpen(true);
      } else {
        // console.log("i ran for close should");
        setSearchBarOpen(false);
      }
    }
  };

  const handleOutsideClickGroupAction = (event: any) => {
    if (groupActionRef.current && !groupActionRef.current.contains(event.target)) {
      setGroupActionOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickColumn);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickColumn);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickGroupAction);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickGroupAction);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSearch);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSearch);
    };

    // eslint-disable-next-line
  }, [closeSearchBarIfValue]);

  //country select All

  const handleSelectAllCountry = () => {
    let refactoredArr;
    if (countryList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = countryList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = countryList.map((item) => {
        return { ...item, isSelected: false };
      });
      // setcompanyLoading(true);
    }
    setCountryList([...refactoredArr]);
    setcountryLoading(true);
    setcountryListChangeLoading(true);
  };

  //status select All

  const handleSelectAllStatus = () => {
    let refactoredArr;
    if (statusList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = statusList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = statusList.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    setstatusList([...refactoredArr]);
    setcountryLoading(true);
  };
  const handleSelectAllSeats = () => {
    let refactoredArr;
    if (seatsList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = seatsList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = seatsList.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    setSeatsList([...refactoredArr]);
    setcountryLoading(true);
  };
  const handleSelectAllPlan = () => {
    let refactoredArr;
    if (planList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = planList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = planList.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    setPlanList([...refactoredArr]);
    setcountryLoading(true);
  };

  //country single select
  const handleSelectCountry = (id: string) => {
    let refactoredArr = countryList.map((item) => {
      if (item.id === id) {
        // setcompanyLoading(true);
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setCountryList([...refactoredArr]);
    setcountryLoading(true);
    setcountryListChangeLoading(true);
  };
  const handleSelectStatus = (id: string) => {
    let refactoredArr = statusList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });

    setstatusList([...refactoredArr]);
    setcountryLoading(true);
  };

  const handleSelectSeats = (id: string) => {
    let refactoredArr = seatsList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setSeatsList([...refactoredArr]);
    setcountryLoading(true);
  };
  const handleSelectPlan = (id: string) => {
    let refactoredArr = planList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setPlanList([...refactoredArr]);
    setcountryLoading(true);
  };
  // console.log(size, "size rrr");
  const countrySearch = (countries: any) => {
    let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
    let seats = seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);
    let status: any;
    let isSuspended = undefined;

    if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
      status = statusList.filter((item) => item.isSelected).map((item) => item.label);
      if (status.includes("Active") && !status.includes("Suspended")) {
        status.push("Free");
        let finded = status.findIndex((item: any) => item === "Active");
        status.splice(finded, 1);
        isSuspended = false;
      }
      if (status.includes("Active") && status.includes("Suspended")) {
        status.push("Free");
        let finded = status.findIndex((item: any) => item === "Active");
        status.splice(finded, 1);
        // eslint-disable-next-line
        isSuspended = undefined;
      }
    }

    if (page > 0) {
      dispatch(
        companyActions.getAllCompanies({
          page: page,
          size: size,
          countries: countries,
          subscriptionStatus: status,
          subscriptionPlan: plans,
          subscriptionSeats: seats,
          sortBy: selectedHeader.sortBy,
          sortAt: feildToSort,
        })
      ).then(() => {
        setcompanyLoading(true);
        setcountryLoading(false);
        setcountryListChangeLoading(false);
      });
    }
  };
  const handleCountrySearch = (() => {
    // return () => {
    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    // }

    return (searchString: any) => {
      setTimeout(() => {
        dispatch(companyActions.getAllCountries(searchString, countrySearch)).then((res: any) => {
          setcompanyLoading(true);
        });
      }, 200);
    };
    // }
  })();

  const handleSelectColumn = (id: string) => {
    let refactoredArr = columnHeaders.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    const filterSelectedHeader = refactoredArr.filter((item) => item.isSelected).map((subItem) => subItem.label);
    companyTableHandler(filterSelectedHeader?.length > 0 ? filterSelectedHeader : [""]);
    setColumnHeaders([...refactoredArr]);
  };

  const handleSelectAllColumns = () => {
    let refactoredArr;
    if (columnHeaders?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = columnHeaders.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = columnHeaders.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    const filterSelectedHeader = refactoredArr.filter((item) => item.isSelected).map((subItem) => subItem.label);
    companyTableHandler(filterSelectedHeader?.length > 0 ? filterSelectedHeader : [""]);
    setColumnHeaders([...refactoredArr]);
  };

  const companyTableHandler = (dataToUpdate: string[]) => {
    dispatch(companyActions.companyUserToUpdate(dataToUpdate, false));
  };

  const handleReset = () => {
    // if (countryList.length > 0) {
    //   let resetCountryList = countryList?.map((item) => {
    //     return { ...item, isSelected: true };
    //   });
    //   setCountryList(resetCountryList);
    //   let resetStatusList = statusList.map((item) => {
    //     return { ...item, isSelected: true };
    //   });
    //   setstatusList(resetStatusList);
    //   let resetSeatsList = seatsList.map((item) => {
    //     return { ...item, isSelected: true };
    //   });
    //   setSeatsList(resetSeatsList);
    //   let resetPlanList = planList.map((item) => {
    //     return { ...item, isSelected: true };
    //   });
    //   setPlanList(resetPlanList);
    //   setcountryLoading(true);
    //   setcompanyLoading(true);

    // } else {
    //   console.log("i ran for else case");
    // dispatch(companyActions.getAllCountries()).then(() => {
    dispatch(companyActions.getAllCompanies({ page: page, size: size })).then(() => {
      dispatch(companyActions.getAllCountries());
      setcompanyLoading(true);
      setcountryLoading(true);
      let resetCountryList = countryList?.map((item) => {
        return { ...item, isSelected: true };
      });
      setCountryList(resetCountryList);
      let resetStatusList = statusList.map((item) => {
        return { ...item, isSelected: true };
      });
      setstatusList(resetStatusList);
      let resetSeatsList = seatsList.map((item) => {
        return { ...item, isSelected: true };
      });
      setSeatsList(resetSeatsList);
      let resetPlanList = planList.map((item) => {
        return { ...item, isSelected: true };
      });
      setPlanList(resetPlanList);
    });
    // });

    // }
  };

  const handleSelectTableCheckbox = (id: string) => {
    let refactoredArr = companiesList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setcompaniesList([...refactoredArr]);
  };

  const handleSelectAllTableCheckbox = () => {
    let refactoredArr: any;
    if (companiesList && companiesList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = companiesList.map((item) => {
        return { ...item, isSelected: true };
      });
      setcompaniesList([...refactoredArr!]);
    } else {
      if (companiesList) {
        refactoredArr =
          companiesList &&
          companiesList?.map((item) => {
            return { ...item, isSelected: false };
          });
        setcompaniesList([...refactoredArr!]);
      }
    }
  };
  // console.log(companiesPage, "page from company");

  const dispatch: AppDispatch | any = useDispatch();

  // const { companies } = useSelector((state: any) => ({
  //   companies: state?.company?.companies,
  // }));
  useEffect(() => {
    const selectedFilters = getSelectedFilters();
    if (page > 0 && size > 0) {
      dispatch(
        companyActions.getAllCompanies({
          page: page,
          size: size,
          countries: selectedFilters.countries,
          subscriptionStatus: selectedFilters.status,
          subscriptionPlan: selectedFilters.plans,
          subscriptionSeats: selectedFilters.seats,
          sortBy: selectedHeader.sortBy,
          sortAt: feildToSort,
        })
      ).then(() => {
        dispatch(companyActions.getAllCountries());
        setcompanyLoading(true);
      });
    } else {
      dispatch(
        companyActions.getAllCompanies({
          page: 1,
          size: 10,
          countries: selectedFilters.countries,
          subscriptionStatus: selectedFilters.status,
          subscriptionPlan: selectedFilters.plans,
          subscriptionSeats: selectedFilters.seats,
          sortBy: selectedHeader.sortBy,
          sortAt: feildToSort,
        })
      ).then(() => {
        dispatch(companyActions.getAllCountries());
        setcompanyLoading(true);
      });
    }

    // eslint-disable-next-line
  }, [page, size]);
  // console.log(size, "size kkkk");

  useEffect(() => {
    refactorCompanies(companiesData, companyLoading, setcompaniesList, setcompanyLoading);
    // console.log(size, "size in companydata and companyloading dependency");

    // eslint-disable-next-line
  }, [companiesData, companyLoading]);

  useEffect(() => {
    const uniqueLabels: any = [];
    // console.log(size, "i ran when country dependency");

    const unique = countries?.filter((element: any) => {
      const isDuplicate = uniqueLabels.includes(element.label);

      if (!isDuplicate) {
        uniqueLabels.push(element.label);
        return true;
      }
      return false;
    });
    setCountryList(unique);

    // eslint-disable-next-line
  }, [countries]);

  useEffect(() => {
    refactorStatusListBasedOnSelectedPlan(planList, setstatusList);
    // eslint-disable-next-line
  }, [planList]);
  // console.log(companiesList, "company uu");

  useEffect(() => {
    // console.log(size, "i ran when filters dependency");

    if (countryLoading) {
      let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected).map((item) => item.label);
      let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
      let seats = seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);
      let status: any;
      let isSuspended = undefined;

      if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
        status = statusList.filter((item) => item.isSelected).map((item) => item.label);
        if (status.includes("Active") && !status.includes("Suspended")) {
          status.push("Free");
          let finded = status.findIndex((item: any) => item === "Active");
          status.splice(finded, 1);
          isSuspended = false;
        }
        if (status.includes("Active") && status.includes("Suspended")) {
          status.push("Free");
          let finded = status.findIndex((item: any) => item === "Active");
          status.splice(finded, 1);
          isSuspended = undefined;
        }
      }
      if (countryList?.length > 0 && countryList?.filter((item) => !item.isSelected)?.length > 0) {
        setIsfilterBtnColorApplied(true);
      } else if (planList?.length > 0 && planList?.filter((item) => !item.isSelected)?.length > 0) {
        setIsfilterBtnColorApplied(true);
      } else if (statusList?.length > 0 && statusList?.filter((item) => !item.isSelected)?.length > 0) {
        setIsfilterBtnColorApplied(true);
      } else if (seatsList?.length > 0 && seatsList?.filter((item) => !item.isSelected)?.length > 0) {
        setIsfilterBtnColorApplied(true);
      } else {
        setIsfilterBtnColorApplied(false);
      }

      if (page > 0) {
        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: countries,
            subscriptionStatus: status,
            subscriptionPlan: plans,
            subscriptionSeats: seats,
            sortBy: selectedHeader.sortBy,
            sortAt: feildToSort,
            isSuspended: isSuspended,
          })
        ).then(() => {
          setcompanyLoading(true);
          setcountryLoading(false);
          setcountryListChangeLoading(false);
        });
      }
    }

    // eslint-disable-next-line
  }, [planList, statusList, seatsList, countryListChangeLoading]);

  const getSelectedFilters = () => {
    let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected).map((item) => item.label);
    let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
    let seats = seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);
    let status: any;
    let isSuspended = undefined;
    if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
      status = statusList.filter((item) => item.isSelected).map((item) => item.label);
      if (status.includes("Active") && !status.includes("Suspended")) {
        status.push("Free");
        let finded = status.findIndex((item: any) => item === "Active");
        status.splice(finded, 1);
        isSuspended = false;
      }
      if (status.includes("Active") && status.includes("Suspended")) {
        status.push("Free");
        let finded = status.findIndex((item: any) => item === "Active");
        status.splice(finded, 1);
        // eslint-disable-next-line
        isSuspended = undefined;
      }
    }
    return { countries, plans, seats, status };
  };

  const handleCancel = () => {
    if (suspendModaOpen) {
      setSuspendModaOpen(false);
      setMessage("");
    } else if (deleteModaOpen) {
      setdeleteModaOpen(false);
    } else if (emailModalOpen) {
      setEmailModalOpen(false);
    } else if (showResumeOption) {
      setshowResumeOption(false);
      setMessage("");
    }
  };
  const resetSearchingHandler = () => {
    let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected).map((item) => item.label);
    let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
    let seats = seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);

    let status: any;
    let isSuspended = undefined;

    if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
      status = statusList.filter((item) => item.isSelected).map((item) => item.label);
      if (status.includes("Active") && !status.includes("Suspended")) {
        status.push("Free");
        let finded = status.findIndex((item: any) => item === "Active");
        status.splice(finded, 1);
        isSuspended = false;
      }
      if (status.includes("Active") && status.includes("Suspended")) {
        status.push("Free");
        let finded = status.findIndex((item: any) => item === "Active");
        status.splice(finded, 1);
        // eslint-disable-next-line
        isSuspended = undefined;
      }
    }

    dispatch(
      companyActions.getAllCompanies({
        page: page,
        size: size,
        countries: countries,
        subscriptionStatus: status,
        subscriptionPlan: plans,
        subscriptionSeats: seats,
        sortBy: selectedHeader.sortBy,
        sortAt: feildToSort,
      })
    ).then(() => {
      setcompanyLoading(true);
    });
  };
  const handleConfirmation = () => {
    if (suspendModaOpen) {
      //suspend selected countries

      let selectedCompanyIdsForSuspension = companiesList?.filter((item: any) => item.isSelected === true).map((item) => item.mongoId);
      dispatch(companyActions.suspendSelectedCompanies(selectedCompanyIdsForSuspension, reason)).then(() => {
        setReason("");
        let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected).map((item) => item.label);
        let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
        let seats =
          seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);
        let status: any;
        let isSuspended = undefined;

        if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
          status = statusList.filter((item) => item.isSelected).map((item) => item.label);
          if (status.includes("Active") && !status.includes("Suspended")) {
            status.push("Free");
            let finded = status.findIndex((item: any) => item === "Active");
            status.splice(finded, 1);
            isSuspended = false;
          }
          if (status.includes("Active") && status.includes("Suspended")) {
            status.push("Free");
            let finded = status.findIndex((item: any) => item === "Active");
            status.splice(finded, 1);
            // eslint-disable-next-line
            isSuspended = undefined;
          }
        }

        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: countries,
            subscriptionStatus: status,
            subscriptionPlan: plans,
            subscriptionSeats: seats,
            sortBy: selectedHeader.sortBy,
            sortAt: feildToSort,
          })
        ).then(() => {
          setcompanyLoading(true);
        });
      });

      setSuspendModaOpen(false);
      setMessage("");
      setShowSuccessSuspend(true);
      setGroupActionOpen(false);
    } else if (showResumeOption) {
      //resume selected companies
      setcompanyLoading(true);
      let selectedCompanyIdsForResume = companiesList?.filter((item: any) => item.isSelected === true).map((item) => item.mongoId);
      dispatch(companyActions.resumeSuspendedCompanies(selectedCompanyIdsForResume)).then(() => {
        let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected).map((item) => item.label);
        let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
        let seats =
          seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);
        let status: any;
        let isSuspended = undefined;

        if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
          status = statusList.filter((item) => item.isSelected).map((item) => item.label);
          if (status.includes("Active") && !status.includes("Suspended")) {
            status.push("Free");
            let finded = status.findIndex((item: any) => item === "Active");
            status.splice(finded, 1);
            isSuspended = false;
          }
          if (status.includes("Active") && status.includes("Suspended")) {
            status.push("Free");
            let finded = status.findIndex((item: any) => item === "Active");
            status.splice(finded, 1);
            // eslint-disable-next-line
            isSuspended = undefined;
          }
        }

        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: countries,
            subscriptionStatus: status,
            subscriptionPlan: plans,
            subscriptionSeats: seats,
            sortBy: selectedHeader.sortBy,
            sortAt: feildToSort,
          })
        );
      });
      setshowResumeOption(false);
      setMessage("");
      setShowSuccessResume(true);
      setGroupActionOpen(false);
    } else if (deleteModaOpen) {
      // delete selected companies

      let selectedCompanyIdsForDeletion = companiesList?.filter((item: any) => item.isSelected === true).map((item) => item.mongoId);
      dispatch(companyActions.deleteSelectedCompanies(selectedCompanyIdsForDeletion)).then(() => {
        const selectedFilters = getSelectedFilters();
        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: selectedFilters.countries,
            subscriptionStatus: selectedFilters.status,
            subscriptionPlan: selectedFilters.plans,
            subscriptionSeats: selectedFilters.seats,
            sortBy: selectedHeader.sortBy,
            sortAt: feildToSort,
          })
        ).then(() => {
          setcompanyLoading(true);
        });
      });
      setdeleteModaOpen(false);
      setshowSuccessDeletion(true);
      setGroupActionOpen(false);
    } else if (emailModalOpen) {
      let selectedCompaniesToSendEmail = companiesList?.filter((item: any) => item.isSelected === true).map((item: any) => item.email);
      dispatch(companyActions.sendEmailToCompany(selectedCompaniesToSendEmail, { subject: emailSubject, message: emailMessage })).then(() => {
        setEmailSubject("");
        setEmailMessage("");
      });
      setEmailModalOpen(false);
      setShowSuccessEmail(true);
      setGroupActionOpen(false);
    }
  };
  // console.log(countryList, "countryList");
  // console.log(companyLoading, "comapnyloading rrr");
  const handleSearchCompany = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected).map((item) => item.label);
        let plans = planList.filter((item) => !item.isSelected)?.length !== 0 && planList.filter((item) => item.isSelected).map((item) => item.value);
        let seats =
          seatsList.filter((item) => !item.isSelected)?.length !== 0 && seatsList.filter((item) => item.isSelected).map((item) => item.label);
        let status: any;
        let isSuspended = undefined;

        if (statusList.filter((item) => !item.isSelected)?.length !== 0) {
          status = statusList.filter((item) => item.isSelected).map((item) => item.label);
          if (status.includes("Active") && !status.includes("Suspended")) {
            status.push("Free");
            let finded = status.findIndex((item: any) => item === "Active");
            status.splice(finded, 1);
            isSuspended = false;
          }
          if (status.includes("Active") && status.includes("Suspended")) {
            status.push("Free");
            let finded = status.findIndex((item: any) => item === "Active");
            status.splice(finded, 1);
            // eslint-disable-next-line
            isSuspended = undefined;
          }
        }
        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchString,
            countries: countries,
            subscriptionStatus: status,
            subscriptionPlan: plans,
            subscriptionSeats: seats,
            sortBy: selectedHeader.sortBy,
            sortAt: feildToSort,
          })
        ).then(() => {
          setcompanyLoading(true);
        });
        if (searchString !== "") {
          setCloseSearchBarIfValue(false);
          // console.log("i ran inside for open should");
        } else {
          setCloseSearchBarIfValue(true);
          // console.log("i ran inside for close should");
        }
      }, 200);
    };
  })();
  const handleSort = (item: any) => {
    sortTableColumns(item, setfeildToSort);
    setselectedHeader(item);
  };

  //sorting

  useEffect(() => {
    if (page > 0) {
      const selectedFilters = getSelectedFilters();
      dispatch(
        companyActions.getAllCompanies({
          countries: selectedFilters.countries,
          subscriptionStatus: selectedFilters.status,
          subscriptionPlan: selectedFilters.plans,
          subscriptionSeats: selectedFilters.seats,
          page: page,
          size: size,
          sortAt: feildToSort,
          sortBy: selectedHeader.sortBy,
        })
      ).then(() => setcompanyLoading(true));
    }

    // if (feildToSort === "buisnessId") {
    let sortIcon = document.getElementById("sort-icon");
    sortIcon?.classList.toggle("toggle-icon");
    // }
    // eslint-disable-next-line
  }, [selectedHeader.sortBy, feildToSort]);

  const handleOpenResumeModal = () => {
    let finded = companiesList?.filter((item: any) => item.isSelected).map((item) => item.isSuspended);
    if (finded) {
      setisCompanySuspended({ value: true, id: 0 });
      setshowResumeOption(true);
    }
  };
  //suspend reason
  const handleReason = (event: any) => {
    setReason(event.target.value);
  };

  useEffect(() => {
    if (companiesList?.length > 0) {
      let finded = companiesList?.filter((item: any) => item.isSelected).filter((item) => !item.isSuspended);
      if (finded.length > 0) {
        setisCompanySuspended({ ...isCompanySuspended, id: 0, value: false });
      } else {
        setisCompanySuspended({ value: true, id: 0 });
      }
    }
    // console.log("suspended use effect run");

    // eslint-disable-next-line
  }, [companiesList, companyLoading]);

  useEffect(() => {
    //selected company is able to delete if its on free plan, expired trial or paused subscription
    if (companiesList?.length > 0) {
      let status = companiesList.filter(
        (item: any) =>
          item.isSelected &&
          item.subscription.currentPlan.status !== "Paused" &&
          item.subscription.currentPlan.status !== "Free" &&
          item.subscription.currentPlan.status !== "Expired"
      );
      if (status?.length > 0) {
        setDeleteTextColor("#828282");
      } else {
        setDeleteTextColor("#EB5757");
      }
    }
    // console.log("group action open and status list dependency");

    // eslint-disable-next-line
  }, [groupActionOpen, statusList]);

  useEffect(() => {
    let selectedColumns = columnHeaders.filter((item) => !item.isSelected)?.length > 0;
    if (selectedColumns) {
      setIsColumnFilterBtnColorApplied(true);
    } else {
      setIsColumnFilterBtnColorApplied(false);
    }
  }, [columnHeaders]);
  

  // useEffect(() => {
  //   const selectedFilters = getSelectedFilters();
  //   if (page > 0) {
  //     dispatch(
  //       companyActions.getAllCompanies({
  //         page: page,
  //         size: size,
  //         searchName: searchValue,
  //         countries: selectedFilters.countries,
  //         subscriptionStatus: selectedFilters.status,
  //         subscriptionPlan: selectedFilters.plans,
  //         subscriptionSeats: selectedFilters.seats,
  //         sortBy: selectedHeader.sortBy,
  //         sortAt: feildToSort,
  //       })
  //     ).then(() => {
  //       setcompanyLoading(true);
  //     });
  //   }

  //   // eslint-disable-next-line
  // }, [isDeleteCompanies]);

  // console.log(selectedHeader, "header sss");

  const handleArrowSortClick = () => {
    if (selectedHeader) {
      setfeildToSort(selectedHeader.value!);
    }
    if (selectedHeader.sortBy === "asc") {
      selectedHeader.sortBy = "desc";
    } else {
      selectedHeader.sortBy = "asc";
    }
  };
  const { collapsed, setselectedItem } = useSidebar();
  // console.log(sortBy, "sort by kkk");
  setselectedItem("");
  return (
    <div className="pb-2" style={{ overflowX: "hidden", background: "rgb(242, 243, 248)" }}>
      <div>
        <CompaniesHeader isDeleteCompanies={isDeleteCompanies} setisDeleteCompanies={setisDeleteCompanies} />
        {!isDeleteCompanies ? (
          <div className="companies-main-wrap" style={{ marginLeft: collapsed ? "1rem" : "17rem" }}>
            <div className="d-flex justify-content-between align-items-center" style={{ gap: "25px" }}>
              <div className="filters">
                <div
                  className={
                    companiesList?.length && companiesList.filter((e) => e.isSelected).length > 0 ? "normal filter-div " : "disabled filter-div "
                  }
                  style={{ whiteSpace: "nowrap", position: "relative" }}
                  ref={groupActionRef}
                >
                  <span
                    onClick={() => {
                      setGroupActionOpen(!groupActionOpen);
                    }}
                  >
                    Group Action
                  </span>
                  {groupActionOpen && companiesList?.filter((e) => e.isSelected).length > 0 ? (
                    <CompanyActionModal
                      suspendModalOpen={suspendModaOpen}
                      emailModalOpen={emailModalOpen}
                      deleteModaOpen={deleteModaOpen}
                      showResumeOption={showResumeOption}
                      openDeleteModalHandler={() => setdeleteModaOpen(true)}
                      openEmailModalHandler={() => setEmailModalOpen(true)}
                      openSuspendModalHandler={() => setSuspendModaOpen(true)}
                      openResumeHandler={handleOpenResumeModal}
                      reason={reason}
                      // setReason={setReason}
                      handleReason={handleReason}
                      message={message}
                      setMessage={setMessage}
                      setEmailMessage={setEmailMessage}
                      setEmailSubject={setEmailSubject}
                      emailMessage={emailMessage}
                      emailSubject={emailSubject}
                      onCancel={handleCancel}
                      onConfirmation={handleConfirmation}
                      isCompanySuspended={isCompanySuspended.value}
                      textDeleteColor={deleteTextColor}
                      style={{ top: "-10px" }}
                    />
                  ) : null}
                  {showSuccessDeletion ? (
                    <SuccessMessage setshowSuccessCompletion={setshowSuccessDeletion} message="Company deleted successfully " />
                  ) : (
                    ""
                  )}
                  {showSuccessSuspend ? (
                    <SuccessMessage setshowSuccessCompletion={setShowSuccessSuspend} message="Company suspended successfully " />
                  ) : null}
                  {showSuccessEmail ? (
                    <SuccessMessage setshowSuccessCompletion={setShowSuccessEmail} message="The email was successfully sent" />
                  ) : null}
                  {showSuccessResume ? (
                    <SuccessMessage setshowSuccessCompletion={setShowSuccessResume} message="Company resumed successfully" />
                  ) : null}
                </div>
                <div className={`filter-wrap ${isfilterBtnColorApplied ? "active" : "normal "}`} ref={filterRef} style={{ position: "relative" }}>
                  <span
                    onClick={() => {
                      setfilterDropdownOpen(!filterDropdownOpen);
                    }}
                  >
                    Filter
                  </span>
                  {filterDropdownOpen ? (
                    <FilterForm
                      countryList={countryList ? countryList : []}
                      seatsList={seatsList}
                      planList={planList}
                      statusList={statusList}
                      setCountryList={setCountryList}
                      setSeatsList={setSeatsList}
                      setstatusList={setstatusList}
                      setPlanList={setPlanList}
                      handleSelectAllCountry={handleSelectAllCountry}
                      handleSelectAllPlan={handleSelectAllPlan}
                      handleSelectAllSeats={handleSelectAllSeats}
                      handleSelectAllStatus={handleSelectAllStatus}
                      handleSelectCountry={handleSelectCountry}
                      handleSelectPlan={handleSelectPlan}
                      handleSelectSeats={handleSelectSeats}
                      handleSelectStatus={handleSelectStatus}
                      handleReset={handleReset}
                      handleCountrySearch={handleCountrySearch}
                    />
                  ) : null}
                </div>
                <div className={`filter-div ${isColumnFilterBtnColorApplied ? "active" : "normal"}`} ref={columnRef} style={{ position: "relative" }}>
                  <span
                    onClick={() => {
                      setColumnDropdownOpen(!columnDropdownOpen);
                    }}
                  >
                    Columns
                  </span>
                  {columnDropdownOpen ? (
                    <ColumnFilter
                      columnHeaders={columnHeaders}
                      setColumnHeaders={setColumnHeaders}
                      isHeaderSelected={columnDropdownOpen}
                      handleSelectAllColumns={handleSelectAllColumns}
                      handleSelectColumn={handleSelectColumn}
                    />
                  ) : null}
                </div>
              </div>
              <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
                <div style={{ width: "100%", height: "34px" }}>
                  {searchBarOpen ? (
                    <input
                      value={searchValue}
                      onChange={(e: any) => {
                        setsearchValue(e.target.value);
                        handleSearchCompany(e.target.value);
                      }}
                      type="text"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        outline: "none",
                        background: "#F2F2F2",
                        borderRadius: "6px",
                        padding: "1rem",
                        color: searchValue.length ? "#4F4F4F" : "#BDBDBD",
                        fontWeight: !searchValue.length ? 500 : 700,
                      }}
                      className="searchBar"
                      placeholder="Search"
                    />
                  ) : null}

                  <div style={{ position: "absolute", top: 3, right: 20 }}>
                    {searchValue.length && searchBarOpen ? (
                      <img
                        src={closeIcon}
                        alt="search icon"
                        onClick={() => {
                          setsearchValue("");
                          resetSearchingHandler();
                          setCloseSearchBarIfValue(true);
                        }}
                      />
                    ) : (
                      <img
                        src={SearchImage}
                        alt="search icon"
                        onClick={() => {
                          setSearchBarOpen(!searchBarOpen);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Table
                tableHeaderState={columnHeaders}
                companiesList={companiesList}
                handleSelectTableCheckbox={handleSelectTableCheckbox}
                handleSelectAllTableCheckbox={handleSelectAllTableCheckbox}
                page={+page}
                setSize={setSize}
                setPage={setPage}
                size={size}
                total={total}
                setTotal={setTotal}
                handleSort={handleSort}
                companySuspend={isCompanySuspended}
                setCompanyLoading={setcompanyLoading}
                countryList={countryList}
                planList={planList}
                statusList={statusList}
                seatsList={seatsList}
                setDeleteCompanyLoading={setdeletedCompanyLoading}
                deleteCompanyLoading={deletedCompanyLoading}
                sortField={feildToSort}
                sortBy={selectedHeader?.sortBy}
                searchValue={searchValue}
                getSelectedFilters={getSelectedFilters}
                setSortField={setfeildToSort}
                setSortBy={setSortBy}
                handleArrowSortClick={handleArrowSortClick}
                selectedHeader={selectedHeader}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Companies;
