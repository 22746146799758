import { useNavigate } from "react-router-dom";
import { useSidebar } from "../useSidebarProvider";

function CompaniesHeader({ isDeleteCompanies, setisDeleteCompanies }: { isDeleteCompanies: boolean; setisDeleteCompanies: any }) {
  const { collapsed } = useSidebar();
  const navigate = useNavigate();
  return (
    <div className="row companies-header" style={{ paddingLeft: !collapsed ? "20rem" : "4rem" }}>
      <div
        className={`${isDeleteCompanies ? "" : "active"}`}
        onClick={() => {
          setisDeleteCompanies(false);
          navigate("/companies");
        }}
      >
        Companies List
      </div>
      <div
        className={`${!isDeleteCompanies ? "" : "active"}`}
        onClick={() => {
          setisDeleteCompanies(true);
          navigate("/deleted-companies");
        }}
      >
        Deleted List
      </div>
    </div>
  );
}

export default CompaniesHeader;
