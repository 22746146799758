import * as requestFromServer from "./companiesCrud";
import { companiesSlice, callTypes } from "./companiesSlice";
import { toast } from "react-hot-toast";
import { AppDispatch } from "../store";
import { CompanyRoleSearchDTO, CompanyUsersSearchDTO, InvoiceEmailPayload, SiteSearchDTO } from "../../interfaces/companyInterface";

const { actions } = companiesSlice;

export const getAllCompanies = (data?: any) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllCompanies(data)
    .then((response) => {
      // console.log(response, "company ttt in action");

      dispatch(
        actions.getAllCompanies({
          companies: response?.data?.results,
          page: response?.data?.page,
          total: response?.data?.totalCount,
          size: response?.data?.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getCompanySubscriptionById = (data?: any) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getCompanySubscriptionById(data)
    .then((response) => {
      // console.log(response, "response rrrrrrr");
      dispatch(
        actions.getCompanySubscriptionById({
          subscriptionData: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const deleteSelectedCompanies = (ids: string[]) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .deleteSelectedCompanies(ids)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
    });
};

export const getAllCountries = (searchString?: string, countrySearch?: any) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllCountries(searchString)
    .then((res: any) => {
      let data = res?.data;
      let countries = data.map((item: any) => item.label);
      if (countrySearch) {
        countrySearch(countries);
      }
      dispatch(
        actions.getAllCountries({
          countries: res.data,
          countryLoading: false,
        })
      );
    })
    .catch((error: any) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const suspendSelectedCompanies = (ids: string[], reason: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .suspendSelectedCompanies(ids, reason)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
    });
};
export const getCompanyById = (data?: any) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getCompanyById(data)
    .then((response) => {
      dispatch(
        actions.getCompanyById({
          company: response?.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;

      // toast.error(error?.response?.data?.message);
    });
};

export const sendEmailToCompany = (ids: string[], emailData: { subject: string; message: string }) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .emailSendToCompany(ids, emailData)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getCompanyLogsById = (data: any) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getCompanyLogsById(data)
    .then((response) => {
      dispatch(
        actions.getCompanyLogsById({
          logs: response?.data?.logs,
          totalCount: response?.data?.totalCount,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getSubscriptionInvoiceById = (id: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSubscriptionInvoiceById(id)
    .then((response) => {
      // console.log(response, "from api for invoices");
      dispatch(
        actions.getSubscriptionInvoiceById({
          invoiceData: response?.data,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.getSubscriptionInvoiceById({ invoiceData: undefined }));
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

// export const getCompanySubscriptionById = (data: any) => (dispatch: AppDispatch | any) => {
//   dispatch(
//     actions.startCall({
//       callType: callTypes.list,
//     })
//   );
//   return requestFromServer
//     .getCompanySubscriptionById(data)
//     .then((response) => {
//       console.log(response, "response from subscription data ");
//       dispatch(
//         actions.getCompanySubscriptionById({
//           subscriptionData: response?.data,
//         })
//       );
//     })
//     .catch((error) => {
//       error.clientMessage = error.message;
//       console.log(error, "subscription error");
//       dispatch(
//         actions.catchError({
//           error,
//           callType: callTypes.action,
//         })
//       );
//       toast.error(error?.response?.data?.message);
//     });
// };
export const resumeSuspendedCompanies = (ids: string[]) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .resumeSuspendedCompanies(ids)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
    });
};

export const companyUserToUpdate = (dataToUpdate: string[], isDeletedCompanyAction: boolean) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .updateComapnyUser(dataToUpdate, isDeletedCompanyAction)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
    });
};

export const sendInvoiceEmailToCompany = (data: InvoiceEmailPayload) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .sendInvoiceEmailToCompany(data)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,

          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getAllCompanyUsers = (searchState: CompanyUsersSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllCompanyUsers(searchState)
    .then((res) => {
      dispatch(
        actions.getAllCompanyUsers({
          users: res.data.refactorUsers,
          total: res.data.totalCount,
          page: res.data.page,
          size: res.data.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
    });
};

export const getAllCompanySites = (searchState: SiteSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return (
    requestFromServer
      // .getAllCompanyUsers(searchState)
      // .then((res) => {
      //   console.log(res, "res from action for users");
      //   dispatch(
      //     actions.getAllCompanyUsers({
      //       users: res.data.refactorUsers,
      //       total: res.data.totalCount,
      //       page: res.data.page,
      //       size: res.data.size,
      .getAllCompanySites(searchState)
      .then((res) => {
        dispatch(
          actions.getAllCompanySites({
            sites: res.data.sites,
            page: res.data.page,
            size: res.data.size,
            totalCount: res.data.totalCount,
          })
        );
      })
      .catch((error) => {
        error.clientMessage = error.message;
        dispatch(
          actions.catchError({
            error,
            callType: callTypes.action,
          })
        );
        toast.error(error?.response?.data?.message);
      })
  );
};

export const getCompanyPaymentCardById = (id: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getCompanyPaymentCardDataById(id)
    .then((res: any) => {
      dispatch(
        actions.getCompanyPaymentCardById({
          paymentCard: res?.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,

          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getCompanyUserById = (id: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSingleUserById(id)
    .then((res) => {
      dispatch(
        actions.getSingleCompanyUserById({
          user: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getUserChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getUserChartsDataByCompanyId(id, startDate, endDate)
    .then((res) => {
      dispatch(
        actions.getUserChartsDataByCompanyId({
          chartsData: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};

export const getSingleCompanySiteById = (id: string) => (dispatch: AppDispatch | any) => {
  // export const sendInvoiceEmailToCompany = (data:InvoiceEmailPayload)=> (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSingleCompanySiteById(id)
    .then((res: any) => {
      dispatch(
        actions.getSingleCompanySiteById({
          singleSite: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};

// export const sendInvoiceEmailToCompany = (data: InvoiceEmailPayload) => (dispatch: AppDispatch | any) => {
//   dispatch(
//     actions.startCall({
//       callType: callTypes.list,
//     })
//   );
//   return requestFromServer
//     .sendInvoiceEmailToCompany(data)
//     .then((res: any) => {})
//     .catch((error) => {
//       error.clientMessage = error.message;
//       dispatch(
//         actions.catchError({
//           error,
//           callType: callTypes.action,
//         })
//       );
//       toast.error(error?.message);
//     });
// };
// export const getCompanyPaymentCardById = (id: string) => (dispatch: AppDispatch | any) => {
//   dispatch(
//     actions.startCall({
//       callType: callTypes.list,
//     })
//   );
//   return requestFromServer
//     .getCompanyPaymentCardDataById(id)
//     .then((res: any) => {
//       dispatch(
//         actions.getCompanyPaymentCardById({
//           paymentCard: res?.data,
//         })
//       );
//     })
//     .catch((error) => {
//       error.clientMessage = error.message;
//       dispatch(
//         actions.getCompanyPaymentCardById({
//           paymentCard: undefined,
//         })
//       );
//       // dispatch(
//       //   actions.catchError({
//       //     error,
//       //     callType: callTypes.action,
//       //     // errorType: "emailNotSent",
//       //   })
//       // );
//       // toast.error(error?.response?.data?.message);
//     });
// };

export const getSiteChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSitesChartsDataByCompanyId(id, startDate, endDate)
    .then((res) => {
      dispatch(
        actions.getSiteChartsDataByCompanyId({
          chartsData: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

//forms
export const getAllCompanyForms = (searchState: SiteSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllCompanyForms(searchState)
    .then((res) => {
      dispatch(
        actions.getAllCompanyForms({
          forms: res.data.forms,
          page: res.data.page,
          size: res.data.size,
          totalCount: res.data.formsTotalCount,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getRolesChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getRolesChartsDataByCompanyId(id, startDate, endDate)
    .then((res) => {
      dispatch(
        actions.getUserRolesDataByCompanyId({
          rolesData: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getAllCompanyRoles = (searchState: CompanyRoleSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllCompanyRoles(searchState)
    .then((res) => {
      dispatch(
        actions.getAllCompanyRoles({
          roles: res.data.roles,
          total: res.data.totalCount,
          page: res.data.page,
          size: res.data.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getFormsChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getFormsChartsDataByCompanyId(id, startDate, endDate)
    .then((res) => {
      dispatch(
        actions.getFormsChartsDataByCompanyId({
          chartsData: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getCompanyRoleById = (id: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSingleRoleById(id)
    .then((res) => {
      // console.log(res, "res single  user");
      dispatch(
        actions.getSingleCompanyRoleById({
          role: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getSingleCompanyFormById = (id: string) => (dispatch: AppDispatch | any) => {
  // export const sendInvoiceEmailToCompany = (data:InvoiceEmailPayload)=> (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSingleCompanyFormById(id)
    .then((res: any) => {
      dispatch(
        actions.getSingleCompanyFormById({
          form: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getFormDispatchRuleById = (id: string) => (dispatch: AppDispatch | any) => {
  // export const sendInvoiceEmailToCompany = (data:InvoiceEmailPayload)=> (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getFormDispatchRuleById(id)
    .then((res: any) => {
      dispatch(
        actions.getFormDispatchRuleById({
          form: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const restoreDeletedFormById = (id: string) => (dispatch: AppDispatch | any) => {
  // export const sendInvoiceEmailToCompany = (data:InvoiceEmailPayload)=> (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .restoreDeletedFormById(id)
    .then((res: any) => {
      // console.log(res, "res from restore form api");
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

//reports
export const getAllCompanyReports = (searchState: SiteSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllCompanyReports(searchState)
    .then((res) => {
      // console.log(res, "res from action");
      dispatch(
        actions.getAllCompanyReports({
          reports: res.data.reports,
          page: res.data.page,
          size: res.data.size,
          totalCount: res.data.reportTotalCount,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getReportsChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getReportsChartsDataByCompanyId(id, startDate, endDate)
    .then((res) => {
      dispatch(
        actions.getReportsChartsDataByCompanyId({
          chartsData: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const getSingleCompanyReportById = (id: string) => (dispatch: AppDispatch | any) => {
  // export const sendInvoiceEmailToCompany = (data:InvoiceEmailPayload)=> (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getSingleCompanyReportById(id)
    .then((res: any) => {
      dispatch(
        actions.getSingleCompanyReportById({
          report: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const restoreReportById = (id: string) => (dispatch: AppDispatch | any) => {
  // export const sendInvoiceEmailToCompany = (data:InvoiceEmailPayload)=> (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .restoreReportById(id)
    .then((res: any) => {
      // toast.success("Report restored successfully");
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
export const getAllReportSettingUsers = (id: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllReportSettingUsersByReportId(id!)
    .then((res) => {
      // console.log(res, "res from action");
      dispatch(
        actions.getAllReportSettingUsers({
          users: res.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
      console.log(error?.response?.data?.message);
    });
};
export const getSingleReportSettingByUserAndReportId = (userId: string, reportId: string) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getReportSettingByUserAndReportId(userId, reportId)
    .then((res) => {
      // console.log(res, "res from action");
      dispatch(
        actions.getSingleReportSettingByUserAndReportId({
          reportSetting: res?.data?.userReportSetting,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
