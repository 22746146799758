import sendIcon from "../../../assets/images/sendIcon.svg";
import moment from "moment";
import ConfirmationModal from "../../companies/modal/ConfirmationModal";
import SuccessMessage from "../../companies/modal/SuccessMessage";
import { invoiceTranslation } from "../../../helper/helperFunction";
function InvoiceDetails({
  setSelectedOption,
  invoiceData,
  companyDetails,
  handleSendInvoice,
  showInvoiceSendModal,
  onCancel,
  handleOpenInvoice,
  setShowSuccessModal,
  showSuccessModal,
  subscriptionData,
}: {
  setSelectedOption: any;
  invoiceData: any;
  companyDetails: any;
  handleSendInvoice: any;
  showInvoiceSendModal: any;
  onCancel: any;
  handleOpenInvoice: any;
  setShowSuccessModal: any;
  showSuccessModal: any;
  subscriptionData: any;
}) {
  // console.log(invoiceData, "inner kkk");
  let SubTotal =
    invoiceData?.subscriptionData?.currentPlan?.plan !== "Additional seats"
      ? invoiceData?.subscriptionData?.currentPlan?.seats * invoiceData?.subscriptionData?.currentPlan?.price
      : invoiceData?.subscriptionData?.lastAdditionalseats?.seats * invoiceData?.subscriptionData?.currentPlan?.price;
  let taxPrice = (15 / 100) * SubTotal;
  let total = taxPrice + SubTotal;
  // console.log(companyDetails,'details')
  return (
    <div>
      <div className="main-overview-wrap" style={{ position: "relative" }}>
        <div className="title-subscription">Invoice details</div>
        <div className="d-flex ">
          <button className="back-btn" onClick={() => setSelectedOption("subscription")}>
            Back
          </button>
          <button className="send-btn" onClick={handleOpenInvoice}>
            <span>Send</span>
            <img src={sendIcon} alt="sendIcon" />
          </button>
        </div>
        <div style={{ position: "absolute", top: 39, right: 330 }}>
          {showInvoiceSendModal ? (
            <>
              <ConfirmationModal
                title="Are you sure you want send this invoice to company email ?"
                btnText="Send"
                btnColor="#20639B"
                onCancel={onCancel}
                onConfirmation={handleSendInvoice}
                actions
                handleSucccessConfirmation={onCancel}
              />
            </>
          ) : null}
          {showSuccessModal && <SuccessMessage message="The invoice was successfully sent" setshowSuccessCompletion={setShowSuccessModal} />}
        </div>
      </div>
      <div className="data-wrap mt-4">
        <div className="subscription-invoice-wrap ">
          <div className="title">Details</div>
          <div className="d-flex justify-content-between align-items-center my-2">
            <span>Description:</span>
            <span>{invoiceTranslation(invoiceData.subscriptionData?.currentPlan?.plan)}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center my-2">
            <span>Subscription ID :</span>
            <span>{invoiceData.subscriptionData?.subscriptionId}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Date :</span>
            <span>{moment(invoiceData?.subscriptionData?.currentPlan?.createdAt).format("DD MMM YYYY")}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Invoice number : </span>
            <span>A1-{invoiceData?.invoiceDummyId}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Reference number : </span>
            <span>{invoiceData?.paymentMetaData?.refrenceNumber}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Total: </span>
            <span>
              {" "}
              {invoiceData?.currency}{" "}
              {total
                ?.toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="subscription-invoice-wrap my-2 ">
        <div className="title">For</div>
        <div className="my-2">{companyDetails?.name}</div>
        <div className="my-2">Tax no. {subscriptionData?.taxNumber}</div>
        <div className="my-2">{companyDetails?.country}</div>
      </div>
      <div className="subscription-invoice-wrap ">
        <div className="title">Summary :</div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <span>Plan:</span>
          <span>{invoiceData?.subscriptionData?.currentPlan?.plan}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Price:</span>
          <span>
            {invoiceData?.currency} {invoiceData?.subscriptionData?.currentPlan?.price.toFixed(2)}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Seats: </span>
          <span>
            {" "}
            {invoiceData?.subscriptionData?.currentPlan?.plan !== "Additional seats"
              ? invoiceData?.subscriptionData?.currentPlan?.seats
              : invoiceData?.subscriptionData?.lastAdditionalseats?.seats}{" "}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Billing Period</span>
          <span>
            {moment(invoiceData?.subscriptionData?.subscriptionStartedAt).format("DD MMM")} -&nbsp;
            {moment(invoiceData?.subscriptionData?.subscriptionEndedAt).format("DD MMM YYYY")}
          </span>
        </div>
      </div>
      <div className="subscription-invoice-wrap mt-2 ">
        {/* <div className="title">Sub total :</div> */}
        <div className="d-flex justify-content-between align-items-center my-2">
          <span className="title-bold">Sub total:</span>
          <span className="title-bold">
            {invoiceData?.currency}{" "}
            {SubTotal.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Discount</span>
          <span> {invoiceData?.currency} 0.00</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span className="title-bold">Total befor tax </span>
          <span className="title-bold">
            {" "}
            {invoiceData?.currency}{" "}
            {SubTotal?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Vat. 15%</span>
          <span>
            {" "}
            {invoiceData?.currency} {taxPrice.toFixed(2)}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span className="title-bold">Total</span>
          <span className="title-bold">
            {" "}
            {invoiceData?.currency}{" "}
            {total
              .toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDetails;
