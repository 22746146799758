import { createSlice } from "@reduxjs/toolkit";
import { companiesInterface } from "../../interfaces/companiesInterface";
import { GenaricListDTO } from "../../Pages/companies/DTO/formDTO";
import {
  CompanyForEdit,
  CompanyLogsForEditDTO,
  CompanySubscriptionDTO,
  companyForEditInitialState,
  companySubscriptionState,
} from "../../interfaces/companyInterface";

export interface CompaniesState {
  loading: boolean;
  listLoading: boolean;
  actionsLoading: boolean;
  error: null;
  companies: companiesInterface;
  page: number;
  total: number;
  size: number;
  companyForEdit: CompanyForEdit;
  countries: GenaricListDTO[];
  companyLogsForEdit: CompanyLogsForEditDTO[];
  totalCount: number;
  subscriptionForEdit: CompanySubscriptionDTO;
  invoiceForEdit: any;
  companyUsers: any;
  userPage: number;
  userSize: number;
  totalUserCount: number;
  userChartsData: any;
  singleUserForEdit: any;
  rolesChartsData: any;
  companyRoles: any;
  totalRolesCount: number;
  rolesPage: number;
  rolesSize: number;
  roleForEdit: any;
  companySites: any[];
  totalSiteCount: number;
  sitesPage: number;
  siteSize: number;
  singleSiteForEdit: any;
  siteChartsData: any;
  paymentCardForEdit: any;
  allCompanyForms: any;
  formsPage: number;
  formsSize: number;
  totalFormsCount: number;
  singleFormForEdit: any;
  formsChartData: any;
  formDispatchRule: any;
  companyReports: any;
  reportsPage: number;
  reportSize: number;
  totalReportCount: number;
  singleReportForEdit: any;
  reportChartData: any;
  allReportSettingUsers: any;
  singleUserReportSetting: any;
}

const initialCompaniesState: CompaniesState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  page: 0,
  total: 0,
  size: 0,
  countries: [],
  companies: {
    countries: [],
    startDate: "",
    endDate: "",
    size: 0,
    page: 0,
    subscriptionPlan: [],
    subscriptionStatus: [],
    subscriptionSeats: [],
  },
  paymentCardForEdit: {},
  invoiceForEdit: {},
  subscriptionForEdit: companySubscriptionState,
  // companyLogsLimit:0,
  companyForEdit: companyForEditInitialState,
  companyLogsForEdit: [
    {
      _id: "",
      createdAt: "",
      createdBy: "",
      logMessage: "",
      detailedMessage: "",
    },
  ],
  totalCount: 0,
  companyUsers: [],
  totalUserCount: 0,
  userPage: 0,
  userSize: 0,
  userChartsData: [],
  singleUserForEdit: {},
  rolesChartsData: [],
  companyRoles: [],
  totalRolesCount: 0,
  rolesPage: 0,
  rolesSize: 0,
  roleForEdit: {},
  companySites: [],
  totalSiteCount: 0,
  sitesPage: 0,
  siteSize: 0,
  singleSiteForEdit: {},
  siteChartsData: [],
  allCompanyForms: [],
  totalFormsCount: 0,
  formsPage: 0,
  formsSize: 0,
  singleFormForEdit: {},
  formsChartData: [],
  formDispatchRule: [],
  companyReports: [],
  reportChartData: [],
  reportSize: 0,
  reportsPage: 0,
  singleReportForEdit: {},
  totalReportCount: 0,
  allReportSettingUsers: [],
  singleUserReportSetting: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState: initialCompaniesState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    // get companies

    getAllCompanies: (state, action) => {
      const { companies, total, page, size } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companies = companies;
      state.total = total;
      state.page = page;
      state.size = size;
    },
    getCompanyPaymentCardById: (state, action) => {
      const { paymentCard } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.paymentCardForEdit = paymentCard;
    },
    getCompanyById: (state, action) => {
      const { company } = action.payload;
      // console.log(company, "company from response");
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companyForEdit = company;
    },
    getCompanyLogsById: (state, action) => {
      const { logs, totalCount } = action.payload;
      // console.log(logs, "logs from response");
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companyLogsForEdit = logs;
      state.totalCount = totalCount;
    },
    getCompanySubscriptionById: (state, action) => {
      const { subscriptionData } = action.payload;
      state.actionsLoading = false;
      state.subscriptionForEdit = subscriptionData;
      state.error = null;
      state.loading = false;
    },

    getAllCompanySites: (state, action) => {
      const { sites, totalCount, page, size } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companySites = sites;
      state.sitesPage = page;
      state.siteSize = size;
      state.totalSiteCount = totalCount;
    },
    // deleteSelectedCompanies: (state, action) => {
    //    const { companies, total, page } = action.payload;
    //    state.actionsLoading = false;
    //    state.error = null;
    //    state.companies = companies;
    //    state.total = total;
    //    state.page = page;
    // }
    // state.page = page;
    // },
    getAllCountries: (state, action) => {
      const { countries } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.countries = countries;
    },
    // getSubscriptionInvoiceById: (state, action) => {
    //   const { invoiceData } = action.payload;
    //   console.log(invoiceData, "invoice data kkkk");
    // },
    getSiteChartsDataByCompanyId: (state, action) => {
      const { chartsData } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.siteChartsData = chartsData;
    },
    getSingleCompanySiteById: (state, action) => {
      const { singleSite } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.singleSiteForEdit = singleSite;
    },
    getSubscriptionInvoiceById: (state, action) => {
      const { invoiceData } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.invoiceForEdit = invoiceData;
    },
    getAllCompanyForms: (state, action) => {
      const { forms, page, size, totalCount } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.allCompanyForms = forms;
      state.formsPage = page;
      state.formsSize = size;
      state.totalFormsCount = totalCount;
    },
    getFormsChartsDataByCompanyId: (state, action) => {
      const { chartsData } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.formsChartData = chartsData;
    },
    getAllCompanyUsers: (state, action) => {
      const { users, page, size, total } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companyUsers = users;
      state.userPage = page;
      state.userSize = size;
      state.totalUserCount = total;
    },
    getSingleCompanyUserById: (state, action) => {
      const { user } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.singleUserForEdit = user;
    },
    getUserChartsDataByCompanyId: (state, action) => {
      const { chartsData } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.userChartsData = chartsData;
    },
    getSingleCompanyFormById: (state, action) => {
      const { form } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.singleFormForEdit = form;
    },
    getFormDispatchRuleById: (state, action) => {
      const { form } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.formDispatchRule = form;
    },
    getAllCompanyReports: (state, action) => {
      const { reports, page, size, totalCount } = action.payload;
      // console.log(action.payload, "payload");
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companyReports = reports;
      state.reportsPage = page;
      state.reportSize = size;
      state.totalReportCount = totalCount;
    },
    getReportsChartsDataByCompanyId: (state, action) => {
      const { chartsData } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.reportChartData = chartsData;
    },
    getSingleCompanyReportById: (state, action) => {
      const { report } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.singleReportForEdit = report;
    },
    getAllReportSettingUsers: (state, action) => {
      const { users } = action.payload;
      // console.log(action.payload, "payload");
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.allReportSettingUsers = users;
    },
    getSingleReportSettingByUserAndReportId: (state, action) => {
      const { reportSetting } = action.payload;
      // console.log(action.payload, "payload");
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.singleUserReportSetting = reportSetting;
    },
    getUserRolesDataByCompanyId: (state, action) => {
      const { rolesData } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.rolesChartsData = rolesData;
    },
    getAllCompanyRoles: (state, action) => {
      const { roles, page, size, total } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.companyRoles = roles;
      state.rolesPage = page;
      state.rolesSize = size;
      state.totalRolesCount = total;
    },
    getSingleCompanyRoleById: (state, action) => {
      const { role } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.roleForEdit = role;
    },
  },
});
