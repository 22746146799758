import { createSlice } from "@reduxjs/toolkit";
import { DeletedCompaniesInterface } from "../../interfaces/companiesInterface";

export interface DeletedCompaniesState {
  loading: boolean;
  listLoading: boolean;
  actionsLoading: boolean;
  error: null;
  deletedCompaniesData: DeletedCompaniesInterface |any;
  deletedCompanyPage: number;
  size: number;
  totalResults: number;
  deletedCountries: []
  
}
const initialDeletedCompanyState: DeletedCompaniesState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: null,
  deletedCompanyPage: 0,
  totalResults: 0,
  size:0,
  deletedCountries:[],
  deletedCompaniesData: {
    countries: [],
    days: [],
    startDate: "",
    endDate: "",
    size: 0,
    page: 0,
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const deletedCompanySlice = createSlice({
  name: "deletedCompanies",
  initialState: initialDeletedCompanyState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    // get deleted companies
    getAllDeletedCompanies: (state, action) => {
      const { deletedCompanies, total, page,size } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.deletedCompaniesData = deletedCompanies;
      state.totalResults = total;
      state.deletedCompanyPage = page;
      state.size = size;
    },
    getAllDeletedCountries: (state, action) => {
      const { countries  } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loading = false;
      state.deletedCountries = countries;
    },
  },
});
