import SingleDummyChart from "./charts/SingleChart";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CompanyDetails({ chartsToShow, xAxisData, chartsLoading }: { chartsToShow: any; xAxisData: any; chartsLoading: any }) {
  return (
    <div className="company-overview h-100">
      <div className="main-overview-wrap">
        <div>Overview</div>
        <button className="back-btn" onClick={() => window.history.back()}>
          Back
        </button>
      </div>
      <div className="data-wrap mt-4">
        <div className=" usage-wrap ">
          <div className="d-flex justify-content-between align-items-center">
            <span>Data Usage:</span>
            <span>15,458MB</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-4">
            <span>15,458/20,000</span>
            <span>76%</span>
          </div>
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "76%" }} aria-valuenow={76} aria-valuemin={0} aria-valuemax={100}></div>
          </div>
        </div>
        <div className="storage-wrap" style={{ borderRadius: "7.74px", background: "#FFFFFF" }}>
          <div className="d-flex justify-content-between align-items-center">
            <span>Data Storage:</span>
            <span>2.16 GB</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-4">
            <span>2.13/3.00</span>
            <span>75%</span>
          </div>
          <div className="data-storage">
            {" "}
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: "75%" }}
                aria-valuenow={75}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2" style={{ display: "flex", gap: "10px", width: "100%" }}>
        {!chartsLoading ? (
          <>
            <SingleDummyChart
              title={chartsToShow[0]?.title}
              analyticsXaxisData={xAxisData}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[0]}
              color="#56CCF2"
              totalResult={chartsToShow[0]?.value}
            />
            <SingleDummyChart
              title={chartsToShow[1]?.title}
              analyticsXaxisData={xAxisData}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[1]}
              color="#F2994A"
              totalResult={chartsToShow[1]?.value}
            />
          </>
        ) : (
          <>
            <div className="bg-white circle p-2 w-50">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
            <div className="bg-white circle p-2 w-50">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
          </>
        )}
      </div>
      <div className="mt-2" style={{ display: "flex", gap: "10px", width: "100%" }}>
        {!chartsLoading ? (
          <>
            <SingleDummyChart
              title={chartsToShow[2]?.title}
              analyticsXaxisData={xAxisData}
              selectedItem={chartsToShow[2]}
              className=" shadow-lg mt-3  w-100 "
              totalResult={chartsToShow[2]?.value}
              color="#BB6BD9"
            />
            <SingleDummyChart
              title={chartsToShow[3]?.title}
              analyticsXaxisData={xAxisData}
              selectedItem={chartsToShow[3]}
              className=" shadow-lg mt-3  w-100 "
              totalResult={chartsToShow[3]?.value}
              color="#219653"
            />
          </>
        ) : (
          <>
            <div className="bg-white circle p-2 w-50">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
            <div className="bg-white circle p-2 w-50">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CompanyDetails;
