import { statusForAllSelectedPlan, statusForFreePlan, statusForMonthlyPlan, statusForTrialPlan } from "../Pages/companies/filterForm/constant";

export const TEXT_DIRECTION = () => {
  let direction = document.getElementsByTagName("html")[0].getAttribute("dir");
  return direction;
};

export const CHECK_ARRAY_IS_EMPTY_OR_NOT = (array: any[]) => {
  if (array && array.length && array.length > 0) {
    return true;
  } else return false;
};

// export const refactorCountries = (companies: any, setCountryList: any, companyLoading?: boolean, setcompanyLoading?: any,countryList?:any) => {
//   if (companies && companyLoading && countryList.length<=0 ) {
//     console.log('refactore countries run kkkk')
//     let allCountries = companies?.map((item: any) => item.country);
//     for (let i = 0; i < allCountries?.length; i++) {
//       let findeIndex = allCountries?.findIndex((item: any) => item.label === allCountries[i].label);
//     console.log(findeIndex,'index kkkk')
//       allCountries?.splice(findeIndex, 1);
//     }
//     let refactoredCountries = allCountries?.map((item: any, index: number) => {
//       return { label: item, isSelected: true, id: index + 1 };
//     });
//     setCountryList(refactoredCountries);
//     console.log(refactoredCountries,"3rd refactored countries fron helper functoin after duplicate removal");
//     setcompanyLoading(false);
//   }
// };
export const refactorCompanies = (companies: any, companyLoading: any, setcompaniesList: any, setcompanyLoading: any) => {
  if (companies && companyLoading) {
    let refactoredCompanies;
    // if (companies.length > 0) {
    refactoredCompanies =
      companies.length > 0 &&
      companies?.map((item: any) => {
        return { ...item, isSelected: false, id: item.buisnessId, mongoId: item._id, label: item.companyName };
      });
    // }

    setcompaniesList(refactoredCompanies);
    setcompanyLoading(false);
  }
};
export const refactorStatusListBasedOnSelectedPlan = (planList: any, setstatusList: any) => {
  if (planList.length > 0) {
    let updatedStatusList: any = [];
    planList.forEach((plan: any) => {
      if (plan.isSelected) {
        if (plan.label === "Free" && plan.isSelected) {
          updatedStatusList = [];
          updatedStatusList = statusForFreePlan;
          setstatusList(updatedStatusList);
        } else if (plan.label === "Trial" && plan.isSelected) {
          updatedStatusList = [];
          updatedStatusList = statusForTrialPlan;
          setstatusList(updatedStatusList);
        } else if (plan.label === "Monthly Subscription" && plan.isSelected) {
          updatedStatusList = [];
          updatedStatusList = statusForMonthlyPlan;
          setstatusList(updatedStatusList);
        } else {
          updatedStatusList = [];
          updatedStatusList = statusForAllSelectedPlan;
          setstatusList(updatedStatusList);
        }
      }
    });
  }
};

export const sortTableColumns = (item: any, setSortField: any) => {
  if (item.value === "businessName") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("companyName");
  } else if (item.value === "ownerName") {
    setSortField("ownerName");
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
  } else if (item.value === "companyEmail") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("email");
  } else if (item.value === "country") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("country");
  } else if (item.value === "plan") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("plan");
  } else if (item.value === "seats") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("seats");
  } else if (item.value === "status") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("status");
  } else if (item.value === "buisnessId") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("buisnessId");
  }
};

export const sortTableColumnsForDeletedCompanies = (item: any, setSortField: any) => {
  if (item.value === "businessNameDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("companyName");
  } else if (item.value === "daysDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("days");
  } else if (item.value === "ownerNameDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("ownerName");
  } else if (item.value === "countryDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("country");
  } else if (item.value === "companyEmailDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("email");
  } else if (item.value === "buisnessIdDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("buisnessId");
  }
};
export const invoiceTranslation = (text: any) => {
  if (text === "Monthly Subscription") {
    return "Monthly Invoice";
  } else if (text === "Yearly Subscription") {
    return "Yearly Invoice";
  } else if (text === "Additional seats") {
    return "Additional Seats Invoice";
  }
};

export const refactoredSelectedOption = (selectedItem: any) => {
  if (selectedItem === "overview") {
    return " / OVERVIEW";
  } else if (selectedItem.includes("subscription")) {
    return " / SUBSCRIPTION";
  } else if (selectedItem.includes("users")) {
    return " / USERS";
  } else if (selectedItem.toLowerCase().includes("site")) {
    return " / SITES";
  } else if (selectedItem.toLowerCase().includes("forms")) {
    return " / FORMS";
  } else return "";
};

export const refactorSuspendStatus = (status: string, isSuspended: boolean) => {
  if (isSuspended === false && status === "Free") {
    return "Active";
  } else if (status === "Free" && isSuspended === true) {
    return "Suspended";
  } else {
    return status;
  }
};

export const sortSiteHeader = (item: any, setSortField: any) => {
  if (item.value === "siteId") {
    setSortField("siteId");
  } else if (item.value === "name") {
    setSortField("name");
  }
};
export const sortFormHandler = (item: any, setSortField: any) => {
  if (item.value === "buisnessId ") {
    setSortField("buisnessId ");
  } else if (item.value === "formDetail.title") {
    setSortField("formDetail.title");
  } else if (item.value === "usage") {
    setSortField("usage");
  }
};
export const sortReportHandler = (item: any, setSortField: any) => {
  if (item.value === "buisnessId ") {
    setSortField("buisnessId ");
  } else if (item.value === "formDetail.title") {
    setSortField("formDetail.title");
  } else if (item.value === "startAt") {
    setSortField("startAt");
  }
};

export const refactorLogsTilte = (selectedOption: string) => {
  if (selectedOption === "overview") {
    return "company information";
  } else if (selectedOption === "subscription") {
    return "subscription";
  } else if (selectedOption === "users") {
    return "users";
  } else if (selectedOption.includes("users_single")) {
    return "user";
  } else if (selectedOption === "roles") {
    return "roles";
  } else if (selectedOption.includes("roles_single")) {
    return "role";
  } else if (selectedOption === "sites") {
    return "sites";
  } else if (selectedOption.includes("sites_single")) {
    return "site";
  } else if (selectedOption.includes("users")) {
    return "users";
  } else if (selectedOption.includes("users_single")) {
    return "user";
  } else if (selectedOption === "forms") {
    return "forms";
  } else if (selectedOption.includes("forms_single")) {
    return "form";
  } else if (selectedOption === "reports") {
    return "reports";
  } else if (selectedOption.includes("reports_single")) {
    return "report";
  } else return "";
};

export const readablePaymentStatus = (status: string) => {
  if (status === "paid") {
    return "PAID";
  } else {
    return status;
  }
};

export const refactorMarginTopForReports = (item: any) => {
  if (item.label === "Photos" || item.label === "Footer" || item.label === "Failed Items Summary") {
    return ".5rem";
  } else if (
    item.label === "Table of Contents" ||
    item.label === "Company Informations" ||
    item.label === "Disclaimer" ||
    item.label === "Report Statistics"
  ) {
    return "-.5rem";
  } else return "";
};
