export const statusForFreePlan = [
  {
    id: "1",
    isSelected: true,
    label: "Active",
    value: "active",
  },
  {
    id: "6",
    isSelected: true,
    label: "Suspended",
    value: "Suspended",
  },
];
export const statusForTrialPlan = [
  {
    id: "2",
    isSelected: true,
    label: "Running",
    value: "Running",
  },
  {
    id: "3",
    isSelected: true,
    label: "Expired",
    value: "Expired",
  },
  {
    id: "6",
    isSelected: true,
    label: "Suspended",
    value: "Suspended",
  },
];

export const statusForMonthlyPlan = [
  {
    id: "2",
    isSelected: true,
    label: "Running",
    value: "running",
  },
  {
    id: "4",
    isSelected: true,
    label: "Trial is running",
    value: "trial",
  },
  {
    id: "5",
    isSelected: true,
    label: "Paused",
    value: "paused",
  },
  {
    id: "6",
    isSelected: true,
    label: "Suspended",
    value: "suspended",
  },
  {
    id: "7",
    isSelected: true,
    label: "Unsubscribed, subscription is running",
    value: "Unsubscribed, subscription is running",
  },
];
export const statusForAllSelectedPlan = [
  {
    id: "1",
    isSelected: true,
    label: "Active",
    value: "active",
  },
  {
    id: "2",
    isSelected: true,
    label: "Running",
    value: "running",
  },
  {
    id: "3",
    isSelected: true,
    label: "Expired",
    value: "expired",
  },
  {
    id: "4",
    isSelected: true,
    label: "Trial is running",
    value: "trial",
  },
  {
    id: "5",
    isSelected: true,
    label: "Paused",
    value: "paused",
  },
  {
    id: "6",
    isSelected: true,
    label: "Suspended",
    value: "suspended",
  },
  {
    id: "7",
    isSelected: true,
    label: "Unsubscribed, subscription is running",
    value: "Unsubscribed, subscription is running",
  },
];
