import prevIcon from "../../assets/images/prevArrow.svg";
import nextIcon from "../../assets/images/nextArrow.svg";
import React, { useEffect } from "react";
interface PaginationIProps {
  page: number;
  total: number;
  setpage: any;
  setSize: any;
  size: number;
}
function CustomPagination({ page, total, setpage, setSize, size }: PaginationIProps) {
  const [selectedPage, setSelectedPage] = React.useState(1);
  let pageNo = Math.ceil(total / size);
  // console.log(pageNo, "page number");

  const onPrevious = () => {
    setpage(page - 1);
  };
  const onNext = () => {
    setpage(page + 1);
  };
  const onFirst = () => {
    setpage(1);
  };
  const onLast = () => {
    setpage(pageNo);
  };
  useEffect(() => {
    setSelectedPage(page);
  }, [page]);
  useEffect(() => {
    if (page > pageNo) {
      setpage(pageNo);
    } else {
      setpage(page);
    }
    // setSize(size)
    // eslint-disable-next-line
  }, [size]);

  let pages = [];
  for (let i = 1; i <= pageNo; i++) {
    pages.push(i);
  }

  return (
    <div className="main-pagination-wrap">
      <div className="page-btns-wrap">
        <div className="first-page-btn" onClick={() => (page === 1 ? null : onFirst())}>
          <img src={prevIcon} alt="prevIcon" />
          <img src={prevIcon} alt="prevIcon" />
        </div>
        <div className="prev-btn" onClick={() => (page === 1 ? null : onPrevious())}>
          <img src={prevIcon} alt="prevIcon" />
        </div>
        {pages.length > 0
          ? pages.map((page, index) => {
              return (
                <div className="pages-wrap" key={index}>
                  <div className={`${page === selectedPage ? "active" : ""}`} onClick={() => setpage(page)}>
                    {page}
                  </div>
                </div>
              );
            })
          : null}

        <div className="next-btn" onClick={() => (page >= pageNo ? null : onNext())}>
          <img src={nextIcon} alt="nextIcon" />
        </div>
        <div className="last-page-btn" onClick={() => (page >= pageNo ? null : onLast())}>
          <img src={nextIcon} alt="nextIcon" />
          <img src={nextIcon} alt="nextIcon" />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mx-2">
        <select
          onChange={(e) => {
            setSize(parseInt(e.target.value));
          }}
          value={size}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <div className="page-show">
          Showing {page}-{size} of {total}
        </div>
      </div>
    </div>
  );
}

export default CustomPagination;
