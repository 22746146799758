import { useEffect, useState } from "react";
import CompanyDetails from "./CompanyDetails";
// import CompanyInfo from "./CompanyInfo";
import CompanyInfo from "./CompanyInfo";
import CompanyLogs from "./CompanyLogs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";

import { StoreState } from "../../redux/storeState/storeState";
import * as companyAction from "../../redux/companies/companiesAction";
import { useParams } from "react-router-dom";
import { CompanyLogsForEditDTO, CompanySubscriptionDTO } from "../../interfaces/companyInterface";
import { useSidebar } from "../useSidebarProvider";
// import UserDetails from "./users/UserDetails";
// import RoleDetails from "./roles/RoleDetails";
// import SingleRoleDetails from "./roles/SingleRoleDetails";
import FormsDetails from "./forms/FormsDetails";
import SingleFormDetails from "./forms/SingleFormDetails";
import ReportDetails from "./reports/ReportDetails";
import SingleReportDetails from "./reports/SingleReportDetails";
import SubscriptionDetails from "./subscription/CompanySubscription";
import InvoiceDetails from "./subscription/InvoiceDetails";
import RoleDetails from "./roles/RoleDetails";
import SingleRoleDetails from "./roles/SingleRoleDetails";
import UserDetails from "./users/UserDetails";
import SingleUserDetails from "./users/SingleUserDetails";
import SitesDetails from "./sites/siteDetails";
import SingleSite from "./sites/SingleSite";
import { refactorLogsTilte, sortFormHandler, sortReportHandler, sortSiteHeader } from "../../helper/helperFunction";

function CompanyOverview() {
  const dispatch: AppDispatch | any = useDispatch();
  const [subscriptionData, setsubscriptionData] = useState<CompanySubscriptionDTO>();
  const [userPage, setuserPage] = useState(1);
  const [userSize, setuserSize] = useState(10);
  const [userSearchText, setuserSearchText] = useState("");
  const [userTotal, setuserTotal] = useState(0);
  const [userSortAt, setuserSortAt] = useState("userBuisnessId");
  const [userChartsData, setuserChartsData] = useState<any>([]);
  const [rolesChartsData, setRolesChartsData] = useState<any>([]);
  const [rolesPage, setRolesPage] = useState(1);
  const [rolesSize, setRolesSize] = useState(10);
  const [rolesSearchText, setrolesSearchText] = useState("");
  const [rolesTotal, setrolesTotal] = useState(0);
  const [rolesSortAt, setRolesSortAt] = useState("buisnessId");

  const userTableHeader = [
    {
      id: "1",
      sortBy: "desc",
      label: "USER ID	",
      value: "userBuisnessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "fullName",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "EMAIL",
      value: "email",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "ROLE",
      value: "role",
    },
    {
      id: "5",
      sortBy: "desc",
      label: "LOGINS",
      value: "loginAttempts",
    },
  ];
  const rolesTableHeader = [
    {
      id: "1",
      sortBy: "desc",
      label: "ROLE ID",
      value: "buisnessId",
    },
    {
      id: "2",
      sortBy: "asc",
      label: "ROLE",
      value: "name",
    },
    {
      id: "3",
      sortBy: "asc",
      label: "NO. OF USERS",
      value: "totalUsers",
    },
    {
      id: "4",
      sortBy: "asc",
      label: "ACCESS",
      value: "access",
    },
  ];
  const [allcompanyUsers, setallcompanyUsers] = useState<any>([]);
  const [paymentCardData, setpaymentCardData] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [companyInitialState, setcompanyInitialState] = useState({
    name: "",
    businessId: "",
    plan: "",
    status: "",
    industry: "",
    email: "",
    phone: "",
    registerDate: "",
    lastActivity: "",
    country: "",
    image: "",
    isSuspended: false,
    chartsToShow: [
      {
        title: "",
        value: 0,
        perDayCalculation: [],
      },
    ],
    xAxisData: [""],
  });
  const [companyLogs, setcompanyLogs] = useState<CompanyLogsForEditDTO[]>([]);
  const [size, setsize] = useState(10);
  const [logSearchText, setlogSearchText] = useState("");
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  const [chartsLoading, setchartsLoading] = useState(true);
  const [invoiceData, setinvoiceData] = useState({});
  const [showInvoiceSendModal, setshowInvoiceSendModal] = useState(false);
  const [allRoles, setallRoles] = useState<any>([]);
  const [singleUserData, setsingleUserData] = useState({});
  const [sitePage, setsitePage] = useState(1);
  const [siteSize, setsiteSize] = useState(10);
  const [companySites, setcompanySites] = useState<any>([]);
  // const [siteSortBy, setsiteSortBy] = useState("asc");
  const [siteSearchText, setsiteSearchText] = useState("");
  const [singleSiteForEdit, setsingleSiteForEdit] = useState<any>({});
  const [siteSortField, setsiteSortField] = useState("siteId");
  const [showRestoreFormModal, setshowRestoreFormModal] = useState(false);
  const [showSuccessRestoreFormModal, setshowSuccessRestoreFormModal] = useState(false);
  const [sitesChartsData, setsitesChartsData] = useState({
    chartsData: [],
    xAxisData: [],
  });
  const [formsTotal, setformsTotal] = useState(0);
  const [formsPage, setFormsPage] = useState(1);
  const [formsSize, setFormsSize] = useState(10);
  //eslint-disable-next-line
  const [formSearchText, setFormSearchText] = useState("");
  const [singleFormForEdit, setsingleFormForEdit] = useState<any>({});
  const [formChartsData, setformChartsData] = useState<any>([]);
  const [formSortField, setFormSortField] = useState("buisnessId");
  const [allForms, setallForms] = useState<any>([]);
  const { id } = useParams();
  const [reportTotal, setReportTotal] = useState(0);
  const [reportPage, setReportPage] = useState(1);
  const [reportSize, setReportSize] = useState(10);
  const [ReportSearchText, setReportSearchText] = useState("");
  const [reportSortAt, setReportSortAt] = useState("buisnessId");
  const [allReportUsers, setallReportUsers] = useState<any>([]);

  const [roleForEdit, setroleForEdit] = useState({});
  const {
    companyForEdit,
    companyLogsForEdit,
    totalCount,
    companyUsers,
    totalUserCount,
    companyUserSize,
    companyUserPage,
    singleUserForEdit,
    companyRoleChartData,
    companyUserChartsData,
    companyRolesPage,
    companyRolesSize,
    totalRolesCount,
    allCompanyRoles,
    companyRoleForEdit,
    paymentCardForEdit,
    invoiceForEdit,
    singleSite,
    siteChartsData,
    allCompanySites,
    allSitePage,
    allSiteSize,
    allSiteTotal,
    compnaySingleFormForEdit,
    companyFormsChartData,
    totalFormsCount,
    allCompanyForms,
    companyFormsPage,
    companyFormsSize,
    formDispatchRule,
    allCompanyReports,
    totalReportCount,
    companyReportPage,
    companyReportSize,
    companyReportsChartsData,
    companySingleReportForEdit,
    companyReportUsers,
    userReportSetting,
    subscriptionForEdit,
  } = useSelector((state: StoreState) => ({
    companyForEdit: state.company.companyForEdit,
    companyLogsForEdit: state.company.companyLogsForEdit,
    totalCount: state?.company?.totalCount,
    subscriptionForEdit: state.company.subscriptionForEdit,
    invoiceForEdit: state.company.invoiceForEdit,
    companyUsers: state.company.companyUsers,
    totalUserCount: state.company.totalUserCount,
    companyUserPage: state.company.userPage,
    companyUserSize: state.company.userSize,
    singleUserForEdit: state.company.singleUserForEdit,
    companyUserChartsData: state.company.userChartsData,
    companyRoleChartData: state.company.rolesChartsData,
    companyRolesPage: state.company.rolesPage,
    companyRolesSize: state.company.rolesSize,
    totalRolesCount: state.company.totalRolesCount,
    allCompanyRoles: state.company.companyRoles,
    companyRoleForEdit: state.company.roleForEdit,
    singleSite: state.company.singleSiteForEdit,
    siteChartsData: state.company.siteChartsData,
    allCompanySites: state.company.companySites,
    allSitePage: state.company.sitesPage,
    allSiteSize: state.company.siteSize,
    allSiteTotal: state.company.totalSiteCount,
    compnaySingleFormForEdit: state.company.singleFormForEdit,
    companyFormsChartData: state.company.formsChartData,
    allCompanyForms: state.company.allCompanyForms,
    companyFormsPage: state.company.formsPage,
    companyFormsSize: state.company.formsSize,
    totalFormsCount: state.company.totalFormsCount,
    formDispatchRule: state.company.formDispatchRule,
    allCompanyReports: state.company.companyReports,
    companyReportPage: state.company.reportsPage,
    companyReportSize: state.company.reportSize,
    totalReportCount: state.company.totalReportCount,
    companySingleReportForEdit: state.company.singleReportForEdit,
    companyReportsChartsData: state.company.reportChartData,
    companyReportUsers: state.company.allReportSettingUsers,
    userReportSetting: state.company.singleUserReportSetting,
    paymentCardForEdit: state.company.paymentCardForEdit,
  }));
  const [formRules, setformRules] = useState<any>([]);
  const [reportChartData, setreportChartData] = useState<any>([]);
  const [slectedLable, setslectedLable] = useState("Last 30 Days");
  const [selectedValue, setselectedValue] = useState("Last30Days");
  const [companyLogsLoading, setcompanyLogsLoading] = useState(true);
  const [companyLoading, setcompanyLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("overview");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedUserHeader, setselectedUserHeader] = useState(userTableHeader[0]);
  const [selectedRolesHeader, setselectedRolesHeader] = useState(rolesTableHeader[0]);

  const [siteTotal, setSiteTotal] = useState(0);
  const [selectedRuleOptioin, setselectedRuleOptioin] = useState("Dispatch rule No.1");
  const [allReports, setallReports] = useState<any>([]);
  const [reportForEdit, setreportForEdit] = useState({});
  const [reportSetting, setreportSetting] = useState({});

  const siteHeadData = [
    { id: "1", label: "SITE ID", sortBy: "desc", value: "siteId" },
    { id: "2", label: "SITE NAME", sortBy: "desc", value: "name" },
  ];
  const formsHeadData = [
    {
      label: "FORM ID",
      value: "buisnessId ",
      sortBy: "desc",
    },
    {
      label: "FORM NAME",
      value: "formDetail.title",
      sortBy: "desc",
    },
    {
      label: "USAGE",
      value: "usage",
      sortBy: "desc",
    },
  ];
  const ReportsHeadData = [
    {
      label: "REPORT ID",
      value: "buisnessId",
      sortBy: "desc",
    },
    {
      label: "FORM NAME",
      value: "formDetail.title",
      sortBy: "desc",
    },
    {
      label: "REPORT NAME",
      value: "startAt",
      sortBy: "desc",
    },
  ];
  const [selectedReportHeader, setselectedReportHeader] = useState(ReportsHeadData[0]);
  const [selectedSiteHeader, setselectedSiteHeader] = useState(siteHeadData[0]);
  const [selectedFormHeader, setselectedFormHeader] = useState(formsHeadData[0]);
  const [showRestoreReportModal, setshowRestoreReportModal] = useState(false);
  const [showSuccessReportRestoreModal, setshowSuccessReportRestoreModal] = useState(false);
  let options = [
    { lable: "Today", value: "Today" },
    { lable: "Yesterday", value: "Yesterday" },
    { lable: "This Week", value: "ThisWeek" },
    { lable: "Last Week", value: "LastWeek" },
    { lable: "Last 7 Days", value: "Last7Days" },
    { lable: "This Month", value: "ThisMonth" },
    { lable: "Last Month", value: "LastMonth" },
    { lable: "Last 30 Days", value: "Last30Days" },
    { lable: "Custom Range", value: "CustomRange" },
  ];
  useEffect(() => {
    if (selectedValue !== "CustomRange") {
      const selectedOption = options.find((item) => item.value === selectedValue);
      setslectedLable(selectedOption?.lable || "");
    } else {
      setslectedLable(`${selectedDate.startDate} ~ ${selectedDate.endDate}`);
    }
    // eslint-disable-next-line
  }, [selectedValue]);
  useEffect(() => {
    setselectedItem(selectedOption);
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (selectedDate.startDate && selectedDate.endDate) {
      setchartsLoading(true);
      dispatch(
        companyAction.getCompanyById({
          id: id,
          limit: size,
          searchName: logSearchText,
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
          // logSearchFor: selectedOption.includes("_") ? selectedOption.split("_").shift() : selectedOption,
          // actionId: selectedOption.includes("_") ? selectedOption.split("_").pop() : "",
        })
      ).then(() => {
        dispatch(
          companyAction.getCompanyLogsById({
            id: id,
            limit: size,
            searchName: logSearchText,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
            logSearchFor: selectedOption.includes("_") ? selectedOption.split("_").shift() : selectedOption,
            actionId: selectedOption.includes("_") ? selectedOption.split("_").pop() : "",
          })
        ).then(() => {
          setcompanyLogsLoading(false);
          setchartsLoading(false);
        });
      });
    }

    // eslint-disable-next-line
  }, [selectedDate.startDate, selectedDate.endDate, selectedOption]);

  const [selectedId, setselectedId] = useState(undefined);
  const [selectedUserOption, setselectedUserOption] = useState(allReportUsers[0]?.userName);
  const [selectedUserId, setselectedUserId] = useState(undefined);
  const handleSelectedRuleItem = (item: any, index: number) => {
    setselectedRuleOptioin(`Disptach rule No.${index + 1}`);
    setselectedId(item._id);
  };
  const handleSelectedUserItem = (item: any, index: number) => {
    setselectedUserOption(item.userName);
    setselectedUserId(item?.userid);
  };
  // console.log(selectedId, "id ii");
  // console.log(formRules, "id ii rules");
  useEffect(() => {
    if (selectedOption === "subscription" && selectedDate.startDate && selectedDate.endDate) {
      dispatch(
        companyAction.getCompanySubscriptionById({
          id: id!,
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
        })
      );
      dispatch(companyAction.getCompanyPaymentCardById(id!));
    }

    // eslint-disable-next-line
  }, [selectedOption, selectedDate.startDate, selectedDate.endDate]);

  useEffect(() => {
    setsingleSiteForEdit(singleSite);
    // eslint-disable-next-line
  }, [singleSite]);
  useEffect(() => {
    setpaymentCardData(paymentCardForEdit);
    // eslint-disable-next-line
  }, [paymentCardForEdit]);

  useEffect(() => {
    setsubscriptionData(subscriptionForEdit);
    // eslint-disable-next-line
  }, [subscriptionForEdit]);
  useEffect(() => {
    setsingleFormForEdit(compnaySingleFormForEdit);
    // eslint-disable-next-line
  }, [compnaySingleFormForEdit]);
  useEffect(() => {
    setformRules(formDispatchRule);
    // eslint-disable-next-line
  }, [formDispatchRule, formDispatchRule]);
  useEffect(() => {
    if (companyReportUsers) {
      setallReportUsers(companyReportUsers);
    }
    // eslint-disable-next-line
  }, [companyReportUsers]);
  useEffect(() => {
    if (userReportSetting) {
      setreportSetting(userReportSetting);
    }
    // eslint-disable-next-line
  }, [userReportSetting]);

  useEffect(() => {
    if (formRules?.length > 0) {
      setselectedId(formRules[0]?._id);
      setselectedRuleOptioin("Disptach rule No.1");
    } else {
      setselectedId(undefined);
      setselectedRuleOptioin("Disptatch rule No.1");
    }
    // eslint-disable-next-line
  }, [formRules]);
  useEffect(() => {
    if (allReportUsers?.length > 0) {
      setselectedUserId(allReportUsers[0]?._userid);
      setselectedUserOption(allReportUsers[0]?.userName);
    } else {
      setselectedUserId(undefined);
      setselectedUserOption(allReportUsers[0]?.userName);
    }
    // eslint-disable-next-line
  }, [allReportUsers]);

  useEffect(() => {
    dispatch(
      companyAction.getCompanyLogsById({
        id: id,
        limit: size,
        searchName: logSearchText,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        logSearchFor: selectedOption.includes("_") ? selectedOption.split("_").shift() : selectedOption,
        actionId: selectedOption.includes("_") ? selectedOption.split("_").pop() : "",
      })
    ).then(() => {
      setcompanyLogsLoading(false);
      setcompanyLoading(false);
      setchartsLoading(false);
    });
    // eslint-disable-next-line
  }, [companyLogsLoading, companyLoading, selectedOption]);

  useEffect(() => {
    if (id && companyForEdit) {
      setcompanyInitialState({
        ...companyInitialState,
        name: companyForEdit?.companyName,
        businessId: companyForEdit.buisnessId,
        plan: companyForEdit.subscription.currentPlan.plan,
        status: companyForEdit.subscription.currentPlan.status,
        industry: companyForEdit.industry,
        email: companyForEdit.email,
        phone: companyForEdit.phone,
        registerDate: companyForEdit.createdAt,
        lastActivity: companyForEdit.updatedAt,
        country: companyForEdit.country,
        image: companyForEdit.image,
        chartsToShow: companyForEdit.companyChartsToShow,
        xAxisData: companyForEdit.xAxisData,
        isSuspended: companyForEdit.isSuspended,
      });
    }
    // eslint-disable-next-line
  }, [companyForEdit]);

  useEffect(() => {
    setpaymentCardData(paymentCardForEdit);
    // eslint-disable-next-line
  }, [paymentCardForEdit]);

  useEffect(() => {
    setinvoiceData(invoiceForEdit);
    // eslint-disable-next-line
  }, [invoiceForEdit]);
  useEffect(() => {
    setreportForEdit(companySingleReportForEdit);
    // eslint-disable-next-line
  }, [companySingleReportForEdit]);

  useEffect(() => {
    if (companyLogsForEdit && id) {
      setcompanyLogs(companyLogsForEdit);
    }
    // eslint-disable-next-line
  }, [companyLogsForEdit]);

  const handleSearchLogs = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getCompanyLogsById({
            id: id,
            limit: size,
            searchName: searchString,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
            logSearchFor: selectedOption.includes("_") ? selectedOption.split("_").shift() : selectedOption,
            actionId: selectedOption.includes("_") ? selectedOption.split("_").pop() : "",
          })
        ).then(() => {
          setcompanyLogsLoading(true);
        });
        if (searchString !== "") {
          setCloseSearchBarIfValue(false);
        } else {
          setCloseSearchBarIfValue(true);
        }
      }, 200);
    };
  })();

  const resetSearchingHandler = () => {
    dispatch(
      companyAction.getCompanyLogsById({
        id: id,
        limit: size,
        searchName: logSearchText,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        logSearchFor: selectedOption.includes("_") ? selectedOption.split("_").shift() : selectedOption,
        actionId: selectedOption.includes("_") ? selectedOption.split("_").pop() : "",
      })
    ).then(() => {
      setcompanyLogsLoading(true);
    });
  };
  const LoadMoreLogs = () => {
    dispatch(
      companyAction.getCompanyLogsById({
        id: id,
        limit: size + 10,
        searchName: logSearchText,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        logSearchFor: selectedOption.includes("_") ? selectedOption.split("_").shift() : selectedOption,
        actionId: selectedOption.includes("_") ? selectedOption.split("_").pop() : "",
      })
    ).then(() => {
      setsize(size + 10);
    });
  };

  const { collapsed, setselectedItem } = useSidebar();
  const getAllUsersHandler = (searchString?: string) => {
    dispatch(
      companyAction.getAllCompanyUsers({
        companyId: id!,
        page: userPage,
        size: userSize,
        sortAt: userSortAt,
        sortBy: selectedUserHeader.sortBy,
        searchItem: searchString!,
      })
    );
  };
  const getAllRolesHandler = (searchString?: string) => {
    dispatch(
      companyAction.getAllCompanyRoles({
        companyId: id!,
        page: rolesPage,
        size: rolesSize,
        sortAt: rolesSortAt,
        sortBy: selectedRolesHeader.sortBy,
        searchItem: searchString!,
      })
    );
  };
  useEffect(() => {
    if (selectedOption.includes("subscriptionInvoice") && selectedOption.split("_").pop()!.length > 0) {
      let selectedId = selectedOption.split("_").pop();
      dispatch(companyAction.getSubscriptionInvoiceById(selectedId!));
    } else if (selectedOption === "sites") {
      companySiteHandler();
    } else if (selectedOption.includes("sites_single")) {
      let selectedId = selectedOption.split("_").pop();
      dispatch(companyAction.getSingleCompanySiteById(selectedId!));
    } else if (selectedOption === "forms") {
      companyFormsHandler();
    } else if (selectedOption.includes("forms_single")) {
      let selectedFormId = selectedOption.split("_").pop();

      dispatch(companyAction.getSingleCompanyFormById(selectedFormId!));
      // if (singleFormForEdit && !singleFormForEdit.isArchived && !singleFormForEdit.isDeleted) {
      dispatch(companyAction.getFormDispatchRuleById(selectedFormId!));
      // }
      // });
    } else if (selectedOption === "reports") {
      reportsHandler();
    } else if (selectedOption.includes("reports_single")) {
      let selectedReportId = selectedOption.split("_").pop();
      dispatch(companyAction.getSingleCompanyReportById(selectedReportId!));
      dispatch(companyAction.getAllReportSettingUsers(selectedReportId!));
      // if (selectedUserId !== undefined) {
      //   dispatch(companyAction.getSingleReportSettingByUserAndReportId(selectedUserId!, selectedReportId!));
      // }
    }

    // eslint-disable-next-line
  }, [selectedOption, sitePage, siteSize, formsPage, formsSize, reportSize, reportPage]);

  useEffect(() => {
    if (selectedOption.includes("reports_single") && selectedUserId === undefined && allReportUsers) {
      setselectedUserId(allReportUsers[0]?.userid);
    }
    if (selectedOption.includes("reports_single") && selectedUserId !== undefined) {
      let selectedReportId = selectedOption.split("_").pop();

      dispatch(companyAction.getSingleReportSettingByUserAndReportId(selectedUserId!, selectedReportId!));
    }

    // eslint-disable-next-line
  }, [selectedUserId, allReportUsers, selectedOption]);

  useEffect(() => {
    if (selectedDate.startDate && selectedDate.endDate && selectedOption === "sites") {
      setchartsLoading(true);
      dispatch(companyAction.getSiteChartsDataByCompanyId(id!, selectedDate.startDate, selectedDate.endDate)).then(() => {
        setchartsLoading(false);
      });
    } else if (selectedDate.startDate && selectedDate.endDate && selectedOption === "forms") {
      setchartsLoading(true);
      dispatch(companyAction.getFormsChartsDataByCompanyId(id!, selectedDate.startDate, selectedDate.endDate)).then(() => {
        setchartsLoading(false);
      });
    } else if (selectedOption === "reports" && selectedDate.startDate && selectedDate.endDate) {
      setchartsLoading(true);
      dispatch(companyAction.getReportsChartsDataByCompanyId(id!, selectedDate.startDate, selectedDate.endDate)).then(() => {
        setchartsLoading(false);
      });
    } else if (selectedDate.startDate && selectedDate.endDate && selectedOption === "users") {
      setchartsLoading(true);
      dispatch(companyAction.getUserChartsDataByCompanyId(id!, selectedDate.startDate, selectedDate.endDate)).then(() => setchartsLoading(false));
    }

    // eslint-disable-next-line
  }, [selectedDate, selectedOption]);
  useEffect(() => {
    if (selectedOption === "users") {
      setchartsLoading(true);
      getAllUsersHandler();
    }
    if (selectedOption.includes("users_single") && selectedOption.split("_").pop()!.length > 0) {
      let selectedId = selectedOption.split("_").pop();
      dispatch(companyAction.getCompanyUserById(selectedId!));
    }
    if (selectedOption === "roles") {
      setchartsLoading(true);
      getAllRolesHandler();
      dispatch(companyAction.getRolesChartsDataByCompanyId(id!, selectedDate.startDate, selectedDate.endDate)).then(() => setchartsLoading(false));
    }
    if (selectedOption.includes("roles_single") && selectedOption.split("_").pop()!.length > 0) {
      let selectedId = selectedOption.split("_").pop();
      dispatch(companyAction.getCompanyRoleById(selectedId!));
    }
    // eslint-disable-next-line
  }, [selectedOption, userPage, userSize, selectedDate, rolesPage, rolesSize]);

  useEffect(() => {
    setallcompanyUsers(companyUsers);
    // eslint-disable-next-line
  }, [companyUsers]);

  useEffect(() => {
    setallRoles(allCompanyRoles);
    // eslint-disable-next-line
  }, [allCompanyRoles]);
  useEffect(() => {
    setinvoiceData(invoiceForEdit);
    // eslint-disable-next-line
  }, [invoiceForEdit]);

  useEffect(() => {
    if (companyUserChartsData) {
      setuserChartsData(companyUserChartsData);
    }
  }, [companyUserChartsData]);

  useEffect(() => {
    if (companyRoleChartData) {
      setRolesChartsData(companyRoleChartData);
    }
    // eslint-disable-next-line
  }, [companyRoleChartData]);

  useEffect(() => {
    setsingleUserData(singleUserForEdit);
    // eslint-disable-next-line
  }, [singleUserForEdit]);
  useEffect(() => {
    setroleForEdit(companyRoleForEdit);
    // eslint-disable-next-line
  }, [companyRoleForEdit]);
  useEffect(() => {
    if (userTotal === 0 && totalUserCount > 0) {
      setuserPage(companyUserPage);
      setuserSize(companyUserSize);
      setuserTotal(totalUserCount);
    }
    // eslint-disable-next-line
  }, [totalUserCount]);
  useEffect(() => {
    if (rolesTotal === 0 && totalRolesCount > 0) {
      setRolesPage(companyRolesPage);
      setRolesSize(companyRolesSize);
      setrolesTotal(totalRolesCount);
    }
    // eslint-disable-next-line
  }, [totalRolesCount]);

  useEffect(() => {
    if (siteChartsData) {
      setsitesChartsData({ ...sitesChartsData, chartsData: siteChartsData?.companySitesChartsToShow, xAxisData: siteChartsData?.xAxisData });
    }
    // eslint-disable-next-line
  }, [siteChartsData]);
  useEffect(() => {
    if (companyReportsChartsData) {
      setreportChartData({
        ...reportChartData,
        chartsData: companyReportsChartsData?.reportsChartsToShow,
        xAxisData: companyReportsChartsData?.xAxisData,
      });
    }
    // eslint-disable-next-line
  }, [companyReportsChartsData]);
  useEffect(() => {
    if (companyFormsChartData) {
      setformChartsData(companyFormsChartData);
    }
    // eslint-disable-next-line
  }, [companyFormsChartData]);
  //site api call
  const companySiteHandler = (searchString?: any) => {
    dispatch(
      companyAction.getAllCompanySites({
        companyId: id!,
        sortBy: selectedSiteHeader.sortBy,
        page: sitePage,
        size: siteSize,
        name: searchString,
        sortAt: siteSortField,
      })
    );
  };
  //forms api call
  const companyFormsHandler = (searchString?: any) => {
    dispatch(
      companyAction.getAllCompanyForms({
        companyId: id!,
        sortBy: selectedFormHeader.sortBy,
        page: formsPage,
        size: formsSize,
        name: searchString,
        sortAt: formSortField,
      })
    );
  };
  //restore form api call
  const restoreFormHandler = () => {
    let selectedFormId = selectedOption.split("_").pop();
    dispatch(companyAction.restoreDeletedFormById(selectedFormId!));
    setshowRestoreFormModal(false);
    setshowSuccessRestoreFormModal(true);
  };
  const restoreReportHandler = () => {
    let selectedFormId = selectedOption.split("_").pop();
    dispatch(companyAction.restoreReportById(selectedFormId!));
    setshowRestoreReportModal(false);
    setshowSuccessReportRestoreModal(true);
  };
  // report api call
  const reportsHandler = (searchString?: any) => {
    dispatch(
      companyAction.getAllCompanyReports({
        companyId: id!,
        page: reportPage,
        size: reportSize,
        sortAt: reportSortAt,
        sortBy: selectedReportHeader.sortBy,
        name: searchString,
      })
    );
  };
  useEffect(() => {
    setcompanySites(allCompanySites);
    // eslint-disable-next-line
  }, [allCompanySites]);
  useEffect(() => {
    setallForms(allCompanyForms);
    // eslint-disable-next-line
  }, [allCompanyForms]);

  useEffect(() => {
    setallReports(allCompanyReports);
    // eslint-disable-next-line
  }, [allCompanyReports]);

  useEffect(() => {
    if (allSiteTotal > 0 && siteTotal === 0) {
      setsitePage(allSitePage);
      setSiteTotal(allSiteTotal);
      setsiteSize(allSiteSize);
    }
    // eslint-disable-next-line
  }, [allSiteTotal]);
  useEffect(() => {
    if (totalFormsCount > 0 && formsTotal === 0) {
      setFormsPage(companyFormsPage);
      setformsTotal(totalFormsCount);
      setFormsSize(companyFormsSize);
    }
    // eslint-disable-next-line
  }, [totalFormsCount]);
  useEffect(() => {
    if (totalReportCount > 0 && reportTotal === 0) {
      setReportPage(companyReportPage);
      setReportTotal(totalReportCount);
      setReportSize(companyReportSize);
    }
    // eslint-disable-next-line
  }, [totalReportCount]);

  const handleSendInvoice = () => {
    setshowInvoiceSendModal(false);
    setShowSuccessModal(true);
    if (selectedOption.includes("subscriptionInvoice")) {
      let selectedId = selectedOption.split("_").pop();
      dispatch(companyAction.sendInvoiceEmailToCompany({ invoiceId: selectedId!, email: companyForEdit?.email }));
    }
  };
  // site search handler
  const handleSiteSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        companySiteHandler(searchString);
      }, 200);
    };
  })();
  const handleFormsSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        companyFormsHandler(searchString);
      }, 200);
    };
  })();
  const handleReportSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        reportsHandler(searchString);
      }, 200);
    };
  })();

  //site sorting
  useEffect(() => {
    companySiteHandler();
    let sortIcon = document.getElementById("site-sort-icon");
    if (selectedSiteHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");

    // eslint-disable-next-line
  }, [selectedSiteHeader.sortBy, siteSortField]);

  const handleSort = (item: any) => {
    sortSiteHeader(item, setsiteSortField);
    setselectedSiteHeader(item);
    if (selectedSiteHeader.value === item.value) {
      if (selectedSiteHeader.sortBy === "asc") {
        setselectedSiteHeader({ ...selectedSiteHeader, sortBy: "desc" });
      } else {
        setselectedSiteHeader({ ...selectedSiteHeader, sortBy: "asc" });
      }
    }
  };
  const handleArrowSortClick = () => {
    if (selectedOption === "sites") {
      if (selectedSiteHeader.sortBy === "asc") {
        setselectedSiteHeader({ ...selectedSiteHeader, sortBy: "desc" });
      } else {
        setselectedSiteHeader({ ...selectedSiteHeader, sortBy: "asc" });
      }
    }
  };

  const handleSortForms = (item: any) => {
    sortFormHandler(item, setFormSortField);
    setselectedFormHeader(item);
    if (selectedFormHeader.value === item.value) {
      if (selectedFormHeader.sortBy === "asc") {
        setselectedFormHeader({ ...selectedFormHeader, sortBy: "desc" });
      } else {
        setselectedFormHeader({ ...selectedFormHeader, sortBy: "asc" });
      }
    }
  };
  const handleSortReports = (item: any) => {
    sortReportHandler(item, setReportSortAt);
    setselectedReportHeader(item);
    if (selectedReportHeader.value === item.value) {
      if (selectedReportHeader.sortBy === "asc") {
        setselectedReportHeader({ ...selectedReportHeader, sortBy: "desc" });
      } else {
        setselectedReportHeader({ ...selectedReportHeader, sortBy: "asc" });
      }
    }
  };

  const handleFormSortArrow = () => {
    if (selectedFormHeader.sortBy === "asc") {
      setselectedFormHeader({ ...selectedFormHeader, sortBy: "desc" });
    } else {
      setselectedFormHeader({ ...selectedFormHeader, sortBy: "asc" });
    }
  };
  const handleReportSortArrow = () => {
    if (selectedReportHeader.sortBy === "asc") {
      setselectedReportHeader({ ...selectedReportHeader, sortBy: "desc" });
    } else {
      setselectedReportHeader({ ...selectedReportHeader, sortBy: "asc" });
    }
  };
  //forms sorting:
  useEffect(() => {
    companyFormsHandler();
    let sortIcon = document.getElementById("forms-sort-icon");
    if (selectedFormHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");

    // eslint-disable-next-line
  }, [selectedFormHeader.sortBy, formSortField]);
  //user search handler
  const handleSearchUsers = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAllUsersHandler(searchString);
      }, 200);
    };
  })();
  // role search handler
  const handleSearchRoles = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAllRolesHandler(searchString);
      }, 200);
    };
  })();

  const handleArrowUserSortClick = () => {
    setuserSortAt(selectedUserHeader.value!);
    if (selectedUserHeader.sortBy === "asc") {
      setselectedUserHeader({ ...selectedUserHeader, sortBy: "desc" });
    } else {
      setselectedUserHeader({ ...selectedUserHeader, sortBy: "asc" });
    }
  };
  //role sort
  const handleRolesArrowSortClick = () => {
    setRolesSortAt(selectedRolesHeader.value!);
    if (selectedRolesHeader.sortBy === "asc") {
      setselectedRolesHeader({ ...selectedRolesHeader, sortBy: "desc" });
    } else {
      setselectedRolesHeader({ ...selectedRolesHeader, sortBy: "asc" });
    }
  };

  const handleUserSort = (item: any) => {
    setuserSortAt(item.value);
    setselectedUserHeader(item);
    if (selectedUserHeader.value === item.value) {
      if (selectedUserHeader.sortBy === "asc") {
        setselectedUserHeader({ ...selectedUserHeader, sortBy: "desc" });
      } else {
        setselectedUserHeader({ ...selectedUserHeader, sortBy: "asc" });
      }
    }
  };
  const handleRolesSort = (item: any) => {
    setselectedRolesHeader(item);
    setRolesSortAt(item.value);
    if (selectedRolesHeader.value === item.value) {
      handleRolesArrowSortClick();
    }
  };
  useEffect(() => {
    getAllUsersHandler();
    let sortIcon = document.getElementById("user-sort-icon");
    if (selectedUserHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [userSortAt, selectedUserHeader.sortBy]);

  useEffect(() => {
    getAllRolesHandler();
    let sortIcon = document.getElementById("roles-sort-icon");
    if (selectedRolesHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [rolesSortAt, selectedRolesHeader.sortBy]);

  //reports sorting
  useEffect(() => {
    reportsHandler();
    let sortIcon = document.getElementById("report-sort-icon");
    if (selectedReportHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");

    // eslint-disable-next-line
  }, [selectedReportHeader.sortBy, reportSortAt]);

  return (
    <div>
      <div className="company-wrap row m-0 pb-2" style={{ paddingLeft: !collapsed ? "17rem" : "1rem ", overflow: "hidden" }}>
        <div className="col-lg-3  col-md-12" style={{ height: "100%", position: "relative", bottom: 0 }}>
          <CompanyInfo
            companyInfo={companyInitialState}
            setcompanyLoading={setcompanyLoading}
            logSearchText={logSearchText}
            selectedDate={selectedDate}
            size={size}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className=" col-lg-6 col-md-12 " style={{ height: "100%" }}>
          {selectedOption === "subscription" && (
            <SubscriptionDetails
              subscriptionData={subscriptionData}
              setSelectedOption={setSelectedOption}
              chartsLoading={chartsLoading}
              chartsToShow={subscriptionData?.subscriptionChartsToShow}
              paymentCardData={paymentCardData}
            />
          )}
          {selectedOption === "overview" && (
            <CompanyDetails chartsToShow={companyInitialState.chartsToShow} xAxisData={companyInitialState.xAxisData} chartsLoading={chartsLoading} />
          )}
          {selectedOption.includes("subscriptionInvoice") && (
            <InvoiceDetails
              setSelectedOption={setSelectedOption}
              invoiceData={invoiceData}
              companyDetails={companyInitialState}
              handleSendInvoice={handleSendInvoice}
              showInvoiceSendModal={showInvoiceSendModal}
              onCancel={() => setshowInvoiceSendModal(false)}
              handleOpenInvoice={() => setshowInvoiceSendModal(true)}
              setShowSuccessModal={setShowSuccessModal}
              showSuccessModal={showSuccessModal}
              subscriptionData={subscriptionData}
            />
          )}
          {selectedOption === "users" && (
            <UserDetails
              userTableHeader={userTableHeader}
              users={allcompanyUsers}
              setSelectedOption={setSelectedOption}
              page={userPage}
              size={userSize}
              total={userTotal}
              setpage={setuserPage}
              setsize={setuserSize}
              handleSearchUsers={handleSearchUsers}
              setsearchText={setuserSearchText}
              searchText={userSearchText}
              userChartsData={userChartsData}
              chartsLoading={chartsLoading}
              selectedHeader={selectedUserHeader}
              handleArrowSortClick={handleArrowUserSortClick}
              handleSort={handleUserSort}
            />
          )}
          {selectedOption.includes("users_single") && <SingleUserDetails userData={singleUserData} setSelectedOption={setSelectedOption} />}
          {selectedOption === "sites" && (
            <SitesDetails
              page={sitePage}
              setPage={setsitePage}
              size={siteSize}
              setSize={setsiteSize}
              total={siteTotal}
              companySites={companySites}
              setSelectedOption={setSelectedOption}
              searchText={siteSearchText}
              handleSiteSearch={handleSiteSearch}
              setsiteSearchText={setsiteSearchText}
              siteHeadData={siteHeadData}
              selectedSiteHeader={selectedSiteHeader}
              handleArrowSortClick={handleArrowSortClick}
              handleSort={handleSort}
              chartsData={sitesChartsData}
              chartsLoading={chartsLoading}
            />
          )}
          {selectedOption.includes("sites_single") && <SingleSite siteData={singleSiteForEdit} setSelectedOption={setSelectedOption} />}
          {selectedOption === "forms" && (
            <FormsDetails
              formsHeadData={formsHeadData}
              setSelectedOption={setSelectedOption}
              selectedFormHeader={selectedFormHeader}
              handleArrowSortClick={handleFormSortArrow}
              handleSort={handleSortForms}
              handleFormSearch={handleFormsSearch}
              setFormSearchText={setFormSearchText}
              page={formsPage}
              size={formsSize}
              setsize={setFormsSize}
              total={formsTotal}
              setpage={setFormsPage}
              allForms={allForms}
              formChartsData={formChartsData}
              chartsLoading={chartsLoading}
            />
          )}
          {selectedOption.includes("forms_single") && (
            <SingleFormDetails
              singleFormData={singleFormForEdit}
              formData={formRules}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              selectedOptioin={selectedRuleOptioin}
              handleSelectedItem={handleSelectedRuleItem}
              selectedId={selectedId}
              restoreFormHandler={restoreFormHandler}
              showRestoreModal={showRestoreFormModal}
              onCancel={() => setshowRestoreFormModal(false)}
              handleOpenModal={() => setshowRestoreFormModal(true)}
              setShowSuccessModal={setshowSuccessRestoreFormModal}
              showSuccessModal={showSuccessRestoreFormModal}
            />
          )}
          {selectedOption === "reports" && (
            <ReportDetails
              setSelectedOption={setSelectedOption}
              allReports={allReports}
              page={reportPage}
              size={reportSize}
              setsize={setReportSize}
              total={reportTotal}
              setpage={setReportPage}
              selectedReportHeader={selectedReportHeader}
              handleArrowSortClick={handleReportSortArrow}
              handleSort={handleSortReports}
              handleReportSearch={handleReportSearch}
              setReportSearchText={setReportSearchText}
              chartsLoading={chartsLoading}
              ReportsHeadData={ReportsHeadData}
              reportSearchText={ReportSearchText}
              reportChartData={reportChartData}
            />
          )}
          {selectedOption.includes("reports_single") && (
            <SingleReportDetails
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              reportForEdit={reportForEdit}
              restoreReportHandler={restoreReportHandler}
              allUsers={allReportUsers}
              selectedOptioin={selectedUserOption}
              handleSelectedItem={handleSelectedUserItem}
              reportSetting={reportSetting}
              showRestoreModal={showRestoreReportModal}
              onCancel={() => setshowRestoreReportModal(false)}
              handleOpenRestoreModal={() => setshowRestoreReportModal(true)}
              setShowSuccessModal={setshowSuccessReportRestoreModal}
              showSuccessModal={showSuccessReportRestoreModal}
            />
          )}
          {selectedOption === "roles" && (
            <RoleDetails
              allRoles={allRoles}
              chartsData={rolesChartsData}
              chartsLoading={chartsLoading}
              handleArrowSortClick={handleRolesArrowSortClick}
              handleSearchRoles={handleSearchRoles}
              handleSort={handleRolesSort}
              page={rolesPage}
              rolesHeader={rolesTableHeader}
              searchText={rolesSearchText}
              selectedHeader={selectedRolesHeader}
              setSelectedOption={setSelectedOption}
              setpage={setRolesPage}
              setsearchText={setrolesSearchText}
              setsize={setRolesSize}
              size={rolesSize}
              total={rolesTotal}
            />
          )}
          {selectedOption.includes("roles_single") && <SingleRoleDetails roleForEdit={roleForEdit} setSelectedOption={setSelectedOption} />}
        </div>

        <div className=" col-lg-3 col-md-12 pb-2" style={{ height: "100%" }}>
          <CompanyLogs
            options={options}
            selectedValue={selectedValue}
            setselectedValue={setselectedValue}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setslectedLable={setslectedLable}
            slectedLable={slectedLable}
            totalCount={totalCount}
            companyLogs={companyLogs}
            searchValue={logSearchText}
            setsearchValue={setlogSearchText}
            handleSearchLogs={handleSearchLogs}
            resetSearchingHandler={resetSearchingHandler}
            closeSearchBarIfValue={closeSearchBarIfValue}
            setCloseSearchBarIfValue={setCloseSearchBarIfValue}
            limit={size}
            LoadMoreLogs={LoadMoreLogs}
            title={refactorLogsTilte(selectedOption)}
            notificationSection={selectedOption === "overview" ? true : false}
            subscription={selectedOption === "subscription" ? true : false}
            seatsData={subscriptionData?.subscription.seats}
            price={subscriptionData?.subscription.currentPlan.price}
            dateSection={selectedOption.includes("subscriptionInvoice") ? false : true}
            logSection={selectedOption.includes("subscriptionInvoice") ? false : true}
            selectedOption={selectedOption}
            invoiceData={invoiceData}
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyOverview;
