import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../Components/form-input/input-component";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import * as authActions from "../../redux/auth/authAction";
import { useRef, useState } from "react";
import { MAX_LENGTH, MIN_LENGTH, PASSWORD_REGEX } from "../../config/validations";

interface InititalStateDTO {
  password: string;
  confirmPassword: string;
}
const registerInitialValues = {
  password: "",
  confirmPassword: "",
};

function ResetPassword() {
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
   } = useForm({ defaultValues: registerInitialValues });
   
   const {token}=useParams()
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch: AppDispatch | any = useDispatch();
  const navigate = useNavigate();
  const handleResetPassword = (data: InititalStateDTO) => {
    let resetData = { password: data.password };
    dispatch(authActions.resetPassword(resetData,token!, navigate));
   };
   
//   console.log(errors, "errors");
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-heading">Password Reset</div>
        <div className="reset-subheading">Please enter your email to receive a password reset message</div>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <div className="form-controls">
            <Input
              id="password"
              name="password"
              register={register}
              type="password"
              placeholder="New Password"
              className="w-100 reset-email"
              validations={{
                required: {
                  value: true,
                  message: "Password is required",
                 },
                 minLength: { value: 8, message: MIN_LENGTH(8) },
                 maxLength: { value: 50, message: MAX_LENGTH(50) },
                 pattern: {
                  value: PASSWORD_REGEX,
                  message: "invalid password",
                },
              }}
              error={errors["password"]}
            />
            <Input
              id="confirmPassword"
              name="confirmPassword"
              register={register}
              type="password"
              placeholder="Confirm Password"
              className="w-100 reset-email"
              validations={{
                required: {
                  value: true,
                  message: "Confirm password is required",
                 },
                 minLength: { value: 8, message: MIN_LENGTH(8) },
                 maxLength: { value: 50, message: MAX_LENGTH(50) },
                 pattern: {
                  value: PASSWORD_REGEX,
                  message: "invalid password",
                },
               
                validate: (value: string) => value === password.current || "The passwords do not match",
              }}
              error={errors["confirmPassword"]}
            />
            <div className="robot-wrapper">
              <input checked={isChecked} onChange={() => setIsChecked(!isChecked)} className="checkbox" type="checkbox" />
              <div>I'm not a robot</div>
            </div>
            <div className="btn-reset-wrapper">
              <Link to="/">
                <button className="back-btn">Back</button>
              </Link>
              <button type="submit" disabled={!isChecked || errors["confirmPassword"]?true:false || errors["password"]?true:false}>
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
