import axios from "axios";
import { DeleteCompanySearchDTO } from "../../interfaces/companiesInterface";

export const getAllDeletedCompanies = (searchState?: DeleteCompanySearchDTO) => {
  return axios.get(
    `admin/company/deleted?${searchState?.searchName ? `searchName=${searchState?.searchName}`: ""}${searchState?.size ? `&size=${searchState?.size}` : ""}${searchState?.page ? `&page=${searchState.page}` : ""}${
      searchState?.countries && searchState.countries.length > 0
        ? `&countries=${searchState?.countries.join(",")}`
        : !searchState?.countries
        ? ""
        : "&countries=undefined"
    }${searchState && searchState.sortAt ? `&sortAt=${searchState.sortAt}` : ""}${
      searchState && searchState.sortBy ? `&sortBy=${searchState.sortBy}` : ""
    }${searchState?.days && searchState.days.length > 0 ? `&days=${searchState?.days.join(",")}` : !searchState?.days ? "" : "&days=undefined"}`
  );
};

export const restoreDeletedCompanies = (ids: string[]) => {
  return axios.patch(`admin/company/delete?${ids.length > 0 ? `companiesIds=${ids.join(",")}&isDeleted=false` : ""}`);
};

export const getAllDeletedCountries = (searchString?:string) => {
  return axios.get(`admin/company/countries?${searchString?`&searchName=${searchString}`:""}&isDeleted=true`)

}