import axios from "axios";

export const AUTH_URL = "admin/auth";

export function login(data: object) {
  return axios.post(`${AUTH_URL}/login`, data);
}
export function changePassword(data: object) {
  return axios.patch(`${AUTH_URL}/change-password`, data);
}

export function whoAmI() {
  return axios.get(`${AUTH_URL}/whoAmI`);
}
export function passwordForgot(data: object) {
  return axios.post(`${AUTH_URL}/forgot-password`,data)
}
export function passwordReset(data: object, token:string) {
  return axios.post(`${AUTH_URL}/reset-password?${token?`token=${token}`:""}`,data)
}