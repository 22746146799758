import axios from "axios";
import { CompanySearchDTO } from "../../interfaces/companiesInterface";
import {
  CompanyLogsSearchDTO,
  CompanyRoleSearchDTO,
  CompanySearchInterface,
  CompanySubscriptionSearchDTO,
  InvoiceEmailPayload,
} from "../../interfaces/companyInterface";
import { CompanyUsersSearchDTO } from "../../interfaces/companyInterface";
import { SiteSearchDTO } from "../../interfaces/companyInterface";
import moment from "moment";
export const AUTH_URL = "admin";

export const getAllCompanies = (searchState: CompanySearchDTO) => {
  return axios.get(
    `${AUTH_URL}/company?${searchState.searchName ? `searchName=${searchState.searchName}` : ""}${
      searchState && searchState.subscriptionPlan && searchState?.subscriptionPlan?.length > 0
        ? `&subscriptionPlan=${searchState?.subscriptionPlan?.join(",")}`
        : !searchState.subscriptionPlan
        ? ""
        : "&subscriptionPlan=undefined"
    }${
      searchState && searchState?.subscriptionStatus && searchState.subscriptionStatus?.length > 0
        ? `&subscriptionStatus=${searchState?.subscriptionStatus?.join(";")}`
        : !searchState.subscriptionStatus
        ? ""
        : "&subscriptionStatus=undefined"
    }${
      searchState && searchState?.countries && searchState.countries.length > 0
        ? `&countries=${searchState?.countries?.join(",")}`
        : !searchState.countries
        ? ""
        : "&countries=undefined"
    }${
      searchState && searchState.subscriptionSeats && searchState?.subscriptionSeats?.length > 0
        ? `&subscriptionSeats=${searchState?.subscriptionSeats?.join(",")}`
        : !searchState.subscriptionSeats
        ? ""
        : "&subscriptionSeats=undefined"
    }${searchState && searchState.sortAt ? `&sortAt=${searchState.sortAt}` : ""}${
      searchState && searchState.sortBy ? `&sortBy=${searchState.sortBy}` : ""
    }&page=${searchState?.page ? searchState.page : 1}&size=${searchState?.size}&isSuspended=${
      searchState?.isSuspended === true || searchState?.isSuspended === false ? searchState.isSuspended : undefined
    }`
  );
};

export const deleteSelectedCompanies = (ids: string[]) => {
  return axios.patch(`${AUTH_URL}/company/delete?${ids.length > 0 ? `companiesIds=${ids.join(",")}&isDeleted=true` : ""}`);
};
export const suspendSelectedCompanies = (ids: string[], reason: string) => {
  return axios.patch(`${AUTH_URL}/company/suspended?${ids.length > 0 ? `companiesIds=${ids.join(",")}&isSuspended=true` : ""}`, {
    reasonForSuspend: reason,
  });
};
export const resumeSuspendedCompanies = (ids: string[]) => {
  return axios.patch(`${AUTH_URL}/company/suspended?${ids.length > 0 ? `companiesIds=${ids.join(",")}&isSuspended=false` : ""}&reasonForSuspend=""`);
};
export const getCompanyById = (searchState: CompanySearchInterface) => {
  return axios.get(
    `${AUTH_URL}/company/${searchState?.id}${searchState?.startDate ? `?startDate=${moment(searchState.startDate).format("YYYY/MM/DD")}` : ""}${
      searchState?.endDate ? `&endDate=${moment(searchState.endDate).format("YYYY/MM/DD")}` : ""
    }`
  );
};

export const emailSendToCompany = (ids: string[], emailData: { subject: string; message: string }) => {
  return axios.patch(`${AUTH_URL}/company/email-send?${ids.length > 0 ? `companiesIds=${ids.join(",")}` : ""}`, { emailData });
};

export const getAllCountries = (searchString?: string) => {
  return axios.get(`${AUTH_URL}/company/countries?${searchString ? `&searchName=${searchString}` : ""}&isDeleted=false`);
};
export const updateComapnyUser = (dataToUpdate: string[], isDeletedCompanyAction: boolean) => {
  return axios.patch(`${AUTH_URL}/company/user-to-update`, { companyTableToShow: dataToUpdate, isDeletedCompanyAction });
};

export const getCompanyLogsById = (searchState: CompanyLogsSearchDTO) => {
  return axios.get(
    `${AUTH_URL}/company/AllModuleLogs/${searchState?.id}${searchState?.limit ? `?limit=${searchState.limit}` : ""}${
      searchState?.startDate ? `&startDate=${searchState.startDate}` : ""
    }${searchState?.endDate ? `&endDate=${searchState.endDate}` : ""}${searchState?.searchName ? `&searchName=${searchState.searchName}` : ""}${
      searchState?.logSearchFor === "overview"
        ? `&logSearchFor=company`
        : `&logSearchFor=${searchState?.logSearchFor}${searchState.actionId ? `&actionId=${searchState.actionId}` : ""}`
    }`
  );
};
//subscription
export const getCompanySubscriptionById = (searchState: CompanySubscriptionSearchDTO) => {
  return axios.get(
    `${AUTH_URL}/company/subscription/${searchState?.id}${searchState?.startDate ? `?startDate=${searchState.startDate}` : ""}${
      searchState?.endDate ? `&endDate=${searchState.endDate}` : ""
    }`
  );
};

export const getSubscriptionInvoiceById = (id: string) => {
  return axios.get(`invoices/${id}`);
};
export const sendInvoiceEmailToCompany = (data: InvoiceEmailPayload) => {
  return axios.post(`invoices/email-send`, data);
};

//sites

export const getAllCompanyUsers = (searchState: CompanyUsersSearchDTO) => {
  return axios.get(
    `users/admin-company?companyId=${searchState.companyId}${searchState.page ? `&page=${searchState.page}` : ""}${
      searchState.size ? `&size=${searchState.size}` : ""
    }${searchState?.searchItem ? `&searchItem=${searchState.searchItem}` : ""}${
      searchState?.sortAt ? `&sortAt=${searchState.sortAt}${searchState.sortBy ? `&sortBy=${searchState.sortBy}` : ""}` : ""
    }`
  );
};
export const getUserChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => {
  return axios.get(`admin/company/users/${id}?${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
};
export const getSingleUserById = (id: string) => {
  return axios.get(`users/${id}`);
};
export const getRolesChartsDataByCompanyId = (id: string, startDate: string, endDate: string) => {
  return axios.get(`admin/company/roles/${id}?${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
};

export const getAllCompanyRoles = (searchState: CompanyRoleSearchDTO) => {
  return axios.get(
    `roles/admin-company?companyId=${searchState.companyId}${searchState.page ? `&page=${searchState.page}` : ""}${
      searchState.size ? `&size=${searchState.size}` : ""
    }${searchState?.searchItem ? `&searchItem=${searchState.searchItem}` : ""}${
      searchState?.sortAt ? `&sortAt=${searchState.sortAt}${searchState.sortBy ? `&sortBy=${searchState.sortBy}` : ""}` : ""
    }`
  );
};

export const getSingleRoleById = (id: string) => {
  return axios.get(`roles/${id}`);
};
export const getAllCompanySites = (searchState: SiteSearchDTO) => {
  return axios.get(
    `sites/admin-sites?${searchState?.companyId ? `&companyId=${searchState.companyId}` : ""}${searchState?.name ? `&name=${searchState.name}` : ""}${
      searchState?.sortBy ? `&sortBy=${searchState.sortBy}` : ""
    }&page=${searchState?.page}&size=${searchState.size}&sortAt=${searchState.sortAt}`
  );
};

export const getSingleCompanySiteById = (id: string) => {
  return axios.get(`sites/${id}`);
};

export const getSitesChartsDataByCompanyId = (id: string, startDate?: string, endDate?: string) => {
  return axios.get(`admin/company/sites/${id}${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
};

export const getCompanyPaymentCardDataById = (id: string) => {
  return axios.get(`payments/getCard/${id}`);
};

//forms
export const getAllCompanyForms = (searchState: SiteSearchDTO) => {
  return axios.get(
    `forms/admin-company?${searchState?.companyId ? `companyId=${searchState.companyId}` : ""}${
      searchState?.name ? `&searchItem=${searchState.name}` : ""
    }${searchState?.sortBy ? `&sortBy=${searchState.sortBy}` : ""}&page=${searchState?.page}&size=${searchState.size}&sortAt=${searchState.sortAt}`
  );
};

export const getSingleCompanyFormById = (id: string) => {
  return axios.get(`forms/${id}`);
};
export const getFormDispatchRuleById = (id: string) => {
  return axios.get(`dispatch-rules/${id}`);
};

export const getFormsChartsDataByCompanyId = (id: string, startDate?: string, endDate?: string) => {
  return axios.get(`admin/company/forms/${id}${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
};
export const restoreDeletedFormById = (id: string) => {
  return axios.patch(`forms/${id}/restore`);
};

//reports
export const getAllCompanyReports = (searchState: SiteSearchDTO) => {
  return axios.get(
    `reports/admin-company?${searchState?.companyId ? `companyId=${searchState.companyId}` : ""}${
      searchState?.name ? `&searchItem=${searchState.name}` : ""
    }${searchState?.sortBy ? `&sortBy=${searchState.sortBy}` : ""}&page=${searchState?.page}&size=${searchState.size}&sortAt=${searchState.sortAt}`
  );
};

export const getSingleCompanyReportById = (id: string) => {
  return axios.get(`reports/${id}`);
};
export const getReportsChartsDataByCompanyId = (id: string, startDate?: string, endDate?: string) => {
  return axios.get(`admin/company/reports/${id}${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
};
export const restoreReportById = (id: string) => {
  return axios.patch(`reports/${id}/restore`);
};
export const getAllReportSettingUsersByReportId = (id: string) => {
  return axios.get(`reportSetting/${id}/users`);
};

export const getReportSettingByUserAndReportId = (userId: string, reportId: string) => {
  return axios.get(`reportSetting/${reportId}/${userId}`);
};
