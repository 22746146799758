import moment from "moment";
import ReactCountryFlag from "react-country-flag";
function SingleUserDetails({ setSelectedOption, userData }: { setSelectedOption: any; userData: any }) {
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">User</div>
        <button className="back-btn" onClick={() => setSelectedOption("users")}>
          Back
        </button>
      </div>

      <div className="subscription-id">ID {userData?.userBuisnessId}</div>
      {/* user info */}
      <div className="subscription-invoice-wrap mt-2">
        <div className="title pt-2 ">User info</div>
        <div className="user-detail-wrap">
          <div>Name</div>
          <div>{userData?.fullName}</div>
          <div>Email</div>
          <div>{userData?.email}</div>
          <div>Mobile</div>
          <div>{userData?.mobile}</div>
          <div>Time Zone</div>
          <div>{userData?.timeZone}</div>
          <div>Status</div>
          <div>{userData?.isDeleted ? "Inactive" : "Active"}</div>
        </div>
      </div>
      {/* presence */}
      <div className="subscription-invoice-wrap mt-2">
        <div className="title pt-2 ">Presence </div>
        <div className="user-detail-wrap">
          <div>Last seen</div>
          {/* <div>28 Aug 2022 19:48:36</div> */}
          <div>
            {moment(userData?.updatedAt).format("DD MMM YYYY")} <span className="ml-1">{moment(userData?.updatedAt).format("HH:mm:ss")}</span>{" "}
          </div>
          <div>Login Attempts</div>
          <div>{userData?.loginAttempts}</div>
          <div>IP Address</div>
          <div className="d-flex align-items-center">
            {userData?.platformInfo?.country ? (
              <>
                <span>
                  <ReactCountryFlag
                    countryCode={userData?.platformInfo?.country}
                    className="emojiFlag"
                    svg
                    style={{ borderRadius: "50%", fontSize: "20px" }}
                  />
                  {/* <img src={flag} alt="flag" /> */}
                </span>
                <span className="ml-1 " style={{ marginTop: "1px" }}>
                  {userData?.platformInfo?.ipAddress}
                </span>
              </>
            ) : (
              "-"
            )}
          </div>
          <div>Platform</div>
          {userData?.platformInfo?.platform ? <div>{userData?.platformInfo?.platform}</div> : "-"}
        </div>
      </div>

      {/* checkboxes */}

      <div className="subscription-invoice-wrap mt-2">
        <div className="title pt-2 ">Notifications</div>

        <div className="user-grid">
          <div>
            <div className="mt-4">Assign Form</div>
          </div>
          <div>
            <div>App</div>
            <input
              type="checkbox"
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              checked={userData?.assignForm?.app}
            />
          </div>
          <div>
            <div>Email</div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.assignForm?.email}
            />
          </div>
          <div>
            <div>SMS</div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.assignForm?.sms}
            />
          </div>
        </div>

        <div className="user-grid">
          <div>
            <div className="mt-2">Report Submission</div>
          </div>
          <div>
            <input
              type="checkbox"
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              checked={userData?.reportSubmission?.app}
            />
          </div>
          <div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.reportSubmission?.email}
            />
          </div>
          <div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.reportSubmission?.sms}
            />
          </div>
        </div>
        <div className="user-grid">
          <div>
            <div className="mt-2">Approval of the report</div>
          </div>
          <div>
            <input
              type="checkbox"
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              checked={userData?.approvalReport?.app}
            />
          </div>
          <div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.approvalReport?.email}
            />
          </div>
          <div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.approvalReport?.sms}
            />
          </div>
        </div>
        <div className="user-grid">
          <div>
            <div className="mt-2">Reject report</div>
          </div>
          <div>
            <input
              type="checkbox"
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              checked={userData?.rejectReport?.app}
            />
          </div>
          <div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.rejectReport?.email}
            />
          </div>
          <div>
            <input
              className="custom-user-checkbox my-2 ml-1"
              style={{
                height: "15px",
                width: "15px",
                border: "none",
                background: "#F2F2F2",
              }}
              type="checkbox"
              checked={userData?.rejectReport?.sms}
            />
          </div>
        </div>
      </div>

      {/* role */}
      <div className="subscription-invoice-wrap mt-2">
        <p className="title">Device allowance : {userData?.userDevices?.length}/3</p>
        {userData?.userDevices?.map((item: any, index: number) => {
          return (
            <div className={index > 0 ? "mt-2" : ""} key={index}>
              {item?.deviceName}
            </div>
          );
        })}
        {/* <div>Iphone x</div> 
        <div className="mt-2">Galaxy</div> */}
      </div>
      <div className="subscription-invoice-wrap mt-2">
        <div className="title">Role</div>
        <div className="user-detail-wrap">
          <div>Role Name</div>
          <div>{userData?.roleName === "" ? userData?.role : userData?.roleName}</div>
        </div>
      </div>
    </div>
  );
}

export default SingleUserDetails;
