import moment from "moment";
import SingleDummyChart from "../charts/SingleChart";
import Skeleton from "react-loading-skeleton";
import { invoiceTranslation } from "../../../helper/helperFunction";
function SubscriptionDetails({
  setSelectedOption,
  subscriptionData,
  chartsLoading,
  chartsToShow,
  paymentCardData,
}: {
  setSelectedOption: any;
  subscriptionData: any;
  chartsLoading?: boolean;
  chartsToShow: any;
  paymentCardData?: any;
}) {
  let taxValue = subscriptionData?.subscription?.currentPlan?.seats * subscriptionData?.subscription?.currentPlan?.price * 0.15;
  // console.log(subscriptionData, "from inner");
  return (
    <>
      <div className="main-overview-wrap">
        <div className="title-subscription">Subscription</div>
        <button className="back-btn" onClick={() =>{
          setSelectedOption("overview");
        }}>
          Back
        </button>
      </div>
      <div className="subscription-id">ID {subscriptionData?.subscription?.subscriptionId}</div>
      <div className="mt-2" style={{ display: "flex", gap: "10px", width: "100%" }}>
        {!chartsLoading && chartsToShow ? (
          <>
            <SingleDummyChart
              title={chartsToShow[0]?.title}
              analyticsXaxisData={subscriptionData?.xAxisData}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[0]}
              color="#BB6BD9"
              totalResult={chartsToShow[0]?.value}
            />
            <SingleDummyChart
              title={chartsToShow[1]?.title}
              analyticsXaxisData={subscriptionData?.xAxisData}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[1]}
              color="#6FCF97"
              totalResult={chartsToShow[1]?.value}
            />
          </>
        ) : (
          <>
            <div className="bg-white circle p-2 w-50">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
            <div className="bg-white circle p-2 w-50">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
          </>
        )}
      </div>

      <div className="payment-card-wrap mt-2">
        <p className="title">Payment cards :</p>
        {paymentCardData !== undefined ? (
          <div className="rows-wrap">
            <div>
              {paymentCardData?.brand} : <span>**********</span>
              {paymentCardData?.last_four}
            </div>
            <div>
              Exp : {paymentCardData?.month} / {paymentCardData?.year}
            </div>
          </div>
        ) : null}
      </div>
      <div className="payment-card-wrap mt-2">
        <p className="title">Billing Details :</p>
        <div className="rows-wrap my-2">
          <div>Tax Number</div>
          <div>{subscriptionData?.taxNumber}</div>
        </div>
        <div className="rows-wrap">
          <div>Currency</div>
          <div>{subscriptionData?.invoices[0]?.currency}</div>
        </div>
      </div>
      <div className="payment-card-wrap mt-2">
        <p className="title">Billing History :</p>
        <div className="rows-wrap">
          <div>Next Bill</div>
          <div style={{ marginLeft: "50px", color: "#828282" }}> {moment(subscriptionData?.subscriptionEndedAt).format("DD MMM YYYY")}</div>
          <div></div>
          <div style={{ color: "#828282", marginRight: "25px" }}>
            SAR{" "}
            {(subscriptionData?.subscription?.currentPlan?.seats * subscriptionData?.subscription?.currentPlan?.price + taxValue)
              ?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>

        {subscriptionData?.invoices?.map((item: any, index: number) => {
          let SubTotal =
            item?.subscriptionData?.currentPlan?.plan !== "Additional seats"
              ? item?.subscriptionData?.currentPlan?.seats * item?.subscriptionData?.currentPlan?.price
              : item?.subscriptionData?.lastAdditionalseats?.seats * item?.subscriptionData?.currentPlan?.price;
          let taxPrice = (15 / 100) * SubTotal;
          let total = taxPrice + SubTotal;
          return (
            <div className="row my-3" key={index}>
              <span className=" col-4 title-blue" onClick={() => setSelectedOption(`subscriptionInvoice_${item.id}`)}>
                {invoiceTranslation(item?.subscriptionData?.currentPlan?.plan)}{" "}
              </span>
              <span className="col-3 date">{moment(item.createdAt).format("DD MMM YYYY")}</span>
              <span className="col-3 invoice-id"> A1-{item.invoiceDummyId}</span>
              <span className="col-2 date">
                {item.currency}{" "}
                {total
                  ?.toFixed(2)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SubscriptionDetails;
