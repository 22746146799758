import CustomPagination from "../../companies/CustomPagination";
import SingleDummyChart from "../charts/SingleChart";
import sortArrow from "../../../assets/images/sortArrow.svg";
import searchIcon from "../../../assets/images/siteSearchIcon.svg";
import Skeleton from "react-loading-skeleton";

function ReportDetails({
  setSelectedOption,
  allReports,
  page,
  size,
  total,
  setsize,
  setpage,
  selectedReportHeader,
  handleArrowSortClick,
  handleSort,
  handleReportSearch,
  setReportSearchText,
  chartsLoading,
  ReportsHeadData,
  reportSearchText,
  reportChartData,
}: {
  setSelectedOption: any;
  allReports: any;
  page: number;
  size: number;
  total: number;
  setsize: any;
  setpage: any;
  selectedReportHeader: any;
  handleArrowSortClick: any;
  handleSort: any;
  handleReportSearch: any;
  setReportSearchText: any;
  chartsLoading: any;
  ReportsHeadData: any;
  reportSearchText: any;
  reportChartData: any;
}) {
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Reports</div>
        <button className="back-btn" onClick={() => window.history.back()}>
          Back
        </button>
      </div>
      {!chartsLoading && reportChartData && reportChartData?.chartsData ? (
        <div className="mt-4">
          <SingleDummyChart
            title={`Total ${reportChartData?.chartsData[0]?.title}`}
            analyticsXaxisData={reportChartData?.xAxisData}
            className=" shadow-lg mt-3 w-100 "
            totalResult={reportChartData?.chartsData[0]?.value}
            color="#56CCF2"
            selectedItem={reportChartData?.chartsData[0]}
            dateToShow={true}
          />
        </div>
      ) : (
        <div className="bg-white circle p-2 w-100 mt-4">
          <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
            <Skeleton width={100} />
            <Skeleton width={100} />
          </div>
          <Skeleton count={7} />
        </div>
      )}

      <div className="payment-card-wrap mt-3 w-100">
        <div className="sites-search-wrap ml-auto" style={{ width: "147px" }}>
          <img src={searchIcon} alt="searchIcon" />
          <input
            type="text"
            placeholder="Search in reports"
            className="sites-searchBar"
            onChange={(e: any) => {
              handleReportSearch(e.target.value);
              setReportSearchText(e.target.value);
            }}
            value={reportSearchText}
          />
        </div>
        <div className="mt-4 table-responsive">
          <table className="table-main table">
            <thead>
              <tr className="table-header">
                {ReportsHeadData?.map((item: any, index: number) => {
                  return (
                    <th key={index} style={{whiteSpace:"nowrap" }}>
                      <span
                        style={{ color: "#4F4F4F", cursor: "pointer"}}
                        onClick={() => {
                          handleSort(item);
                        }}
                      >
                        {item.label}
                      </span>
                      {item.label === selectedReportHeader.label ? (
                        <span>
                          <img
                            className="ml-2"
                            id="report-sort-icon"
                            src={sortArrow}
                            alt="sort-arrow"
                            onClick={handleArrowSortClick}
                            style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99", color: "inherit" }}
                          />{" "}
                        </span>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {allReports?.map((item: any, index: number) => {
                return (
                  <tr
                    onClick={() => {
                      setSelectedOption(`reports_single_${item._id}`);
                    }}
                    key={index}
                    className={item?.isDeleted ? "red-form-color table-row" : item?.isArchived ? "orange-form-color table-row" : " table-row"}
                  >
                    <td>{item.reportBuisnessId}</td>
                    <td>{item.formName}</td>
                    <td>{item.reportName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CustomPagination page={page} setSize={setsize} setpage={setpage} size={size} total={total} />
        </div>
      </div>
    </div>
  );
}

export default ReportDetails;
