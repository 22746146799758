import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../Pages/auth/Login";
import Companies from "../Pages/companies/Companies";
import ForgotPassword from "../Pages/auth/ForgotPassword";
import ResetPassword from "../Pages/auth/ResetPassword";
import CompanyOverview from "../Pages/company/CompanyOverview";
import { useEffect } from "react";
import { whoAmI } from "../redux/auth/authCrud";
import Header from "../Components/Layout/Header";
import Footer from "../Components/Layout/Footer";
import DeletedCompanies from "../Pages/companies/DeletedCompanies";
import ViewInvoice from "../Pages/company/ViewInvoice";
function AppRoutes() {
  const navigate = useNavigate();
  const isPublic = true;
  useEffect(() => {
    !isPublic &&
      whoAmI()
        .then((res) => {
          if (
            window.location.pathname === "/" ||
            window.location.pathname.includes("forgot-password") ||
            window.location.pathname.includes("reset-password")
          )
            navigate("/companies");
        })
        .catch((e) => {
          if (!window.location.pathname.includes("forgot-password") && !window.location.pathname.includes("reset-password")) {
            navigate("/");
          }
        });

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {window.location.pathname.includes("companies") || window.location.pathname.includes("company") ? <Header /> : null}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/deleted-companies" element={<DeletedCompanies />} />
        <Route path="/company/:id" element={<CompanyOverview />} />
        {isPublic && <Route path="/view-invoice/:id" element={<ViewInvoice />} />}
      </Routes>
      {window.location.pathname.includes("companies") || window.location.pathname.includes("company") ? <Footer /> : null}
    </>
  );
}

export default AppRoutes;
