import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { reducer } from "./rootReducer";

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware:any) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }).concat(),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;

export default store;
