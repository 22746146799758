import { GenaricListDTO } from "../DTO/formDTO";
import { TEXT_DIRECTION } from "../../../helper/helperFunction";
import CustomMultiSelect from "../../../Components/form-input/multi-select";

interface FilterFormIProps {
  countryList: GenaricListDTO[];
  statusList?: GenaricListDTO[];
  planList?: GenaricListDTO[];
  seatsList?: GenaricListDTO[];
  daysList?: any;
  setCountryList: any;
  setSeatsList?: any;
  setstatusList?: any;
  setPlanList?: any;
  handleSelectCountry: any;
  handleSelectAllCountry: any;
  handleSelectAllStatus?: any;
  handleSelectAllSeats?: any;
  handleSelectAllPlan?: any;
  handleSelectPlan?: any;
  handleSelectSeats?: any;
  handleSelectStatus?: any;
  handleReset: any;
  handleSelectDays?: any;
  handleSelectAllDays?: any;

  handleCountrySearch: any;
}
function FilterForm({
  statusList,
  planList,
  seatsList,
  countryList,
  setCountryList,
  setSeatsList,
  setstatusList,
  setPlanList,
  daysList,
  handleSelectCountry,
  handleSelectAllCountry,
  handleCountrySearch,
  handleSelectAllStatus,
  handleSelectAllSeats,
  handleSelectAllPlan,
  handleSelectPlan,
  handleSelectSeats,
  handleReset,
  handleSelectStatus,
  handleSelectAllDays,
  handleSelectDays,
}: FilterFormIProps) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "4px",
        position: "absolute",
        left: "2px",
        width: "270px",
        marginTop: "10px",
        paddingLeft:"15px",
        paddingTop: "27px",
        zIndex:100
      }}
      className="shadow"
    >
      <div>
        <div
          className={`reset ${TEXT_DIRECTION() === "rtl" ? "float-left" : "float-right"}`}
          onClick={() => {
            handleReset();
          }}
          style={{
            color: "#20639B",
            fontWeight: 500,
            fontSize: "14px",
            cursor: "pointer",
            lineHeight: "15.92px",
            marginTop: "-36px",
            paddingLeft: "3px",
            paddingRight: "12px",
          }}
        >
          {/* {i18n("Analytics.ResetAll")} */}
          Reset All
        </div>

        <div className="custom-multi-select mt-4 ">
          {/* <span className="form-lable">{i18n("Analytics.Form")}</span> */}
          <span className="form-lable">Country</span>
          <CustomMultiSelect
            options={countryList}
            onSelect={() => {}}
            handleSelectAll={handleSelectAllCountry}
            handleSelectedItem={handleSelectCountry}
            handleSearch={handleCountrySearch}
            formField={"COUNTRY"}
          
          />
        </div>
        {daysList?.length ? (
          <div className="custom-multi-select my-4">
            <span className="form-lable">Days</span>
            <CustomMultiSelect options={daysList} onSelect={() => {}} handleSelectAll={handleSelectAllDays} handleSelectedItem={handleSelectDays} />
          </div>
        ) : (
          <>
            <div className="custom-multi-select mt-4">
              <span className="form-lable ">Plan</span>
              <CustomMultiSelect options={planList} onSelect={() => {}} handleSelectAll={handleSelectAllPlan} handleSelectedItem={handleSelectPlan} />
            </div>

            <div className="custom-multi-select mt-4">
              <span className="form-lable">Status</span>
              <CustomMultiSelect
                options={statusList}
                onSelect={() => {}}
                handleSelectAll={handleSelectAllStatus}
                handleSelectedItem={handleSelectStatus}
              />
            </div>
            <div className="custom-multi-select my-4">
              <span className="form-lable">Seats</span>
              <CustomMultiSelect
                options={seatsList}
                onSelect={() => {}}
                handleSelectAll={handleSelectAllSeats}
                handleSelectedItem={handleSelectSeats}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FilterForm;
