// import { useParams } from "react-router";
import SingleDummyChart from "../charts/SingleChart";
// import Table from "../../companies/table/Table";
import searchIcon from "../../../assets/images/siteSearchIcon.svg";
import CustomPagination from "../../companies/CustomPagination";
import sortArrow from "../../../assets/images/sortArrow.svg";
import Skeleton from "react-loading-skeleton";

function SitesDetails({
  setSelectedOption,
  companySites,
  page,
  size,
  setPage,
  setSize,
  total,
  searchText,
  handleSiteSearch,
  setsiteSearchText,
  siteHeadData,
  selectedSiteHeader,
  handleArrowSortClick,
  handleSort,
  chartsData,
  chartsLoading,
}: {
  setSelectedOption: any;
  companySites: any;
  page: number;
  size: number;
  setPage: any;
  setSize: any;
  total: number;
  searchText: string;
  handleSiteSearch: any;
  setsiteSearchText: any;
  siteHeadData: any;
  selectedSiteHeader: any;
  handleArrowSortClick: any;
  handleSort: any;
  chartsData: any;
  chartsLoading: any;
}) {
  return (
    <>
      <div className="main-overview-wrap">
        <div className="title-subscription">Sites</div>
        <button className="back-btn" onClick={() => window.history.back()}>
          Back
        </button>
      </div>
      <div className="mt-4 chart-wrap">
        {!chartsLoading && chartsData && chartsData?.chartsData ? (
          <div className="mt-2">
            <SingleDummyChart
              title={chartsData?.chartsData?.title}
              analyticsXaxisData={chartsData?.xAxisData}
              className=" shadow-lg mt-3   w-100 "
              totalResult={chartsData?.value}
              color="#F2994A"
              dateToShow
              selectedItem={chartsData?.chartsData}
            />
          </div>
        ) : (
          <div className="bg-white circle p-2 w-100">
            <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
              <Skeleton width={100} />
              <Skeleton width={100} />
            </div>
            <Skeleton count={7} />
          </div>
        )}
      </div>

      <div className="payment-card-wrap mt-2 w-100 h-100">
        <div className="sites-search-wrap ml-auto">
          <img src={searchIcon} alt="searchIcon" />
          <input
            type="text"
            placeholder="Search in sites"
            className="sites-searchBar"
            onChange={(e: any) => {
              handleSiteSearch(e.target.value);
              setsiteSearchText(e.target.value);
            }}
          />
        </div>
        <div className="mt-2">
          <table className="table-main table">
            <thead>
              <tr className="table-header">
                {siteHeadData.map((item: any, index: number) => {
                  // let selectedItem = item.id === selectedSiteHeader.id;
                  return (
                    <th key={index}>
                      <span
                        style={{ color: "#4F4F4F", cursor: "pointer" }}
                        onClick={() => {
                          handleSort(item);
                        }}
                      >
                        {item.label}
                      </span>

                      {selectedSiteHeader.label === item.label ? (
                        <span>
                          <img
                            className="ml-2"
                            id="site-sort-icon"
                            src={sortArrow}
                            alt="sort-arrow"
                            onClick={handleArrowSortClick}
                            style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99", color: "inherit" }}
                          />
                        </span>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {companySites?.map((item: any, index: number) => {
                return (
                  <tr
                    className={item.isDeleted ? " table-row deleted" : "table-row"}
                    key={index}
                    onClick={() => {
                      setSelectedOption(`sites_single_${item.id}`);
                    }}
                  >
                    <td>{item.siteId}</td>
                    <td>{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CustomPagination page={page} setSize={setSize} setpage={setPage} size={size} total={total} />
        </div>
      </div>
    </>
  );
}

export default SitesDetails;
