function SingleSite({ setSelectedOption, siteData }: { setSelectedOption: any; siteData: any }) {
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Site</div>
        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption("sites");
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">ID {siteData.siteId}</div>
      <div className="site-detail-wrap mt-2">
        <div>Status:</div>
        <div>{siteData?.active ? "Active" : "Inactive"}</div>
        <div>Site Name:</div>
        <div>{siteData?.name}</div>
        <div>Site Email:</div>
        <div>{siteData?.email}</div>
        <div>Site Phone:</div>
        <div>{siteData?.mobile}</div>
        <div>Address:</div>
        <div>{siteData?.address}</div>
        <div>City:</div>
        <div>{siteData?.city}</div>
        <div>State:</div>
        <div>{siteData?.state}</div>
        <div>Postal Code:</div>
        <div>{siteData?.postalCode}</div>
        <div>Country:</div>
        <div>{siteData?.country}</div>
      </div>
    </div>
  );
}

export default SingleSite;
