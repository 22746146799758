
import { TEXT_DIRECTION } from "../../../helper/helperFunction";
import { GenaricListDTO } from "../DTO/formDTO";

interface ColumnFilterIProps {
  setColumnHeaders: any;
  columnHeaders: GenaricListDTO[];
  isHeaderSelected: boolean;
  handleSelectColumn: any;
  handleSelectAllColumns: any;

}
function ColumnFilter({ columnHeaders, setColumnHeaders, isHeaderSelected, handleSelectColumn, handleSelectAllColumns,  }: ColumnFilterIProps) {

  return (
    <div>
      {isHeaderSelected ? (
        <div
          style={{
            position: "absolute",
            top: "2.1rem",
            //  right: TEXT_DIRECTION() === "ltr" ? "0px" : "-210px",
            //  left: TEXT_DIRECTION() === "rtl" ? "210px" : "-210px",
            width: "239px",
            left: "2px",
            padding: "0px",
            borderRadius: "5px",
            background: "white",
            height: "404px",
            paddingTop: "1.4rem",
            fontSize: "14px",
            fontWeight: 500,
            color: "rgba(51, 51, 51, 1)",
            overflow: "auto",
            paddingLeft: "15px",
          }}
          className="shadow"
        >
          <div
            className="d-flex  align-items-center justify-content-start w-100 "
            style={{
              borderBottom: ".3px solid #E0E0E0",
              paddingBottom: "1rem",
              marginTop: "1px",
              marginBottom: "1rem",
              width: "100%",
              marginLeft: "-10px",
              paddingLeft:"12px"
            }}
          >
            {/* <hr style={{ marginTop: "0.15rem", ma.3ginBottom: "0.35rem" }} /> */}

            <input
              type="checkbox"
              checked={columnHeaders?.filter((item) => !item.isSelected)?.length > 0 ? false : true}
              onClick={() => {
                handleSelectAllColumns();
              }}
              style={{
                height: "18px",
                width: "18px",
                border: "none",
                background: "#F2F2F2",
              }}
              className="checkbox"
            />

            <div className={`${TEXT_DIRECTION() === "ltr" ? "ml-3" : "ml-3"} my-2 `}>All</div>
          </div>
          {/* <hr  /> */}

          {columnHeaders.slice(0,columnHeaders.length-1).map((item, index) => {
            return (
              <div key={index} className="d-flex  align-items-center justify-content-start p-0 my-1">
                <>
                  <div>
                    <input
                      type="checkbox"
                      checked={item?.isSelected}
                      onClick={(e: any) => {
                        handleSelectColumn(item?.id);
                      }}
                      style={{
                        height: "18px",
                        width: "18px",
                        border: "none",
                        background: "#F2F2F2",
                      }}
                      className="checkbox"
                    />
                  </div>

                  <div className={`${TEXT_DIRECTION() === "ltr" ? "ml-3" : "ml-3"} my-2`}>{item.label}</div>
                </>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default ColumnFilter;
