import React, { useState } from "react";
import CustomSingleSelect from "../../../Components/form-input/single-select";
import { RadioButton } from "../../../Components/form-input/RadioButton";
import moment from "moment";
import SuccessMessage from "../../companies/modal/SuccessMessage";
import ConfirmationModal from "../../companies/modal/ConfirmationModal";
import { refactorMarginTopForReports } from "../../../helper/helperFunction";

function SingleReportDetails({
  selectedOption,
  setSelectedOption,
  reportForEdit,
  restoreReportHandler,
  allUsers,
  selectedOptioin,
  handleSelectedItem,
  reportSetting,
  showRestoreModal,
  onCancel,
  handleOpenRestoreModal,
  setShowSuccessModal,
  showSuccessModal,
}: {
  selectedOption: any;
  setSelectedOption: any;
  reportForEdit: any;
  restoreReportHandler: any;
  allUsers: any;
  selectedOptioin: any;
  handleSelectedItem: any;
  reportSetting: any;
  showRestoreModal: any;
  onCancel: any;
  setShowSuccessModal: any;
  showSuccessModal: any;
  handleOpenRestoreModal: any;
}) {
  const [selectedTab, setselectedTab] = useState("Report Content");

  let refactoredSetting = [
    {
      label: [
        // { label: "Scores", type: "radio-round", isChecked: reportSetting?.content?.scores },
        // {
        //   label: "Doc Number",
        //   type: "radio-round",
        //   isChecked: reportSetting?.content?.docNumber,
        // },
        // {
        //   label: "Fails Count",
        //   type: "radio-round",
        //   isChecked: reportSetting?.content?.failsCount,
        // },
        {
          label: "Date & Time",
          type: "radio-round",
          isChecked: reportSetting?.content?.dateTime,
        },
        {
          label: "Report No.",
          type: "radio-round",
          isChecked: reportSetting?.content?.docNumber,
        },
      ],
    },
    {
      label: [
        {
          label: "Report Statistics",
          type: "radio-round",
          isChecked: reportSetting?.content?.reportStatistics,
        },
      ],
      subItems: [
        { label: "Score", type: "checkbox", isChecked: reportSetting?.content?.scores },
        {
          label: "Failed Items",
          type: "checkbox",
          isChecked: reportSetting?.content?.failsCount,
        },
        {
          label: "Total Answered",
          type: "checkbox",
          isChecked: reportSetting?.content?.total,
        },
      ],
    },
    {
      label: [
        { label: "Failed Items Summary", type: "radio-round", isChecked: reportSetting?.content?.failsSummary },
        {
          label: "Instructions",
          type: "radio-round",
          isChecked: reportSetting?.content?.instructions,
        },

        {
          label: "Inspection Questions",
          type: "radio-round",
          isChecked: reportSetting?.content?.inspectionQuestions,
        },
      ],
      subItems: [
        { label: "Answered", type: "radio", isChecked: reportSetting?.content?.answered },
        {
          label: "Un-Answered Questions",
          type: "radio",
          isChecked: reportSetting?.content?.unAnswered,
        },
        {
          label: "All",
          type: "radio",
          isChecked: reportSetting?.content?.all,
        },
      ],
    },
    {
      label: [{ label: "Photos", type: "radio-round", isChecked: reportSetting?.content?.photos }],
      subItems: [
        { label: "Standard Quality", type: "radio", isChecked: reportSetting?.content?.standardQuality },
        { label: "High Quality", type: "radio", isChecked: reportSetting?.content?.highQuality },
      ],
    },
    {
      label: [{ label: "QR Codes", type: "radio-round", isChecked: reportSetting?.content?.PdfLinksQrCode }],
    },
    {
      label: [{ label: "Table of Contents", type: "radio-round", isChecked: reportSetting?.content?.tableOfContents }],
    },
    {
      label: [{ label: "Company Information", type: "radio-round", isChecked: reportSetting?.content?.companyInformation }],
    },
    {
      label: [{ label: "Disclaimer", type: "radio-round", isChecked: reportSetting?.content?.disclaimer === "" ? false : true }],
    },
    {
      label: [{ label: "Footer", type: "radio-round", isChecked: reportSetting?.content?.footer }],
      subItems: [
        { label: "Page", type: "checkbox", isChecked: reportSetting?.content?.page },
        { label: "Site Name", type: "checkbox", isChecked: reportSetting?.content?.title },
        { label: "Date", type: "checkbox", isChecked: reportSetting?.content?.footerDate },
        { label: "Inspector Name", type: "checkbox", isChecked: reportSetting?.content?.inspectorName },
      ],
    },
  ];
  let refactoredTitleSetting = [
    {
      label: [{ label: "Site name", type: "radio-round", isChecked: reportSetting?.title?.siteName }],
    },
    {
      label: [{ label: "Inspectore name", type: "radio-round", isChecked: reportSetting?.title?.inspectorName }],
    },
    {
      label: [{ label: "Date", type: "radio-round", isChecked: reportSetting?.title?.dateTime }],
    },
  ];
  let refactoredDate = moment(reportForEdit?.deletedAt).format("DD-MM-YYYY");
  let deleteDate = moment(refactoredDate, "DD-MM-YYYY");
  var newDate = moment();
  let remainingDays = deleteDate.diff(newDate, "days");

  return (
    <div>
      <div className="main-overview-wrap">
        {reportForEdit?.isArchived || reportForEdit?.isDeleted ? (
          <div className="title-subscription">{reportForEdit?.reportName}</div>
        ) : (
          <div className="title-subscription">
            {reportForEdit?.formDetail?.title} - {moment(reportForEdit?.createdAt).format("DD MMM YYYY")}
          </div>
        )}

        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption("reports");
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">
        ID {reportForEdit?.isArchived || reportForEdit?.isDeleted ? reportForEdit?.reportId : reportForEdit?.buisnessId}
      </div>
      {reportForEdit?.isArchived && !reportForEdit?.isDeleted && <div className="normal-wrapper archived mt-2">Archived</div>}
      {reportForEdit?.isDeleted && (
        <div className="normal-wrapper deleted mt-2">
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: "relative" }}>
            <div>Deleted</div>
            <div className="normal-text my-3" style={{ color: "#4F4F4F" }}>
              It will be permanently deleted after <span style={{ fontWeight: "bold" }}>{remainingDays} days</span>
            </div>
            <button onClick={handleOpenRestoreModal}>Restore</button>
            <div style={{ position: "absolute", top: "6rem", right: 300 }}>
              {showRestoreModal ? (
                <>
                  <ConfirmationModal
                    title="Are you sure you want to Restore the report?"
                    btnText="Restore"
                    btnColor="#20639B"
                    onCancel={onCancel}
                    onConfirmation={restoreReportHandler}
                    actions
                    handleSucccessConfirmation={onCancel}
                  />
                </>
              ) : null}
              {showSuccessModal && <SuccessMessage message="Restore successfully " setshowSuccessCompletion={setShowSuccessModal} />}
            </div>
          </div>
        </div>
      )}

      {!reportForEdit?.isDeleted && !reportForEdit?.isArchived && (
        <div className="form-wrap mt-2">
          <div className="report-wrap">
            <div>Report settings</div>
            <div>
              <CustomSingleSelect
                users={true}
                options={allUsers}
                handleSelectedItem={handleSelectedItem}
                selectedOption={selectedOptioin}
                maxHeight="133px"
              />
            </div>
          </div>
          <div className="report-tabs mt-5">
            <div className={`pl-3 first ${selectedTab === "Report Title" ? "active" : "normal"}`} onClick={() => setselectedTab("Report Title")}>
              <span>Report Title</span>
            </div>
            <div
              className={`text-center second ${selectedTab === "Report Content" ? "active" : "normal"}`}
              onClick={() => setselectedTab("Report Content")}
            >
              <span>Report Content</span>
            </div>
          </div>
          {selectedTab === "Report Content" ? (
            <div className="mt-4 ml-3 report-single-detail">
              {refactoredSetting?.map((item, index) => {
                return (
                  <div key={index} style={{ marginTop: index === refactoredSetting?.length - 1 ? "-3px" : "10px" }}>
                    {item.label.map((label, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center"
                          style={{
                            marginTop: refactorMarginTopForReports(label),
                          }}
                        >
                          {label.type === "radio-round" && <RadioButton disabled isSelected={label.isChecked} roundRadio={true} />}
                          <div className="ml-2" style={{ marginBottom: "7px" }}>
                            {label.label}
                          </div>
                        </div>
                      );
                    })}
                    {item?.subItems?.map((subItem, subindex) => {
                      return (
                        <div className="ml-4" key={subindex} style={{ marginBottom: subItem.label === "High Quality" ? "1rem" : "" }}>
                          {subItem.type === "radio" && (
                            <RadioButton
                              id={subItem?.label}
                              simpleRadio={true}
                              isSelected={subItem?.isChecked}
                              label={subItem?.label}
                              // onChange={(e: any) => {
                              //   handleChangeRadio(subItem);
                              // }}
                              disabled
                            />
                          )}
                          {subItem?.type === "checkbox" && (
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="custom-user-checkbox my-2 ml-1"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  border: "none",
                                  background: "#F2F2F2",
                                }}
                                checked={subItem?.isChecked}
                                disabled
                                // onChange={() => {
                                //   handleChangeCheckbox(subItem);
                                // }}
                              />{" "}
                              <div className="ml-2" style={{ marginBottom: "0px" }}>
                                {subItem?.label}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-5 report-single-detail">
              {refactoredTitleSetting?.map((item: any, index: number) => {
                return (
                  <div key={index} style={{ marginTop: index === refactoredSetting?.length - 1 ? "15px" : "10px" }}>
                    {item.label.map((label: any, index: number) => {
                      return (
                        <div key={index} className="d-flex align-items-center">
                          {label.type === "radio-round" && <RadioButton disabled isSelected={label.isChecked} roundRadio={true} />}
                          <div className="ml-2" style={{ marginBottom: "7px" }}>
                            {label.label}
                          </div>
                        </div>
                      );
                    })}
                    {item?.subItems?.map((subItem: any, subindex: number) => {
                      return (
                        <div className="ml-4" key={subindex}>
                          {subItem.type === "radio" && (
                            <RadioButton id={subItem?.label} simpleRadio={true} isSelected={subItem?.isChecked} label={subItem?.label} disabled />
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SingleReportDetails;
