import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setselectedItem] = useState("");

  return <SidebarContext.Provider value={{ collapsed, setCollapsed,selectedItem,setselectedItem }}>{children}</SidebarContext.Provider>;
};
