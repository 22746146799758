import {  useState } from "react";
import Input from "../../Components/form-input/input-component";
import eyeIcon from "../../../src/assets/images/eyeIcon.svg";
import { AppDispatch } from "../../redux/store";
import { useDispatch, } from "react-redux";
import * as authActions from "../.././redux/auth/authAction";
// import { whoAmI } from "../../redux/auth/authCrud";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";


function Login() {
  const dispatch: AppDispatch|any = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);

  //for demo purpose
  const [registerInitialValues, setregisterInitialValues] = useState({
    email: "",
    password: "",
  });
  const {
    handleSubmit,
  } = useForm({ defaultValues: registerInitialValues });
  
  const handleSubmitForm = (data: any) => {
    // console.log(data, "data");
  };


  const loginHandler = () => {
    const loginData = {
      email: registerInitialValues.email,
      password:registerInitialValues.password
    }
    dispatch(authActions.login(loginData, navigate)).then(()=> {
      dispatch(authActions.whoAmI())
    });
  }
  
 
  
 
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-heading">Login to Tamam Control Panel</div>
        <div className="login-subheading">Enter your username and password</div>
        <form className="form-controls" onSubmit={handleSubmit(handleSubmitForm)}>
          <Input
            // register={register}
            type="text"
            name="email"
            placeholder="Email"
            value={registerInitialValues.email}
            onChange={(e: any) => setregisterInitialValues({ ...registerInitialValues, email: e.target.value })}
            // validations={{
            //   required: {
            //     value: true,
            //     // message: i18n("validation.REQUIRED_FIELD"),
            //     message: "Email is required",
            //   },
            //   minLength: { value: 3, message: MIN_LENGTH(3) },
            //   maxLength: { value: 50, message: MAX_LENGTH(50) },
            //   pattern: {
            //     value: EMAIL_REGEX,
            //     // message: i18n("validation.INVALID_EMAIL"),
            //     message: "Invalid Email",
            //   },
            // }}
            // error={errors["email"]}
          />
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            suffix={eyeIcon}
            name="password"
            suffixAction={() => {
              setshowPassword(!showPassword);
            }}
            //for demo purpose
            value={registerInitialValues.password}
            onChange={(e: any) => setregisterInitialValues({ ...registerInitialValues, password: e.target.value })}

            // register={register}
            // validations={{
            //   required: {
            //     value: true,
            //     message: "Password is required",
            //   },
            //   minLength: { value: 3, message: MIN_LENGTH(3) },
            //   maxLength: { value: 3, message: MAX_LENGTH(20) },
            //   patterns: {
            //     value: PASSWORD_REGEX,
            //     message: "Invalid Password",
            //   },
            // }}
            // error={errors["password"]}
          />
          <div className="btn-wrapper">
            <Link to="/forgot-password" style={{ textDecoration: "none" }}>
              {" "}
              <p>Forgot Password?</p>
            </Link>

            <button
              disabled={registerInitialValues.email === "" || registerInitialValues.password === ""}
              type="submit"
              onClick={loginHandler}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
