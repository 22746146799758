import * as requestFromServer from "./deletedCompanyCrud";
import { deletedCompanySlice, callTypes } from "./deletedCompanySlice";
import { toast } from "react-hot-toast";
import { AppDispatch } from "../store";
import { DeleteCompanySearchDTO } from "../../interfaces/companiesInterface";

const { actions } = deletedCompanySlice;
export const getAllDeletedCompanies = (searchState?: DeleteCompanySearchDTO) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDeletedCompanies(searchState)
    .then((res: any) => {
      dispatch(
        actions.getAllDeletedCompanies({
          deletedCompanies: res.data.results,
          page: res.data.page,
          total: res?.data?.totalCount,
          size:res?.data?.size
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const restoreDeletedCompanies = (ids: string[]) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .restoreDeletedCompanies(ids)
    .then((res: any) => {
      toast.success("Company was restored ccessfully", )
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
    });
};

export const getAllDeletedCountries = (searchString?: string, countrySearch?: any) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .getAllDeletedCountries(searchString)
    .then((res: any) => {
      let data = res?.data;
      let countries = data.map((item: any) => item.label)
      if (countrySearch) {
        countrySearch(countries);
      }
      dispatch(
        actions.getAllDeletedCountries({
          countries: res.data,
        
        })
      );
    })
    .catch((error:any) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.response?.data?.message);
    });
};
