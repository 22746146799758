import CustomPagination from "../../companies/CustomPagination";
import SingleDummyChart from "../charts/SingleChart";
import searchIcon from "../../../assets/images/siteSearchIcon.svg";
import sortArrow from "../../../assets/images/sortArrow.svg";
import Skeleton from "react-loading-skeleton";

function FormsDetails({
  setSelectedOption,
  formsHeadData,
  selectedFormHeader,
  handleArrowSortClick,
  handleSort,
  handleFormSearch,
  setFormSearchText,
  page,
  size,
  setsize,
  total,
  setpage,
  allForms,
  formChartsData,
  chartsLoading,
}: {
  setSelectedOption: any;
  formsHeadData: any;
  selectedFormHeader: any;
  handleArrowSortClick: any;
  handleSort: any;
  handleFormSearch: any;
  setFormSearchText: any;
  page: number;
  size: number;
  total: number;
  setpage: any;
  setsize: any;
  allForms: any;
  formChartsData: any;
  chartsLoading: any;
}) {
  // const analyticsXaxisData = ["10March", "10March", "10March", "10March", "10March", "10March", "10March", "10March"];

  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Forms</div>
        <button className="back-btn" onClick={() => window.history.back()}>
          Back
        </button>
      </div>
      <div className="mt-4">
        {formChartsData && formChartsData?.formsChartsToShow && !chartsLoading ? (
          <SingleDummyChart
            title={`Total ${formChartsData?.formsChartsToShow[0]?.title}`}
            analyticsXaxisData={formChartsData?.xAxisData}
            className=" shadow-lg mt-3 w-100 "
            totalResult={formChartsData?.formsChartsToShow[0]?.value}
            color="#56CCF2"
            dateToShow={true}
            selectedItem={formChartsData?.formsChartsToShow[0]}
          />
        ) : (
          <div className="bg-white circle p-2 w-100">
            <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
              <Skeleton width={100} />
              <Skeleton width={100} />
            </div>
            <Skeleton count={7} />
          </div>
        )}
      </div>
      <div className="payment-card-wrap mt-3 w-100">
        <div className="sites-search-wrap ml-auto" style={{width:"139px"}}>
          <img src={searchIcon} alt="searchIcon" />
          <input
            type="text"
            placeholder="Search in forms"
            className="sites-searchBar"
            onChange={(e: any) => {
              handleFormSearch(e.target.value);
              setFormSearchText(e.target.value);
            }}
          />
        </div>
        <div className="mt-4 table-responsive">
          <table className="table-main table">
            <thead>
              <tr className="table-header" style={{ color: "#4F4F4F", cursor: "pointer" }}>
                {formsHeadData?.map((item: any, index: number) => {
                  return (
                    <th key={index}>
                      {" "}
                      <span
                        onClick={() => {
                          handleSort(item);
                        }}
                      >
                        {" "}
                        {item.label}
                      </span>
                      {selectedFormHeader.label === item.label ? (
                        <img
                          className="ml-2"
                          id="forms-sort-icon"
                          src={sortArrow}
                          alt="sort-arrow"
                          onClick={handleArrowSortClick}
                          style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99", color: "inherit" }}
                        />
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {allForms?.map((item: any, index: number) => {
                return (
                  // <tr key={index} className={index === 0 ? "normal-form-color" : index === 2 ? "orange-form-color" : "red-form-color"}>
                  <tr
                    key={index}
                    className={
                      item.isDeleted ? "red-form-color table-row" : item.isArchived ? "orange-form-color table-row" : "normal-form-color table-row"
                    }
                    onClick={() => {
                      setSelectedOption(`forms_single_${item._id}`);
                    }}
                  >
                    <td>{item.formBuisnessId}</td>
                    <td>{item.formName}</td>
                    <td>{item.formsUsage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CustomPagination page={page} setSize={setsize} setpage={setpage} size={size} total={total} />
        </div>
      </div>
    </div>
  );
}

export default FormsDetails;
