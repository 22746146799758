import React, { useEffect, useRef, useState } from "react";
import Table from "./table/Table";
import { GenaricListDTO } from "./DTO/formDTO";
import closeIcon from "../../assets/images/CloseIcon.svg";
import SearchImage from "../../assets/images/Search.svg";
// import actionsIcon from "../../assets/images/actionsCircle.svg";
import FilterForm from "./filterForm/FilterForm";
import ColumnFilter from "./columnFilter/ColumnFilter";
import { useDispatch, useSelector } from "react-redux";
import * as deletedCompanyAction from "../../redux/deletedCompanies/deletedCompanyAction";
import { AppDispatch } from "../../redux/store";
import { refactorCompanies, sortTableColumnsForDeletedCompanies } from "../../helper/helperFunction";
import { StoreState } from "../../redux/storeState/storeStateInterface";
import * as companyActions from "../.././redux/companies/companiesAction";
import * as authActions from "../.././redux/auth/authAction";
import { useSidebar } from "../useSidebarProvider";
import CompaniesHeader from "./CompaniesHeader";

function DeletedCompanies() {
  const [columnHeaders, setColumnHeaders] = useState<GenaricListDTO[]>([
    {
      id: "1",
      label: "Business ID",
      value: "buisnessIdDeleted",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "2",
      label: "Business name",
      value: "businessNameDeleted",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "3",
      label: "Owner name",
      value: "ownerNameDeleted",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "4",
      label: "Company email",
      value: "companyEmailDeleted",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "5",
      label: "Country",
      value: "countryDeleted",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "6",
      label: "Days",
      value: "daysDeleted",
      isSelected: true,
      sortBy: "desc",
    },

    {
      id: "7",
      label: "Actions",
      value: "Actions",
      isSelected: true,
    },
  ]);
  const [deletedCompanyLoading, setdeletedCompanyLoading] = useState(true);
  const [isDeletedCompany, setisDeletedCompany] = useState(true);
  const [daysList, setDaysList] = useState([
    {
      id: "1",
      isSelected: true,
      label: "0-30",
      value: "tillThirty",
    },
    {
      id: "2",
      isSelected: true,
      label: "31-60",
      value: "tillSixty",
    },
    {
      id: "3",
      isSelected: true,
      label: "61-90",
      value: "tillNinty",
    },
  ]);
  const [companiesList, setcompaniesList] = useState<GenaricListDTO[]>([]);
  const [countryList, setCountryList] = useState<GenaricListDTO[]>([]);

  const [filterDropdownOpen, setfilterDropdownOpen] = useState(false);
  const [columnDropdownOpen, setColumnDropdownOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(0);
  const [isfilterBtnColorApplied, setIsfilterBtnColorApplied] = useState(false);
  const [isColumnFilterBtnColorApplied, setIsColumnFilterBtnColorApplied] = useState(false);
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  const [feildToSort, setfeildToSort] = useState("buisnessId");
  const [sortBy, setSortBy] = useState({ label: "buisnessIdDeleted", value: "asc" });
  const [selectedHeader, setselectedHeader] = useState<GenaricListDTO>(columnHeaders[0]);
  const filterRef = useRef<any>();
  const columnRef = useRef<any>();
  const searchRef = useRef<any>();

  let deltedCompany = true;
  console.log(sortBy);
  
  const { deletedCompaniesData, deletedCountries, deletedCompanyPage, totalResults, user,deletedCompanySize } = useSelector((state: StoreState | any) => ({
    deletedCompaniesData: state?.deletedCompany?.deletedCompaniesData,
    deletedCountries: state?.deletedCompany?.deletedCountries,
    deletedCompanyPage: state?.deletedCompany?.deletedCompanyPage,
    totalResults: state?.deletedCompany?.totalResults,
    user: state?.auth?.user,
    deletedCompanySize:state?.deletedCompany?.size,
  }));

  const handleOutsideClick = (event: any) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setfilterDropdownOpen(false);
    }
  };
  const handleOutsideClickColumn = (event: any) => {
    if (columnRef.current && !columnRef.current.contains(event.target)) {
      setColumnDropdownOpen(false);
    }
  };
  const handleOutsideClickSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      if (!closeSearchBarIfValue) {
        // console.log("i ran for open should");
        setSearchBarOpen(true);
      } else {
        // console.log("i ran for close should");
        setSearchBarOpen(false);
      }
    }
  };

  useEffect(() => {
    dispatch(authActions.whoAmI());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      let tableHeaderObject = columnHeaders.map((item, index) => {
        return {
          ...item,
          isSelected:
            user && user?.companySettings && user?.companySettings?.deletedCompaniesTable?.length > 0
              ? user?.companySettings?.deletedCompaniesTable?.includes(item?.label)
                ? true
                : false
              : true,
        };
      });
      setColumnHeaders([...tableHeaderObject]);
    }
    // eslint-disable-next-line
  }, [user]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickColumn);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickColumn);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSearch);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSearch);
    };
    // eslint-disable-next-line
  }, [closeSearchBarIfValue]);

  const handleSelectColumn = (id: string) => {
    let refactoredArr = columnHeaders.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    const filterSelectedHeader = refactoredArr.filter((item) => item.isSelected).map((subItem) => subItem.label);
    companyTableHandler(filterSelectedHeader?.length > 0 ? filterSelectedHeader : [""]);
    setColumnHeaders([...refactoredArr]);
  };
  const handleSelectAllColumns = () => {
    let refactoredArr;
    if (columnHeaders?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = columnHeaders.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = columnHeaders.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    const filterSelectedHeader = refactoredArr.filter((item) => item.isSelected).map((subItem) => subItem.label);
    companyTableHandler(filterSelectedHeader?.length > 0 ? filterSelectedHeader : [""]);
    setColumnHeaders([...refactoredArr]);
  };
  const handleSelectTableCheckbox = (id: string) => {
    let refactoredArr = companiesList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setcompaniesList([...refactoredArr]);
  };

  const handleSelectAllTableCheckbox = () => {
    let refactoredArr;
    if (companiesList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = companiesList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = companiesList.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    setcompaniesList([...refactoredArr]);
  };

  const handleSelectCountry = (id: string) => {
    let refactoredArr = countryList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setCountryList([...refactoredArr]);
  };
  const handleSelectDays = (id: string) => {
    let refactoredArr = daysList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setDaysList([...refactoredArr]);
  };

  const handleSelectAllCountry = () => {
    let refactoredArr;
    if (countryList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = countryList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = countryList.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    setCountryList([...refactoredArr]);
  };
  const handleSelectAllDays = () => {
    let refactoredArr;
    if (daysList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = daysList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = daysList.map((item) => {
        return { ...item, isSelected: false };
      });
    }
    setDaysList([...refactoredArr]);
  };

  const countrySearch = (countries: any) => {
    let days: any = daysList.filter((item) => !item.isSelected)?.length !== 0 && daysList.filter((item) => item.isSelected).map((item) => item.label);
    dispatch(
      deletedCompanyAction.getAllDeletedCompanies({
        page: page,
        size: size,
        countries: countries,
        days: days,
        searchName: searchValue,
      })
    ).then(() => {
      setdeletedCompanyLoading(true);
    });
  };
  const handleCountrySearch = (() => {
    // return () => {
    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    // }

    return (searchString: any) => {
      setTimeout(() => {
        dispatch(deletedCompanyAction.getAllDeletedCountries(searchString, countrySearch)).then((res: any) => {
          setdeletedCompanyLoading(true);
        });
      }, 200);
    };
    // }
  })();
  const dispatch: AppDispatch | any = useDispatch();

  useEffect(() => {
    const selectedFilters = getSelectedFilters();
    if (page > 0 && size>0) {
      dispatch(
        deletedCompanyAction.getAllDeletedCompanies({
          page: page,
          size: size,
          days: selectedFilters.days,
          countries: selectedFilters.countries,
          sortAt: feildToSort,
          sortBy: selectedHeader?.sortBy,
        })
      ).then(() => {
        // setdeletedCompanyLoading(true);
        dispatch(deletedCompanyAction.getAllDeletedCountries()).then(() => {
          setdeletedCompanyLoading(true);
        });
      });
    } else {
      dispatch(
        deletedCompanyAction.getAllDeletedCompanies({
          page: 1,
          size: 10,
          days: selectedFilters.days,
          countries: selectedFilters.countries,
          sortAt: feildToSort,
          sortBy: selectedHeader?.sortBy,
        })
      ).then(() => {
        // setdeletedCompanyLoading(true);
        dispatch(deletedCompanyAction.getAllDeletedCountries()).then(() => {
          setdeletedCompanyLoading(true);
        });
      });
    }

    // eslint-disable-next-line
  }, [page, size]);

  useEffect(() => {
    if (totalResults > 0 && total === 0) {
      setPage(deletedCompanyPage);
      setTotal(totalResults);
      setSize(deletedCompanySize);
    }
    // setSize(size);

    // eslint-disable-next-line
  }, [totalResults]);

  useEffect(() => {
    refactorCompanies(deletedCompaniesData, deletedCompanyLoading, setcompaniesList, setdeletedCompanyLoading);
    // eslint-disable-next-line
  }, [deletedCompaniesData, deletedCompanyLoading]);

  useEffect(() => {
    const uniqueLabels: any = [];
    const unique = deletedCountries?.filter((element: any) => {
      const isDuplicate = uniqueLabels.includes(element.label);

      if (!isDuplicate) {
        uniqueLabels.push(element.label);
        return true;
      }
      return false;
    });
    setCountryList(unique);

    // eslint-disable-next-line
  }, [deletedCountries]);

  //filters
  useEffect(() => {
    if (countryList && countryList.length > 0) {
      let countries = countryList?.filter((item) => item.isSelected).map((item) => item.label);
      // let days: string[] = [];
      // if (daysList.filter((item) => !item.isSelected).length !== 0) {
      //   days = daysList.filter((item) => item.isSelected).map((item) => item.label);
      // }
      let days: any =
          daysList.filter((item) => !item.isSelected)?.length !== 0 && daysList.filter((item) => item.isSelected).map((item) => item.label);
      if (page > 0 && size>0) {
        dispatch(
          deletedCompanyAction.getAllDeletedCompanies({
            page: page,
            size: size,
            countries: countries,
            days: days,
            searchName: searchValue,
            sortAt: feildToSort,
            sortBy: selectedHeader.sortBy,
          })
        ).then(() => {
          setdeletedCompanyLoading(true);
        });
      }
    }
    // eslint-disable-next-line
  }, [countryList, daysList]);

  //company search
  const handleSearchCompany = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let days: any =
          daysList.filter((item) => !item.isSelected)?.length !== 0 && daysList.filter((item) => item.isSelected).map((item) => item.label);
        let countries: any =
          countryList.filter((item) => !item.isSelected)?.length !== 0 && companiesList.filter((item) => item.isSelected).map((item) => item.label);
        dispatch(
          deletedCompanyAction.getAllDeletedCompanies({
            page: page,
            size: size,
            searchName: searchString,
            countries: countries,
            days: days,
            sortAt: feildToSort,
            sortBy: selectedHeader?.sortBy,
          })
        ).then(() => {
          setdeletedCompanyLoading(true);
        });
        if (searchString !== "") {
          setCloseSearchBarIfValue(false);
        } else {
          setCloseSearchBarIfValue(true);
        }
      }, 200);
    };
  })();
  //reset handler
  const resetSearchingHandler = () => {
    if (countryList && countryList.length > 0 && daysList && daysList.length > 0) {
      let countries = countryList?.filter((item) => item.isSelected).map((item) => item.label);
      let days: string[] = [];
      if (daysList.filter((item) => !item.isSelected).length !== 0) {
        days = daysList.filter((item) => item.isSelected).map((item) => item.label);
      }
      dispatch(
        deletedCompanyAction.getAllDeletedCompanies({
          page: page,
          size: size,
          countries: countries,
          days: days,
          sortAt: feildToSort,
          sortBy: selectedHeader.sortBy,
        })
      ).then(() => {
        setdeletedCompanyLoading(true);
      });
    }
  };

  const getSelectedFilters = () => {
    let days: any = daysList.filter((item) => !item.isSelected)?.length !== 0 && daysList.filter((item) => item.isSelected).map((item) => item.label);
    let countries: any =
      countryList.filter((item) => !item.isSelected)?.length !== 0 && companiesList.filter((item) => item.isSelected).map((item) => item.label);
    return { days, countries };
  };

  //filter color
  useEffect(() => {
    if (countryList?.length > 0 && countryList?.filter((item) => !item.isSelected)?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else if (daysList?.length > 0 && daysList?.filter((item) => !item.isSelected)?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else {
      setIsfilterBtnColorApplied(false);
    }
  }, [countryList, daysList]);
  //column color
  useEffect(() => {
    let selectedColumns = columnHeaders.filter((item) => !item.isSelected)?.length > 0;
    if (selectedColumns) {
      setIsColumnFilterBtnColorApplied(true);
    } else {
      setIsColumnFilterBtnColorApplied(false);
    }
  }, [columnHeaders]);

  //sorting
  useEffect(() => {
    if (page > 0 && size > 0) {
      const selectedFilters = getSelectedFilters();

      dispatch(deletedCompanyAction.getAllDeletedCompanies({
        page: page,
        size: size,
        sortAt: feildToSort,
        sortBy: selectedHeader.sortBy,
        days: selectedFilters.days,
        countries: selectedFilters.countries,

      })).then(() =>
        setdeletedCompanyLoading(true)
      );
    }

    // if (feildToSort === "buisnessId") {
    let sortIcon = document.getElementById("sort-icon");
    sortIcon?.classList.toggle("toggle-icon");
    // }
    // eslint-disable-next-line
  }, [selectedHeader.sortBy, feildToSort]);

  // useEffect(() => {
  //   dispatch(
  //     deletedCompanyAction.getAllDeletedCompanies({
  //       page: page,
  //       size: size,
  //       searchName: searchValue,
  //       sortAt: feildToSort,
  //       sortBy: selectedHeader?.sortBy,
  //     })
  //   ).then(() => {
  //     setdeletedCompanyLoading(true);
  //   });
  //   // eslint-disable-next-line
  // }, [isDeletedCompany]);

  const companyTableHandler = (dataToUpdate: string[]) => {
    dispatch(companyActions.companyUserToUpdate(dataToUpdate, true));
  };
  const handleReset = () => {
    dispatch(
      deletedCompanyAction.getAllDeletedCompanies({
        page: page,
        size: size,
        searchName: searchValue,
        sortAt: feildToSort,
        sortBy: selectedHeader?.sortBy,
      })
    ).then(() => {
      dispatch(deletedCompanyAction.getAllDeletedCountries());
      setdeletedCompanyLoading(true);
      let resetCountryList = countryList?.map((item) => {
        return { ...item, isSelected: true };
      });
      setCountryList(resetCountryList);
      let resetDaysList = daysList?.map((item) => {
        return { ...item, isSelected: true };
      });
      setDaysList(resetDaysList);
    });
  };
  const handleSort = (item: any) => {
    sortTableColumnsForDeletedCompanies(item, setfeildToSort);
    setselectedHeader(item);
  };

  const handleArrowSortClick = () => {
    if (selectedHeader) {
      setfeildToSort(selectedHeader.value!);
    }
    if (selectedHeader.sortBy === "asc") {
      selectedHeader.sortBy = "desc";
    } else {
      selectedHeader.sortBy = "asc";
    }
  };
  const { collapsed, setselectedItem } = useSidebar();
  setselectedItem("")
  return (
    <>
      <div className="pb-2" style={{ overflowX: "hidden", background: "rgb(242, 243, 248)" }}>
        <CompaniesHeader isDeleteCompanies={isDeletedCompany} setisDeleteCompanies={setisDeletedCompany} />
        <div className="companies-main-wrap" style={{ marginLeft: collapsed ? "1rem" : "17rem" }}>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: "25px" }}>
            <div className="filters">
              <div
                className={`${isfilterBtnColorApplied ? "active filter-wrap" : "normal filter-wrap"}`}
                ref={filterRef}
                style={{ position: "relative" }}
              >
                <span
                  onClick={() => {
                    setfilterDropdownOpen(!filterDropdownOpen);
                  }}
                >
                  Filter
                </span>
                {filterDropdownOpen ? (
                  <FilterForm
                    countryList={countryList}
                    setCountryList={setCountryList}
                    daysList={daysList}
                    handleSelectAllCountry={handleSelectAllCountry}
                    handleSelectCountry={handleSelectCountry}
                    handleReset={handleReset}
                    handleCountrySearch={handleCountrySearch}
                    handleSelectAllDays={handleSelectAllDays}
                    handleSelectDays={handleSelectDays}
                  />
                ) : null}
              </div>
              <div
                className={`${isColumnFilterBtnColorApplied ? "active filter-div" : "normal filter-div"}`}
                ref={columnRef}
                style={{ position: "relative" }}
              >
                <span
                  onClick={() => {
                    setColumnDropdownOpen(!columnDropdownOpen);
                  }}
                >
                  Columns
                </span>
                {columnDropdownOpen ? (
                  <ColumnFilter
                    columnHeaders={columnHeaders}
                    setColumnHeaders={setColumnHeaders}
                    isHeaderSelected={columnDropdownOpen}
                    handleSelectAllColumns={handleSelectAllColumns}
                    handleSelectColumn={handleSelectColumn}
                  />
                ) : null}
              </div>
            </div>
            <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
              <div style={{ width: "100%", height: "34px" }}>
                {searchBarOpen ? (
                  <input
                    value={searchValue}
                    onChange={(e: any) => {
                      setsearchValue(e.target.value);
                      handleSearchCompany(e.target.value);
                    }}
                    type="text"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      outline: "none",
                      background: "#F2F2F2",
                      borderRadius: "6px",
                      padding: "1rem",
                      color: searchValue.length ? "#4F4F4F" : "#BDBDBD",
                      fontWeight: !searchValue.length ? 500 : 700,
                    }}
                    className="searchBar"
                    placeholder="Search"
                  />
                ) : null}

                <div style={{ position: "absolute", top: 3, right: 20 }}>
                  {searchValue.length && searchBarOpen ? (
                    <img
                      src={closeIcon}
                      alt="search icon"
                      onClick={() => {
                        setsearchValue("");
                        setCloseSearchBarIfValue(true);
                        resetSearchingHandler();
                      }}
                    />
                  ) : (
                    <img
                      src={SearchImage}
                      alt="search icon"
                      onClick={() => {
                        setSearchBarOpen(!searchBarOpen);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table
              tableHeaderState={columnHeaders}
              companiesList={companiesList}
              handleSelectTableCheckbox={handleSelectTableCheckbox}
              handleSelectAllTableCheckbox={handleSelectAllTableCheckbox}
              deltedCompany={deltedCompany}
              total={total}
              page={page}
              size={size}
              setPage={setPage}
              setSize={setSize}
              handleSort={handleSort}
              daysList={daysList}
              deleteCompanyLoading={deletedCompanyLoading}
              setDeleteCompanyLoading={setdeletedCompanyLoading}
              setSortField={setfeildToSort}
              setSortBy={setSortBy}
              handleArrowSortClick={handleArrowSortClick}
              searchValue={searchValue}
              getSelectedFilters={getSelectedFilters}
              selectedHeader={selectedHeader}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DeletedCompanies;
