import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Input from "../../Components/form-input/input-component";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import * as authActions from "../../redux/auth/authAction";
import { useState } from "react";
import { EMAIL_REGEX } from "../../config/validations";

interface InititalStateDTO {
  email: string;
}
const registerInitialValues = {
  email: "",
};
function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: registerInitialValues });

  const dispatch: AppDispatch | any = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const handleResetPassword = (data: InititalStateDTO) => {
    dispatch(authActions.forgotPassword(data));
  };
  // console.log(errors, "errors");
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-heading">Password Reset</div>
        <div className="reset-subheading">Please enter your email to receive a password reset message</div>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <div className="form-controls">
            <Input
              name="email"
              register={register}
              type="email"
              placeholder="Email"
              className="w-100 reset-email"
              validations={{
                required: { value: true, message: "Email is required" },
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid Email",
                },
              }}
              error={errors["email"]}
            />
            <div className="robot-wrapper">
              <input checked={isChecked} onChange={() => setIsChecked(!isChecked)} className="checkbox" type="checkbox" />
              <div>I'm not a robot</div>
            </div>
            <div className="btn-reset-wrapper">
              <Link to="/">
                <button className="back-btn">Back</button>
              </Link>
              <button type="submit" disabled={!isChecked || errors["email"] ? true : false}>
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
