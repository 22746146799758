import React, { useEffect, useState } from "react";
import checkIcon from "../../../assets/images/blueCheckIcon.svg";
import dropdownIcon from "../../../assets/images/bigDropdown.svg";
import CustomSingleSelect from "../../../Components/form-input/single-select";
import moment from "moment";
import ConfirmationModal from "../../companies/modal/ConfirmationModal";
import SuccessMessage from "../../companies/modal/SuccessMessage";
function SingleFormDetails({
  setSelectedOption,
  selectedOption,
  formData,
  singleFormData,
  handleSelectedItem,
  selectedOptioin,
  selectedId,
  restoreFormHandler,
  showRestoreModal,
  onCancel,
  handleOpenModal,
  setShowSuccessModal,
  showSuccessModal,
}: {
  setSelectedOption: any;
  selectedOption: any;
  formData: any;
  singleFormData: any;
  handleSelectedItem: any;
  selectedOptioin: any;
  selectedId: any;
  restoreFormHandler: any;
  showRestoreModal: any;
  onCancel: any;
  handleOpenModal: any;
  setShowSuccessModal: any;
  showSuccessModal: any;
}) {
  const [filtered, setfiltered] = useState(formData);
  useEffect(() => {
    let finded = formData.filter((item: any) => item._id === selectedId);
    setfiltered(finded);
    // eslint-disable-next-line
  }, [selectedId, formData]);
  // console.log(formData, "formData");
  // console.log(filtered, "filtered");
  let refactoredDate = moment(singleFormData?.deletedAt).format("DD-MM-YYYY");
  let deleteDate = moment(refactoredDate, "DD-MM-YYYY");
  var newDate = moment();
  let remainingDays = deleteDate.diff(newDate, "days");
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">
          {formData.length > 0
            ? formData[0]?.formName
            : singleFormData?.formName?.length > 0
            ? singleFormData?.formName
            : singleFormData?.formDetail?.title}
        </div>
        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption("forms");
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">
        ID {formData.length > 0 ? formData[0]?.formId : singleFormData?.formId?.length > 0 ? singleFormData?.formId : singleFormData?.buisnessId}
      </div>
      {singleFormData?.isArchived && !singleFormData?.isDeleted && <div className="normal-wrapper archived mt-2">Archived</div>}
      {singleFormData?.isDeleted && (
        <div className="normal-wrapper deleted mt-2">
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: "absolute" }}>
            <div>Deleted</div>
            <div className="normal-text my-3" style={{ color: "#4F4F4F" }}>
              It will be permanently deleted after <span style={{ fontWeight: "bold" }}>{remainingDays} days</span>
            </div>
            <button onClick={handleOpenModal}>Restore</button>
            <div style={{ position: "absolute", top: "6rem", right: 300 }}>
              {showRestoreModal ? (
                <>
                  <ConfirmationModal
                    title="Are you sure you want to Restore the form?"
                    btnText="Restore"
                    btnColor="#20639B"
                    onCancel={onCancel}
                    onConfirmation={restoreFormHandler}
                    actions
                    handleSucccessConfirmation={onCancel}
                  />
                </>
              ) : null}
              {showSuccessModal && <SuccessMessage message="Restore successfully" setshowSuccessCompletion={setShowSuccessModal} />}
            </div>
          </div>
        </div>
      )}
      {!singleFormData?.isArchived && !singleFormData?.isDeleted && (
        <div className="form-wrap mt-2">
          <div className="rule-wrap">
            <div>{selectedOptioin}</div>
            <div>
              <CustomSingleSelect options={formData} handleSelectedItem={handleSelectedItem} selectedOption={selectedOptioin} />
            </div>
          </div>
          <div className="my-2">{filtered[0]?.createdBy}</div>
          {/* <div className="muted-text my-2">Last Update 22 Mar 2022 18:24 By Abdulla Ali</div> */}
          <div className="muted-text my-2">
            Last Update {moment(filtered[0]?.updatedAt).format("DD MMM YYYY hh:mm")} By {filtered[0]?.createdBy}
          </div>
          <div className="inspectors">
            <div className="heading mb-2">Inspectors with access to this form</div>
            <div>
              {filtered[0]?.assignees?.map((item: any, index: number) => {
                return (
                  <div key={index} className="">
                    <div className="ml-2">{item.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center my-2">
            <img src={dropdownIcon} alt="dropdown" />
          </div>
          <div className="managers">
            <div className="heading">Managers with access to this form</div>
            <div>
              {filtered[0]?.managers?.map((item: any, index: number) => {
                let finded = filtered[0]?.approvedManagers?.find((sub_item: any) => item?.name === sub_item.name);
                return (
                  <div key={index}>
                    <ul className="my-2">
                      <li className="d-flex align-items-center">
                        {finded ? <img src={checkIcon} alt="checkIcon" /> : null}
                        <div className={`${!finded ? "ml-4 pl-2" : "ml-2"}`}>{item.name}</div>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleFormDetails;
