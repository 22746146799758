import { GenaricListDTO } from "../DTO/formDTO";
import actionsIcon from "../../../assets/images/actionsCircle.svg";
import CustomPagination from "../CustomPagination";
import CompanyActionModal from "../../../Components/modal/CompanyActionModal";
import { useEffect, useRef, useState } from "react";
import SuccessMessage from "../modal/SuccessMessage";
import sortArrow from "../../../assets/images/sortArrow.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import * as companyActions from "../../../redux/companies/companiesAction";
import { useNavigate } from "react-router-dom";
import * as deleteCompanyAction from "../../../redux/deletedCompanies/deletedCompanyAction";
import { refactorSuspendStatus } from "../../../helper/helperFunction";

interface TableIProps {
  tableHeaderState: GenaricListDTO[];
  companiesList: any;
  handleSelectTableCheckbox: any;
  handleSelectAllTableCheckbox: any;
  size?: number;
  total?: number;
  setSize?: any;
  setPage?: any;
  page?: number;
  setTotal?: any;
  deltedCompany?: boolean;
  handleSort?: any;
  setisCompanySuspended?: any;
  companySuspend?: any;
  setCompanyLoading?: any;
  countryList?: any;
  planList?: any;
  seatsList?: any;
  statusList?: any;
  daysList?: any;
  deleteCompanyLoading?: boolean;
  setDeleteCompanyLoading?: any;
  sortField?: string;
  sortBy?: any;
  setSortField?: any;
  setSortBy?: any;
  handleArrowSortClick?: any;
  searchValue: string;
  getSelectedFilters: () => any;
  selectedHeader?: any;
}
function Table({
  tableHeaderState,
  companiesList,
  handleSelectTableCheckbox,
  handleSelectAllTableCheckbox,
  setPage,
  setSize,
  page,
  size,
  total,
  setTotal,
  deltedCompany,
  handleSort,
  companySuspend,
  setCompanyLoading,
  countryList,
  seatsList,
  statusList,
  planList,
  daysList,
  deleteCompanyLoading,
  setDeleteCompanyLoading,
  sortField,
  sortBy,
  setSortBy,
  setSortField,
  handleArrowSortClick,
  searchValue,
  getSelectedFilters,
  selectedHeader,
}: TableIProps) {
  const [groupActionOpen, setgroupActionOpen] = useState({ value: false, id: null });
  const [suspendModaOpen, setSuspendModaOpen] = useState({ value: false, id: 0 });
  const [emailModalOpen, setEmailModalOpen] = useState({ value: false, id: 0 });
  const [deleteModaOpen, setdeleteModaOpen] = useState({ value: false, id: 0 });
  const [restoreModalOpen, setRestoreModalOpen] = useState({ value: false, id: 0 });
  const [showSuccessEmail, setShowSuccessEmail] = useState({ value: false, id: 0 });
  const [showSuccessSuspend, setShowSuccessSuspend] = useState({ value: false, id: 0 });
  const [showSuccessDeletion, setshowSuccessDeletion] = useState({ value: false, id: 0 });
  const [showSuccessRestoration, setShowSuccessRestoration] = useState({ value: false, id: 0 });
  const [showSuccessResume, setShowSuccessResume] = useState({ value: false, id: 0 });
  const [reason, setReason] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState<string>("");
  const [selectedId, setselectedId] = useState(0);
  const openGroupActionModalRef = useRef<any>();
  const [showResumeOption, setshowResumeOption] = useState({ value: false, id: 0 });
  const [isCompanySuspended, setisCompanySuspended] = useState({ value: false, id: 0 });
  const [deleteTextColor, setDeleteTextColor] = useState({ value: true, color: "#EB5757", id: 0 });
  const navigate = useNavigate();
  const handleModalOpen = (id: number) => {
    let finded = companiesList.length > 0 && companiesList.find((item: any) => item.id === id);
    if (finded.isSuspended) {
      setgroupActionOpen({ ...groupActionOpen, id: finded.id, value: true });
      setselectedId(finded.id);
      setisCompanySuspended({ value: true, id: finded.id });
    } else {
      setgroupActionOpen({ ...groupActionOpen, id: finded.id, value: true });
      setselectedId(finded.id);
      setisCompanySuspended({ value: false, id: 0 });
    }
    if (
      finded?.subscription?.currentPlan?.status === "Free" ||
      finded?.subscription?.currentPlan?.status === "Paused" ||
      finded?.subscription?.currentPlan?.status === "Expired"
    ) {
      if (deleteTextColor.id === selectedId) {
        setDeleteTextColor({ ...deleteTextColor, color: "#EB5757" });
      } else {
        setDeleteTextColor({ ...deleteTextColor, color: "#828282" });
      }
    } else {
      setDeleteTextColor({ ...deleteTextColor, color: "#828282" });
    }
    // console.log(finded, "item selected and status checiig");
  };
  // console.log(selectedId, "selected id");
  // console.log(deleteTextColor.id, "selected delete id");
  useEffect(() => {
    if (selectedId > 0) {
      setDeleteTextColor({ ...deleteTextColor, id: selectedId });
    }
    // eslint-disable-next-line
  }, [groupActionOpen.value]);

  // console.log(finded.id,'selected finded id')
  const handleClickOutside = (event: any) => {
    if (openGroupActionModalRef.current && !openGroupActionModalRef.current.contains(event.target)) {
      setgroupActionOpen({ ...groupActionOpen, id: null, value: false });
      setdeleteModaOpen({ ...deleteModaOpen, value: false, id: 0 });
      setshowSuccessDeletion({ ...showSuccessDeletion, value: false, id: 0 });
      setEmailModalOpen({ ...emailModalOpen, value: false, id: 0 });
      setRestoreModalOpen({ ...restoreModalOpen, value: false, id: 0 });
      setshowResumeOption({ ...showResumeOption, value: false, id: 0 });
      setShowSuccessEmail({ ...showSuccessEmail, value: false, id: 0 });
      setSuspendModaOpen({ ...suspendModaOpen, value: false, id: 0 });
      setShowSuccessSuspend({ ...showSuccessSuspend, id: 0, value: false });
      setShowSuccessRestoration({ ...showSuccessRestoration, id: 0, value: false });
      setShowSuccessResume({ ...showSuccessResume, id: 0, value: false });
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  const dispatch: AppDispatch | any = useDispatch();
  const handleConfirmation = () => {
    if (deleteModaOpen.value === true) {
      let selectedCompanyIdsForDeletion = companiesList?.filter((item: any) => item.id === selectedId).map((item: any) => item.mongoId);
      // console.log(selectedCompanyIdsForDeletion, "id for deletion");

      dispatch(companyActions.deleteSelectedCompanies(selectedCompanyIdsForDeletion)).then(() => {
        // let countries = countryList?.length > 0 && countryList?.filter((item: any) => item.isSelected).map((item: any) => item.label);
        // let plans =
        //   planList.filter((item: any) => !item.isSelected)?.length !== 0 &&
        //   planList.filter((item: any) => item.isSelected).map((item: any) => item.label);
        // let seats =
        //   seatsList.filter((item: any) => !item.isSelected)?.length !== 0 &&
        //   seatsList.filter((item: any) => item.isSelected).map((item: any) => item.label);
        // let status =
        //   statusList.filter((item: any) => !item.isSelected)?.length !== 0 &&
        //   statusList.filter((item: any) => item.isSelected).map((item: any) => item.label);
        // setTimeout(() => {
        const selectedFilters = getSelectedFilters();
        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: selectedFilters.countries,
            subscriptionStatus: selectedFilters.status,
            subscriptionPlan: selectedFilters.plans,
            subscriptionSeats: selectedFilters.seats,
            sortAt: sortField,
            sortBy: sortBy,
          })
        ).then(() => {
          setCompanyLoading(true);
          setDeleteCompanyLoading(true);
        });
        // }, 1000);
      });
      setdeleteModaOpen({ ...deleteModaOpen, id: 0, value: false });
      setgroupActionOpen({ ...groupActionOpen, value: false, id: null });
      setshowSuccessDeletion({ ...showSuccessDeletion, value: true, id: deleteModaOpen.id });
    } else if (suspendModaOpen.value === true && showResumeOption.value === false) {
      setSuspendModaOpen({ ...suspendModaOpen, id: 0, value: false });

      let selectedCompanyIdsForSuspension = companiesList?.filter((item: any) => item.id === selectedId).map((item: any) => item.mongoId);
      dispatch(companyActions.suspendSelectedCompanies(selectedCompanyIdsForSuspension, reason)).then(() => {
        setReason("");
        // setTimeout(() => {
        const selectedFilters = getSelectedFilters();
        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: selectedFilters.countries,
            subscriptionStatus: selectedFilters.status,
            subscriptionPlan: selectedFilters.plans,
            subscriptionSeats: selectedFilters.seats,
            sortAt: sortField,
            sortBy: sortBy,
          })
        ).then(() => {
          setCompanyLoading(true);
        });
        // }, 1000);
      });
      setgroupActionOpen({ ...groupActionOpen, value: false, id: null });
      setShowSuccessSuspend({ ...showSuccessSuspend, value: true, id: suspendModaOpen.id });
    } else if (emailModalOpen.value === true) {
      let selectedCompaniesToSendEmail = companiesList?.filter((item: any) => item.id === selectedId).map((item: any) => item.email);
      dispatch(companyActions.sendEmailToCompany(selectedCompaniesToSendEmail, { subject: emailSubject, message: emailMessage })).then(() => {
        setEmailSubject("");
        setEmailMessage("");
      });
      setEmailModalOpen({ ...emailModalOpen, id: 0, value: false });
      setgroupActionOpen({ ...groupActionOpen, value: false, id: null });
      setShowSuccessEmail({ ...emailModalOpen, value: true, id: emailModalOpen.id });
    } else if (restoreModalOpen.value === true) {
      const selectedIdsForRestoration = companiesList?.filter((item: any) => item.id === selectedId).map((item: any) => item.mongoId);
      dispatch(deleteCompanyAction.restoreDeletedCompanies(selectedIdsForRestoration)).then(() => {
        let countries = countryList?.length > 0 && countryList?.filter((item: any) => item.isSelected).map((item: any) => item.label);
        let days =
          daysList.filter((item: any) => !item.isSelected)?.length !== 0 &&
          daysList.filter((item: any) => item.isSelected).map((item: any) => item.label);

        dispatch(deleteCompanyAction.getAllDeletedCompanies({ page: page!, size: size!, countries: countries, days: days })).then(() => {
          setDeleteCompanyLoading(true);
        });
      });
      setRestoreModalOpen({ ...restoreModalOpen, value: false, id: 0 });
      setgroupActionOpen({ ...groupActionOpen, value: false, id: null });
      setShowSuccessRestoration({ ...showSuccessRestoration, value: true, id: restoreModalOpen.id });
    } else if (showResumeOption.value === true) {
      setshowResumeOption({ ...showResumeOption, value: false, id: 0 });
      setgroupActionOpen({ ...groupActionOpen, value: false, id: null });
      setCompanyLoading(true);
      let selectedCompanyIdsForSuspension = companiesList?.filter((item: any) => item.id === selectedId).map((item: any) => item.mongoId);
      dispatch(companyActions.resumeSuspendedCompanies(selectedCompanyIdsForSuspension)).then(() => {
        // setTimeout(() => {
        const selectedFilters = getSelectedFilters();
        dispatch(
          companyActions.getAllCompanies({
            page: page,
            size: size,
            searchName: searchValue,
            countries: selectedFilters.countries,
            subscriptionStatus: selectedFilters.status,
            subscriptionPlan: selectedFilters.plans,
            subscriptionSeats: selectedFilters.seats,
            sortAt: sortField,
            sortBy: sortBy,
          })
        ).then(() => {
          setCompanyLoading(true);
        });
        // }, 1000);
      });

      setShowSuccessResume({ ...showSuccessResume, value: true, id: showResumeOption.id });
    }
  };
  const handleCancel = () => {
    if (deleteModaOpen.value) setdeleteModaOpen({ ...deleteModaOpen, value: false, id: 0 });
    else if (suspendModaOpen.value) setSuspendModaOpen({ ...suspendModaOpen, value: false, id: 0 });
    else if (emailModalOpen.value) setEmailModalOpen({ ...emailModalOpen, value: false, id: 0 });
    else if (restoreModalOpen.value) setRestoreModalOpen({ ...restoreModalOpen, value: false, id: 0 });
    else if (showResumeOption.value) setshowResumeOption({ ...showResumeOption, value: false, id: 0 });
  };
  //open deleteModal
  const openDeleteModalHandler = () => {
    setdeleteModaOpen({ ...deleteModaOpen, id: selectedId, value: true });
  };
  const openSuspendModalHandler = () => {
    if (!isCompanySuspended.value) {
      setSuspendModaOpen({ ...suspendModaOpen, id: selectedId, value: true });
    }
  };
  const openEmailModalHandler = () => {
    setEmailModalOpen({ ...emailModalOpen, id: selectedId, value: true });
  };
  const openRestoreModalHandler = () => {
    setRestoreModalOpen({ ...restoreModalOpen, id: selectedId, value: true });
  };
  const openResumeHandler = () => {
    if (isCompanySuspended.value === true) {
      setshowResumeOption({ ...showResumeOption, id: selectedId, value: true });
    }
  };
  //closing success modal
  const handleSuccessConfirmation = () => {
    if (showSuccessDeletion.value) {
      setshowSuccessDeletion({ ...showSuccessDeletion, value: false, id: 0 });
    } else if (showSuccessEmail.value) {
      setShowSuccessEmail({ ...showSuccessEmail, value: false, id: 0 });
    } else if (showSuccessSuspend.value) {
      setShowSuccessSuspend({ ...showSuccessSuspend, value: false, id: 0 });
    } else if (showSuccessRestoration.value) {
      setShowSuccessRestoration({ ...showSuccessRestoration, value: false, id: 0 });
    } else if (showSuccessResume.value) {
      setShowSuccessResume({ ...showSuccessResume, value: false, id: 0 });
    }
  };
  const handleReason = (e: any) => {
    setReason(e.target.value);
  };

  return (
    <div>
      <div className="table-responsive w-100">
        <table className="table table-main  ">
          {tableHeaderState?.filter((item) => item.isSelected)?.length > 0 ? (
            <thead>
              <tr>
                {!deltedCompany ? (
                  <th style={{ border: "none" }}>
                    <input
                      type="checkbox"
                      className="checkbox"
                      style={{
                        height: "16px",
                        width: "16px",
                        border: "none",
                        background: "#F2F2F2",
                      }}
                      checked={
                        companiesList && companiesList?.length > 0
                          ? companiesList?.filter((item: any) => !item.isSelected)?.length > 0
                            ? false
                            : true
                          : false
                      }
                      onClick={() => {
                        handleSelectAllTableCheckbox();
                      }}
                    />
                  </th>
                ) : null}

                {tableHeaderState.map((item, index) => {
                  let finded = item.isSelected;

                  return (
                    <th
                      key={index}
                      scope="col"
                      data-align="start"
                      className="header text-start"
                      style={{
                        color: "#4F4F4F",
                        columnFill: "balance",
                        border: "none",
                        fontWeight: 700,
                      }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item.label !== "Actions") {
                            handleSort(item);
                          }
                        }}
                      >
                        {finded ? item.label : ""}
                      </span>

                      {finded && item.label.includes(selectedHeader.label) && item.id !== "9" && (
                        <span className="ml-2">
                          <img
                            id="sort-icon"
                            onClick={handleArrowSortClick}
                            src={sortArrow}
                            alt="sort-icon"
                            style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99" }}
                          />
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          ) : null}

          <tbody
            className="w-100 h-100"
            style={{
              color: "#333333",
              fontWeight: "400",
              fontSize: "12px",
              flexWrap: "wrap",
            }}
          >
            {tableHeaderState?.filter((item) => item.isSelected)?.length > 0 && companiesList?.length > 0
              ? companiesList.map((item: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        height: index === companiesList.length - 1 ? "" : "50px",
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "12px",
                        border: "1px solid #EAEAEA",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {!deltedCompany ? (
                        <th
                          scope="row"
                          style={{
                            color: "#333333",
                            fontWeight: "400",
                            fontSize: "14px",
                            alignItems: "start",
                            display: "flex",
                            justifyContent: "start",
                            border: "none",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox"
                            style={{ height: "16px", width: "16px", border: "none", background: "#F2F2F2" }}
                            checked={item.isSelected}
                            onClick={() => {
                              handleSelectTableCheckbox(item.id);
                            }}
                          />
                        </th>
                      ) : null}

                      <td
                        style={{
                          color: "#333333",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate(`/company/${item._id}`)}
                      >
                        {tableHeaderState?.filter((item) => item.id === "1" && item.isSelected)?.length > 0 ? item.buisnessId : ""}
                      </td>
                      <td
                        style={{
                          color: "#333333",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate(`/company/${item._id}`)}
                      >
                        {tableHeaderState?.filter((item) => item.id === "2" && item.isSelected)?.length > 0 ? item?.companyName : ""}
                      </td>
                      <td
                        style={{
                          color: "#333333",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate(`/company/${item._id}`)}
                      >
                        {tableHeaderState?.filter((item) => item.id === "3" && item.isSelected)?.length > 0 ? item?.ownerName : ""}
                      </td>
                      <td
                        style={{
                          color: "#333333",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate(`/company/${item._id}`)}
                      >
                        {tableHeaderState?.filter((item) => item.id === "4" && item.isSelected)?.length > 0 ? item?.email : ""}
                      </td>
                      <td
                        style={{
                          color: "#333333",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate(`/company/${item._id}`)}
                      >
                        {tableHeaderState?.filter((item) => item.id === "5" && item.isSelected)?.length > 0 ? item?.country : ""}
                      </td>
                      {!tableHeaderState.find((item) => item.label === "Days") ? (
                        <>
                          <td
                            style={{
                              color: "#333333",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            {tableHeaderState?.filter((item) => item.id === "6" && item.isSelected)?.length > 0
                              ? item?.subscription.currentPlan.plan
                              : ""}
                          </td>
                          <td
                            style={{
                              color: "#333333",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            {tableHeaderState?.filter((item) => item.id === "7" && item.isSelected)?.length > 0
                              ? item?.subscription.currentPlan.seats
                              : ""}
                          </td>
                          <td>
                            {" "}
                            {tableHeaderState?.filter((item) => item.id === "8" && item.isSelected)?.length > 0
                              ? refactorSuspendStatus(item?.subscription?.currentPlan?.status, item?.isSuspended)
                              : ""}
                          </td>
                        </>
                      ) : (
                        <td> {tableHeaderState?.filter((item) => item.id === "6" && item.isSelected).length > 0 ? item.daysLeft : ""}</td>
                      )}

                      <td
                        style={{
                          // color: ReportStatusColorHandler(item?.status),
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <span
                            onClick={() => {
                              handleModalOpen(item.id);
                            }}
                          >
                            <img src={actionsIcon} alt="actionsIcon" />
                            <img src={actionsIcon} alt="actionsIcon" />
                            <img src={actionsIcon} alt="actionsIcon" />
                          </span>

                          {groupActionOpen.value && groupActionOpen.id === item.id ? (
                            <div style={{ position: "absolute", top: 0, right: 0, width: "333px", height: "133px" }} ref={openGroupActionModalRef}>
                              <CompanyActionModal
                                suspendModalOpen={suspendModaOpen.value}
                                emailModalOpen={emailModalOpen.value}
                                deleteModaOpen={deleteModaOpen.value}
                                restoreModalOpen={restoreModalOpen.value}
                                openEmailModalHandler={openEmailModalHandler}
                                openSuspendModalHandler={openSuspendModalHandler}
                                openRestoreModalHandler={openRestoreModalHandler}
                                tableView={true}
                                reason={reason}
                                handleReason={handleReason}
                                setEmailMessage={setEmailMessage}
                                setEmailSubject={setEmailSubject}
                                emailSubject={emailSubject}
                                emailMessage={emailMessage}
                                message=""
                                handleSuccessConfirmation={handleSuccessConfirmation}
                                // setMessage={}
                                // setReason={}
                                onConfirmation={handleConfirmation}
                                openDeleteModalHandler={openDeleteModalHandler}
                                onCancel={handleCancel}
                                deletedCompanyView={deltedCompany}
                                showResumeOption={showResumeOption.value}
                                openResumeHandler={openResumeHandler}
                                isCompanySuspended={isCompanySuspended.value}
                                textDeleteColor={deleteTextColor.color}
                              />
                            </div>
                          ) : null}
                          <div style={{ zIndex: 1001, position: "absolute", right: "56px", bottom: "12px", width: "317px", background: "#FFFFFF" }}>
                            {showSuccessDeletion.value && showSuccessDeletion.id === item.id ? (
                              <SuccessMessage setshowSuccessCompletion={setshowSuccessDeletion} message="Company  deleted successfully " />
                            ) : (
                              ""
                            )}
                            {showSuccessSuspend.value && showSuccessSuspend.id === item.id ? (
                              <SuccessMessage setshowSuccessCompletion={setShowSuccessSuspend} message="Company  suspended successfully " />
                            ) : null}
                            {showSuccessEmail.value && showSuccessEmail.id === item.id ? (
                              <SuccessMessage setshowSuccessCompletion={setShowSuccessEmail} message="The email was successfully sent" />
                            ) : null}
                            {/* {showSuccessRestoration.value && showSuccessRestoration.id === item.id ? (
                              <SuccessMessage setshowSuccessCompletion={setShowSuccessRestoration} message="Company restored successfully" />
                            ) : null} */}
                            {showSuccessResume.value && showSuccessResume.id === item.id ? (
                              <SuccessMessage setshowSuccessCompletion={setShowSuccessResume} message="Company resumed successfully" />
                            ) : null}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <CustomPagination page={page!} setSize={setSize} setpage={setPage} size={size!} total={total!} />
    </div>
  );
}

export default Table;
