import SingleDummyChart from "../charts/SingleChart";
import CustomPagination from "../../companies/CustomPagination";
import searchIcon from "../../../assets/images/siteSearchIcon.svg";
import Skeleton from "react-loading-skeleton";
import sortArrow from "../../../assets/images/sortArrow.svg";

function RoleDetails({
  setSelectedOption,
  chartsData,
  chartsLoading,
  rolesHeader,
  page,
  setpage,
  setsize,
  size,
  total,
  allRoles,
  searchText,
  setsearchText,
  handleSearchRoles,
  handleArrowSortClick,
  handleSort,
  selectedHeader,
}: {
  setSelectedOption: any;
  chartsData: any;
  chartsLoading: boolean;
  rolesHeader: any;
  page: number;
  size: number;
  total: number;
  setpage: any;
  setsize: any;
  allRoles: any;
  searchText: string;
  setsearchText: any;
  handleSearchRoles: any;
  handleArrowSortClick: any;
  handleSort: any;
  selectedHeader: any;
}) {
  // console.log(allRoles, "data roles");
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Roles</div>
        <button className="back-btn" onClick={() => window.history.back()}>
          Back
        </button>
      </div>
      <div className="mt-2 user-chart-wrap">
        {!chartsLoading && chartsData && chartsData?.rolesChartToShow? (
          <SingleDummyChart
            title={`Total ${chartsData?.rolesChartToShow[0]?.title}`}
            analyticsXaxisData={chartsData?.xAxisData}
            className=" shadow-lg mt-3   w-100 "
            totalResult={chartsData?.rolesChartToShow[0]?.value}
            color="#EB5757"
            selectedItem={chartsData?.rolesChartToShow[0]}
            dateToShow={true}
          />
        ) : (
          <div className="bg-white circle p-2">
            <div className="d-flex justify-content-between align-items-center">
              <Skeleton width={100} />
              <Skeleton width={100} />
            </div>
            <Skeleton count={8} />
          </div>
        )}
      </div>
      <div className="payment-card-wrap mt-3 w-100">
        <div className="sites-search-wrap ml-auto">
          <img src={searchIcon} alt="searchIcon" />
          <input
            type="text"
            placeholder="Search in roles"
            value={searchText}
            onChange={(e: any) => {
              setsearchText(e.target.value);
              handleSearchRoles(e.target.value);
            }}
            className="sites-searchBar"
          />{" "}
        </div>
        <div className="mt-4">
          <table className="table-main table user-table">
            <thead>
              <tr className="table-head">
                {rolesHeader?.map((item: any, index: number) => {
                  return (
                    <th key={index}>
                      {" "}
                      <span onClick={() => handleSort(item)}> {item.label}</span>
                      {item.label === selectedHeader.label ? (
                        <span className="ml-1">
                          <img
                            id="roles-sort-icon"
                            onClick={handleArrowSortClick}
                            src={sortArrow}
                            alt="sort-icon"
                            style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99" }}
                          />
                        </span>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {allRoles?.map((item: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className="table-row"
                    onClick={() => {
                      setSelectedOption(`roles_single_${item._id}`);
                    }}
                  >
                    <td>{item.roleBuisnessId}</td>
                    <td>{item.name}</td>
                    <td>{item.totalUsers}</td>
                    <td>{item.roleAccess}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CustomPagination page={page} setSize={setsize} setpage={setpage} size={size} total={total} />
        </div>
      </div>
    </div>
  );
}

export default RoleDetails;
