import dateIcon from "../../assets/images/dateIcon.svg";
import seeMoreIcon from "../../assets/images/seeMoreIcon.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
import yellowCircle from "../../assets/images/yellowCircle.svg";
import redCircle from "../../assets/images/redCircle.svg";
import greyCircle from "../../assets/images/greyCircle.svg";
import CustomSelect from "../../Components/multi-select/CustomSelect";
import React, { useRef, useState } from "react";
import closeIcon from "../../assets/images/CloseIcon.svg";
import moment from "moment";
import { readablePaymentStatus } from "../../helper/helperFunction";
// import { readablePaymentStatus } from "../../helper/helperFunction";
interface CompanyLogsIProps {
  setSelectedDate?: any;
  selectedDate?: any;
  setselectedValue?: any;
  selectedValue?: any;
  options?: any;
  setslectedLable?: any;
  slectedLable?: any;
  companyLogs?: any;
  searchValue?: any;
  setsearchValue?: any;
  handleSearchLogs?: any;
  resetSearchingHandler?: any;
  setCloseSearchBarIfValue?: any;
  closeSearchBarIfValue?: any;
  limit: number;
  LoadMoreLogs: any;
  totalCount: number;
  title?: string;
  notificationSection?: any;
  subscription?: any;
  seatsData?: any;
  price?: any;
  dateSection: boolean;
  logSection: boolean;
  selectedOption: string;
  invoiceData: any;
}
function CompanyLogs({
  setSelectedDate,
  selectedDate,
  companyLogs,
  setselectedValue,
  selectedValue,
  options,
  setslectedLable,
  slectedLable,
  searchValue,
  setsearchValue,
  handleSearchLogs,
  setCloseSearchBarIfValue,
  closeSearchBarIfValue,
  resetSearchingHandler,
  LoadMoreLogs,
  limit,
  totalCount,
  subscription,
  title,
  notificationSection,
  seatsData,
  dateSection,
  price,
  logSection,
  selectedOption,
  invoiceData,
}: CompanyLogsIProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const searchRef = useRef<any>();
  const handleOutsideClickSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      if (!closeSearchBarIfValue) {
        setSearchBarOpen(true);
      } else {
        setSearchBarOpen(false);
      }
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSearch);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSearch);
    };

    // eslint-disable-next-line
  }, [closeSearchBarIfValue]);

  return (
    <div className="company-notifications h-100" style={{ position: "relative", marginTop: subscription ? ".6rem" : ".6rem" }}>
      {dateSection && (
        <>
          <div className="date-wrapper pr-2">
            <div>
              {selectedDate.startDate}-{selectedDate.endDate}
            </div>
            <div>
              <img src={dateIcon} alt="date icon" onClick={() => setIsOpen(!isOpen)} />
            </div>
          </div>
          <CustomSelect
            setselectedValue={setselectedValue}
            selectedValue={selectedValue}
            options={options}
            setslectedLable={setslectedLable}
            slectedLable={slectedLable}
            setSelectedDate={setSelectedDate}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            // selectedDate={selectedDate}
          />
        </>
      )}

      {/* notifications section */}
      {notificationSection ? (
        <>
          {" "}
          <div className="notification-wrap mt-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="ticket">#Tk0000029</span>
                <span className="day ml-2">1 Days ago</span>
              </div>
              <div className="status">
                <div className="in-progress">in progress</div>
              </div>
            </div>
            <div className="detail mt-3">
              <span> Bob Clarcson</span>
              <div className="explanation mt-3">
                I run a team of 20 product managers, developers, QA and UX Previously we designed everything ourselves.
              </div>
            </div>
          </div>
          <div className="notification-wrap mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="ticket">#Tk0000029</span>
                <span className="day ml-2">1 Days ago</span>
              </div>
              <div className="status">
                <div className="complete">complete</div>
              </div>
            </div>
            <div className="detail mt-3">
              <span> Bob Clarcson</span>
              <div className="explanation mt-3">
                I run a team of 20 product managers, developers, QA and UX Previously we designed everything ourselves.
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-2 mr-2">
            <div className="mr-2" style={{ color: "#20639B", fontSize: "15px", lineHeight: "22.42px" }}>
              More
            </div>
            <div>
              <img src={seeMoreIcon} alt="see-more-icon" />
              <img src={seeMoreIcon} alt="see-more-icon" />
            </div>
          </div>
        </>
      ) : null}

      {/* subscription data */}

      {subscription && seatsData && price ? (
        <>
          <div className="notification-wrap" style={{ marginTop: "2rem", height: "200px" }}>
            <span className="subscription-notification-title">Seat details</span>
            <div className="subscription-sub-title" style={{ marginTop: "1.5rem" }}>
              <div>Total</div>
              <div>{seatsData?.total} seats</div>
            </div>
            <div className=" subscription-sub-title">
              <div>Used</div>
              <div>{seatsData?.used} seats</div>
            </div>
            <div className=" subscription-sub-title">
              <div>Available</div>
              <div>{seatsData?.available} seats</div>
            </div>
            <div className=" subscription-sub-title">
              <div>Price</div>
              <div>SAR {price?.toFixed(2)}</div>
            </div>
          </div>
        </>
      ) : null}
      {selectedOption.includes("subscriptionInvoice") ? (
        <>
          <div className="notification-wrap" style={{ marginTop: "4.5rem", height: "324px", }}>
            <span className="subscription-notification-title" style={{fontWeight: 600}}>Payment :</span>
            <div className="subscription-sub-title" style={{ marginTop: "1.5rem" }}>
              <div>STATUS :</div>
              <div>{readablePaymentStatus(invoiceData?.paymentMetaData?.status)}</div>
            </div>
            <div className=" subscription-sub-title" style={{ marginTop: "1rem" }}>
              <div>SOURCE	:</div>
              <div>{invoiceData.paymentMetaData?.sourceMethod } Card</div>
            </div>
            <div className=" subscription-sub-title" style={{ marginTop: "1rem" }}>
              <div>PAYMENT ID :</div>
              <div style={{textAlign:"end"}}>{invoiceData.paymentMetaData?.paymentId }</div>
            </div>
            <div className="subscription-sub-title" style={{ marginTop: "1rem" }}>
              <div>CURRENCY:</div>
              <div>{invoiceData.paymentMetaData?.currency }</div>
            </div>
            <div className="subscription-sub-title" style={{ marginTop: "1rem" }}>
              <div>AMOUNT :</div>
              <div>{parseFloat(invoiceData.paymentMetaData?.amount.replace(/[^\d.-]/g, '')) }</div>
            </div>
            <div className="subscription-sub-title" style={{ marginTop: "1rem" }}>
              <div>CREATED AT :</div>
              <div>{moment(invoiceData?.paymentMetaData?.createdAt).format("DD MMM YYYY h:mm") }</div>
            </div>
          </div>
        </>
      ) : null}

      {/* changes log section */}

      {logSection ? (
        <div
          className="logs-wrap "
          style={{
            marginTop:
              selectedOption.includes("sites") ||
              selectedOption.includes("users") ||
              selectedOption.includes("forms") ||
              selectedOption.includes("reports")
                ? "1.5rem"
                : ".5rem",
          }}
        >
          {!searchBarOpen && <div className="title">Changes Log:</div>}
          <div className="d-flex justify-content-between align-items-center mb-3" style={{ marginTop: searchBarOpen ? "1.4rem" : "" }}>
            {!searchBarOpen && <div className="info">Changes on {title}</div>}

            <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
              <div style={{ width: "100%", height: "34px" }}>
                {searchBarOpen ? (
                  <input
                    value={searchValue}
                    onChange={(e: any) => {
                      setsearchValue(e.target.value);
                      handleSearchLogs(e.target.value);
                    }}
                    type="text"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      outline: "none",
                      background: "#F2F2F2",
                      borderRadius: "6px",
                      padding: "1rem",
                      color: searchValue.length ? "#4F4F4F" : "#BDBDBD",
                      fontWeight: !searchValue.length ? 500 : 700,
                    }}
                    className="searchBar"
                    placeholder="Search"
                  />
                ) : null}

                <div style={{ position: "absolute", top: 3, right: 20 }}>
                  {searchValue.length && searchBarOpen ? (
                    <img
                      src={closeIcon}
                      alt="search icon"
                      onClick={() => {
                        setsearchValue("");
                        resetSearchingHandler();
                        setCloseSearchBarIfValue(true);
                      }}
                    />
                  ) : (
                    <img
                      src={searchIcon}
                      alt="search icon"
                      onClick={() => {
                        setSearchBarOpen(!searchBarOpen);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* logs section */}
          <div className="log-details" id="scrollableElement">
            {companyLogs?.length > 0
              ? companyLogs.map((item: any) => {
                  return (
                    <>
                      <div className="left-side">{moment(item.createdAt).format("DD MMM YYYY, HH:mm")}</div>
                      <div className="right-side">
                        <div className="d-flex align-items-start" style={{ justifyContent: "start" }}>
                          <img
                            src={
                              item.logMessage.toLowerCase().includes("deleted") ||
                              item.logMessage.toLowerCase().includes("removed") ||
                              item.logMessage.toLowerCase().includes("cancelled") ||
                              item.logMessage.toLowerCase().includes("canceled")
                                ? redCircle
                                : item.logMessage.toLowerCase().includes("suspended")
                                ? yellowCircle
                                : greyCircle
                            }
                            alt="circle"
                            style={{ zIndex: "100" }}
                          />
                          <div className="ml-2">
                            <span>{item.createdBy}</span>
                            <span style={{ color: item.logMessage.toLowerCase().includes("suspended") ? "#F2994A" : "#2F80ED" }}>
                              {" "}
                              {item.logMessage}
                            </span>
                            <span> {item.detailedMessage.split(` ${item.logMessage} `).pop()}</span>
                          </div>
                          <div className="verticle-line"></div>
                        </div>
                      </div>
                    </>

                    // </div>
                  );
                })
              : null}
            {companyLogs.length > 0 && companyLogs?.length < totalCount && (
              <button style={{ outline: "none", border: "none", background: "transparent" }} onClick={LoadMoreLogs}>
                Load More
              </button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CompanyLogs;
