export interface CompanyForEdit {
  isDeleted: boolean;
  isSuspended: boolean;
  documentNumber: number;
  inspectionNumber: number;
  reportNumber: number;
  _id: string;
  companyName: string;
  xAxisData: string[];
  industry: string;
  numberOfEmployees: string;
  country: string;
  email: string;
  phone: string;
  buisnessId: string;
  image: string;
  deletedAt: null;
  createdAt: string;
  updatedAt: string;
  subscription: {
    currentPlan: {
      plan: string;
      status: string;
      price: number;
      seats: number;
    };
    seats: {
      total: number;
      used: number;
      available: number;
    };
    userTrial: {
      isTrialStarted: boolean;
      isTrialCancelled: boolean;
      isTrialExpired: boolean;
    };
    subscriptionInfo: {
      isSubscribed: boolean;
      isSubscribedExpired: boolean;
      isUnsubscribed: boolean;
      isSubscribeResumed: boolean;
    };
    lastAdditionalseats: {
      seats: number;
      seatsBill: number;
      lastPerSeatBill: number;
    };
    nextUpdatedPlan: string;
  };
  companyChartsToShow: [
    {
      title: string;
      value: number;
      perDayCalculation: [];
    }
  ];
}

export const companyForEditInitialState: CompanyForEdit = {
  isDeleted: false,
  isSuspended: false,
  documentNumber: 1,
  inspectionNumber: 0,
  reportNumber: 0,
  image: "",
  _id: "",

  companyName: "",
  industry: "",
  numberOfEmployees: "",
  country: "",
  email: "",
  deletedAt: null,
  phone: "",
  buisnessId: "",
  createdAt: "",
  updatedAt: "",
  subscription: {
    currentPlan: {
      plan: "",
      status: "",
      price: 0,
      seats: 0,
    },
    seats: {
      total: 1,
      used: 1,
      available: 0,
    },
    userTrial: {
      isTrialStarted: true,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
    subscriptionInfo: {
      isSubscribed: false,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    lastAdditionalseats: {
      seats: 0,
      seatsBill: 0,
      lastPerSeatBill: 0,
    },
    nextUpdatedPlan: "",
  },
  companyChartsToShow: [
    {
      title: "",
      value: 0,
      perDayCalculation: [],
    },
  ],
  xAxisData: [""],
};

export interface CompanySearchInterface {
  id: string;
  startDate: string;
  endDate: string;
}

export interface CompanyLogsForEditDTO {
  _id: string;
  createdAt: string;
  createdBy: string;
  logMessage: string;
  detailedMessage: string;
}
export interface CompanyLogsSearchDTO {
  id: string;
  startDate: string;
  endDate: string;
  searchName: string;
  limit: number;
  logSearchFor: string;
  actionId?: string;
}
export interface CompanySubscriptionSearchDTO {
  id: string;
  startDate: string;
  endDate: string;
}
export interface CompanySubscriptionDTO {
  _id: string;
  companyName: string;
  industry: string;
  numberOfEmployees: string;
  country: string;
  timeZone: string;
  email: string;
  phone: string;
  buisnessId: string;
  createdAt: string;
  updatedAt: string;
  __v: 0;
  image: string;
  reasonForSuspend: null;
  deletedAt: null;
  subscription: {
    currentPlan: {
      plan: string;
      status: string;
      price: string;
      seats: string;
    };
    seats: {
      total: 2;
      used: 2;
      available: 0;
    };
    userTrial: {
      isTrialStarted: boolean;
      isTrialCancelled: boolean;
      isTrialExpired: boolean;
    };
    subscriptionInfo: {
      isSubscribed: boolean;
      isSubscribedExpired: boolean;
      isUnsubscribed: boolean;
      isSubscribeResumed: boolean;
    };
    lastAdditionalseats: {
      seats: 1;
      seatsBill: -5.120707537240144;
      lastPerSeatBill: -4.452789162817517;
    };
    nextUpdatedPlan: string;
    isGracePeriod: boolean;
    _id: string;
    company: string;
    monthly: [];
    yearly: [];
    createdAt: string;
    updatedAt: string;
    __v: 0;
    trialEndedAt: string;
    trialStartedAt: string;
    subscriptionEndedAt: string;
    subscriptionStartedAt: string;
  };
  paymentCards: [
    {
      _id: string;
      name: string;
      default: boolean;
      number: string;
      month: 10;
      year: 20;
    }
  ];
  subscriptionChartsToShow: [
    {
      title: string;
      value: 0;
      perDayCalculation: [];
    },
    {
      title: string;
      value: 0;
      perDayCalculation: [];
    }
  ];
  xAxisData: [];
  invoices: [
    {
      currency: string;
      amount: 0;
      subscriptionData: {
        currentPlan: {
          plan: string;
          status: string;
          seats: 1;
          price: 79;
        };
        seats: {
          used: 1;
          available: 0;
          total: 1;
        };
        userTrial: {
          isTrialStarted: boolean;
          isTrialCancelled: boolean;
          isTrialExpired: boolean;
        };
        subscriptionInfo: {
          isSubscribed: boolean;
          isSubscribedExpired: boolean;
          isUnsubscribed: boolean;
          isSubscribeResumed: boolean;
        };
        lastAdditionalseats: {
          seatsBill: null;
          lastPerSeatBill: null;
        };
        nextUpdatedPlan: string;
        isGracePeriod: boolean;
        _id: string;
        company: string;
        monthly: [];
        yearly: [];
        createdAt: string;
        updatedAt: string;
        __v: 0;
        trialEndedAt: string;
        trialStartedAt: string;
        subscriptionStartedAt: string;
        subscriptionEndedAt: string;
      };
      invoiceDummyId: string;
      taxNo: string;
      createdAt: string;
      updatedAt: string;
      id: string;
    }
  ];
}
export const companySubscriptionState: CompanySubscriptionDTO = {
  _id: "",
  companyName: "",
  industry: "",
  numberOfEmployees: "",
  country: "",
  timeZone: "",
  email: "",
  phone: "",
  buisnessId: "",
  createdAt: "",
  updatedAt: "",
  __v: 0,
  image: "",
  reasonForSuspend: null,
  deletedAt: null,
  subscription: {
    currentPlan: {
      plan: "",
      status: "",
      price: "",
      seats: "",
    },
    seats: {
      total: 2,
      used: 2,
      available: 0,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: false,
      isTrialExpired: false,
    },
    subscriptionInfo: {
      isSubscribed: false,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    lastAdditionalseats: {
      seats: 1,
      seatsBill: -5.120707537240144,
      lastPerSeatBill: -4.452789162817517,
    },
    nextUpdatedPlan: "",
    isGracePeriod: false,
    _id: "",
    company: "",
    monthly: [],
    yearly: [],
    createdAt: "",
    updatedAt: "",
    __v: 0,
    trialEndedAt: "",
    trialStartedAt: "",
    subscriptionEndedAt: "",
    subscriptionStartedAt: "",
  },
  paymentCards: [
    {
      _id: "",
      name: "",
      default: false,
      number: "",
      month: 10,
      year: 20,
    },
  ],
  subscriptionChartsToShow: [
    {
      title: "",
      value: 0,
      perDayCalculation: [],
    },
    {
      title: "",
      value: 0,
      perDayCalculation: [],
    },
  ],
  xAxisData: [],
  invoices: [
    {
      currency: "",
      amount: 0,
      subscriptionData: {
        currentPlan: {
          plan: "",
          status: "",
          seats: 1,
          price: 79,
        },
        seats: {
          used: 1,
          available: 0,
          total: 1,
        },
        userTrial: {
          isTrialStarted: false,
          isTrialCancelled: false,
          isTrialExpired: false,
        },
        subscriptionInfo: {
          isSubscribed: false,
          isSubscribedExpired: false,
          isUnsubscribed: false,
          isSubscribeResumed: false,
        },
        lastAdditionalseats: {
          seatsBill: null,
          lastPerSeatBill: null,
        },
        nextUpdatedPlan: "",
        isGracePeriod: false,
        _id: "",
        company: "",
        monthly: [],
        yearly: [],
        createdAt: "",
        updatedAt: "",
        __v: 0,
        trialEndedAt: "",
        trialStartedAt: "",
        subscriptionStartedAt: "",
        subscriptionEndedAt: "",
      },
      invoiceDummyId: "",
      taxNo: "",
      createdAt: "",
      updatedAt: "",
      id: "",
    },
  ],
};
export interface InvoiceEmailPayload {
  invoiceId: string;
  email: string;
}
export interface CompanyUsersSearchDTO{
  companyId: string;
  searchItem?: string;
  sortAt: string;
  sortBy: string;
  size: number;
  page: number;
}
export interface CompanyRoleSearchDTO {
  companyId: string;
  searchItem?: string;
  sortAt: string;
  sortBy: string;
  size: number;
  page: number;
}

export interface SiteSearchDTO {
  companyId: string;
  name?: string;
  sortBy: string;
  sortAt: string;
  size: number;
  page: number;
}
export interface InvoiceEmailPayload{
  invoiceId: string;
  email: string;
}
