import { useEffect, useState } from "react";
import BrandLogo from "../../assets/images/blue-brand-logo.svg";
import qrCode from "../../assets/images/qrCode.svg";
import * as companyAction from "../../redux/companies/companiesAction";
import { useParams } from "react-router";
import moment from "moment";
import { invoiceTranslation } from "../../helper/helperFunction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { StoreState } from "../../redux/storeState/storeState";

function ViewInvoice() {
  const { id } = useParams();
  const [invoiceForEdit, setinvoiceForEdit] = useState<any>({});
  const [companyData, setcompanyData] = useState<any>({});
  const dispatch: AppDispatch | any = useDispatch();
  const { companyInvoiceForEdit, companyForEdit } = useSelector((state: StoreState) => ({
    companyInvoiceForEdit: state.company.invoiceForEdit,
    companyForEdit: state.company.companyForEdit,
  }));

  useEffect(() => {
    if (id) {
      dispatch(companyAction.getSubscriptionInvoiceById(id));
    }
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (companyInvoiceForEdit !== undefined) {
      setinvoiceForEdit(companyInvoiceForEdit);
      dispatch(
        companyAction.getCompanyById({
          id: companyInvoiceForEdit?.subscriptionData?.company,
          startDate: "",
          endDate: "",
        })
      );
    }
    // eslint-disable-next-line
  }, [companyInvoiceForEdit]);
  useEffect(() => {
    if (companyInvoiceForEdit && companyForEdit) {
      setcompanyData(companyForEdit);
    }
    // eslint-disable-next-line
  }, [companyForEdit]);

  // console.log(invoiceForEdit, "ed");
  let SubTotal =
    invoiceForEdit?.subscriptionData?.currentPlan?.plan !== "Additional seats"
      ? invoiceForEdit?.subscriptionData?.currentPlan?.seats * invoiceForEdit?.subscriptionData?.currentPlan?.price
      : invoiceForEdit?.subscriptionData?.lastAdditionalseats?.seats * invoiceForEdit?.subscriptionData?.currentPlan?.price;
  let taxPrice = (15 / 100) * SubTotal;
  let total = taxPrice + SubTotal;
  // console.log(companyInvoiceForEdit, "invoice kk");
  return (
    <div className="invoice-main-wrapper">
      {companyInvoiceForEdit !== undefined && companyData ? (
        <div className="invoice-wrapper py-5">
          <div className="sub-wrap">
            <div>
              <img src={BrandLogo} alt="company-logo" />
              <div className="mt-5">
                <div>Numu It Est. - TAMAM APP</div>
                <div className="my-2">Dammam - Saudi Arabia</div>
                <div>CR .2050139742</div>
                <div className="hr"></div>
              </div>
            </div>

            <div className="ml-auto">
              <div className="main-title">Invoice</div>
              <div className="mt-5">
                <div className="d-flex justify-content-between" style={{ gap: "10rem", marginTop: "59px" }}>
                  <div>Invoice number:</div>
                  <div>A1- {invoiceForEdit?.invoiceDummyId} </div>
                </div>
                <div className="d-flex my-2 justify-content-between">
                  <div>Date:</div>
                  {/* <div>07 Jul 2022</div> */}
                  <div>{moment(invoiceForEdit?.createdAt).format("DD MMM YYYY")}</div>
                </div>
                <div>Paid</div>
              </div>
              <div className="hr " style={{ width: "330px", marginTop: "49px" }}></div>
            </div>
          </div>
          <div className="sub-wrap mt-4">
            <div>
              <b>Bailed To </b>
              <div className="mt-2">
                <div>{companyData?.companyName}</div>
                <div className="my-2">Tax no. {companyData?.taxNumber}</div>
                <div>{companyData?.country}</div>
              </div>
            </div>
            <div className="ml-auto " style={{ marginTop: "-4px", paddingTop: "5px" }}>
              <b className="ml-5" style={{ marginRight: "15.5rem" }}>
                Subscription
              </b>
              <div className="mt-2">
                <div className="d-flex ml-5 justify-content-between" style={{ gap: "10rem" }}>
                  <div>Subscription ID:</div>
                  <div>{invoiceForEdit?.subscriptionData?.subscriptionId}</div>
                </div>
                <div className="d-flex my-2 w-100" style={{ justifyContent: "space-between" }}>
                  <div className="ml-5">Billing Period</div>
                  <div className="">
                    {moment(invoiceForEdit?.subscriptionData?.subscriptionStartedAt).format("DD MMM")} -{" "}
                    {moment(invoiceForEdit?.subscriptionData?.subscriptionEndedAt).format("DD MMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 ">
            <div style={{ fontWeight: 700 }}>Summary</div>
            <div className="table-responsive w-100 mt-3">
              <table className="table table-main ">
                <thead style={{ color: "#333333" }}>
                  <th style={{ fontWeight: 400, borderBottom: "0px" }}>Description</th>
                  <th style={{ fontWeight: 400, borderBottom: "0px" }}>Unit Price</th>
                  <th style={{ fontWeight: 400, borderBottom: "0px" }}>Units</th>
                  <th style={{ fontWeight: 400, borderBottom: "0px" }}>Total befor tax</th>
                  <th style={{ fontWeight: 400, borderBottom: "0px" }}>Vat. 15%</th>
                  <th style={{ fontWeight: 700, borderBottom: "0px" }}>Amount</th>
                </thead>
                <tbody style={{ color: "#333333" }}>
                  <tr className="p-2">
                    <td className="py-4" style={{ borderBottom: "1px solid #dee2e6" }}>
                      {invoiceTranslation(invoiceForEdit?.subscriptionData?.currentPlan?.plan)}
                    </td>
                    <td className="py-4" style={{ borderBottom: "1px solid #dee2e6" }}>
                      {invoiceForEdit?.currency} {invoiceForEdit?.subscriptionData?.currentPlan?.price.toFixed(2)}
                    </td>
                    <td className="py-4" style={{ borderBottom: "1px solid #dee2e6" }}>
                      {invoiceForEdit?.subscriptionData?.currentPlan?.plan !== "Additional seats"
                        ? invoiceForEdit?.subscriptionData?.currentPlan?.seats
                        : invoiceForEdit?.subscriptionData?.lastAdditionalseats?.seats}
                    </td>
                    <td className="py-4" style={{ borderBottom: "1px solid #dee2e6" }}>
                      {invoiceForEdit?.currency}{" "}
                      {SubTotal?.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    </td>
                    <td className="py-4" style={{ borderBottom: "1px solid #dee2e6" }}>
                      {invoiceForEdit?.currency} {taxPrice}
                    </td>
                    <td className="py-4" style={{ borderBottom: "1px solid #dee2e6", fontWeight: 700 }}>
                      {invoiceForEdit?.currency} {total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className=" mt-4 total-wrap">
                <div>Total befor tax</div>
                <div>
                  {" "}
                  {invoiceForEdit?.currency} {SubTotal.toFixed(2)}{" "}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end align-items-end">
                <div className=" mt-1 total-wrap">
                  <div>Vat. 15%</div>
                  <div>
                    {" "}
                    {invoiceForEdit?.currency} {taxPrice.toFixed(2)}
                  </div>
                </div>
                <div className="float-right mt-2 small-hr"></div>
              </div>
              <div className="mt-1 total-wrap">
                <div className="highlighted">Total</div>
                <div className="highlighted">
                  {" "}
                  {invoiceForEdit?.currency} {total}
                </div>
              </div>
              <div className="qr-wrap">
                <img src={qrCode} alt="qrCode" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ViewInvoice;
