import { createSlice } from "@reduxjs/toolkit";
import { Storage } from "react-jhipster";
import { User } from "../../interfaces/userInterface";


const TOKEN_KEY = "tamam-controlPanel-authenticationToken";
const TOKEN_TYPE = "token-type";

export interface AuthState {
  loading: boolean;
  listLoading: boolean;
  actionsLoading: boolean;
  error: undefined;
  signupError: null;
  user: User | undefined;
  userVerified: boolean;
  stepVerified: boolean;
  userData: User | undefined;
}

const initialLoginState: AuthState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  error: undefined,
  signupError: null,
  user: undefined,
  userVerified: false,
  stepVerified: false,
  userData: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialLoginState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      if (action.payload?.errorType === "signupError") {
        state.signupError = action.payload?.errorData;
      }
    },
    startCall: (state, action) => {
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    // login
    loginState: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
      // let userInfo = action.payload.userData;
      // if (userInfo) {
      //   state.userData = userInfo;
      // }
    },
    logoutState: (state) => {
      if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
        Storage.session.remove(TOKEN_KEY);
        Storage.local.remove(TOKEN_KEY);
        state.user = undefined;
      } else {
        state.user = undefined;
      }
    },
    tokenState: (state) => {
      if (Storage.local.get(TOKEN_TYPE) || Storage.session.get(TOKEN_TYPE)) {
        Storage.local.remove(TOKEN_TYPE);
        Storage.session.remove(TOKEN_TYPE);
        state.user = undefined;
      
      } else {
        state.user = undefined;
      }
    },

    whoAmIState: (state, action) => {
      const { entities } = action.payload;
      // console.log(entities,'user entity')
      state.actionsLoading = false;
      state.error = undefined;
      state.user = entities 
     
    },
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },


    passwordForgot: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
    resetPassword: (state, action) => {
      if (Storage.local.get(TOKEN_KEY) || Storage.session.get(TOKEN_KEY)) {
        Storage.session.remove(TOKEN_KEY);
        Storage.local.remove(TOKEN_KEY);
        state.user = undefined;
      } else {
        state.user = undefined;
      }
    },
    changePassword: (state, action) => {
      let bearerToken = action.payload.token;
      if (bearerToken) {
        Storage.local.set(TOKEN_KEY, bearerToken);
        Storage.session.set(TOKEN_KEY, bearerToken);
      }
    },
  },
});
