import React from "react";
// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./container/app-layout";
import IconScrollTop from "./assets/images/icon-scroll-top.svg";
function App() {
  const scrollToTopButton = document.getElementById("scrollTop");
  const scrollFunc = () => {
    let y = window.scrollY;
    if (scrollToTopButton) {
      if (y > 0) {
        scrollToTopButton.className = `btn-scroll-top ${window.onscroll ? "show" : "hide"}`;
      } else {
        scrollToTopButton.className = "btn-scroll-top hide";
      }
    }
  };
  window.addEventListener("scroll", scrollFunc);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;

    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 10);
    }
  };
  if (scrollToTopButton) {
    scrollToTopButton.onclick = function (e) {
      scrollToTop();
    };
  }

  return (
    <>
      <BrowserRouter>
        {/* <LoadingBar className="loading-bar" /> */}
        <AppLayout />
      </BrowserRouter>
      <a href="#scrollTop" className="btn-scroll-top hide" id="scrollTop">
        <img src={IconScrollTop} alt="IconScrollTop icon"></img>
      </a>
    </>
  );
}

export default App;
