import React, { useEffect, useRef, useState } from "react";
import actionIcon from "../../assets/images/actionsCircle.svg";
import moment from "moment";
import CompanyActionModal from "../../Components/modal/CompanyActionModal";
import SuccessMessage from "../companies/modal/SuccessMessage";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../redux/storeState/storeStateInterface";
import { AppDispatch } from "../../redux/store";
import { useParams } from "react-router";
import * as deleteCompanyAction from "../.././redux/deletedCompanies/deletedCompanyAction";

import * as companyActions from "../.././redux/companies/companiesAction";

import { useSidebar } from "../useSidebarProvider";
import { refactorSuspendStatus } from "../../helper/helperFunction";

interface CompanyInfoIProps {
  companyInfo?: any;
  setcompanyLoading?: any;
  size: number;
  logSearchText: string;
  selectedDate: any;
  selectedOption: string;
  setSelectedOption: any;
}
function CompanyInfo({ companyInfo, setcompanyLoading, size, logSearchText, selectedDate, selectedOption, setSelectedOption }: CompanyInfoIProps) {
  const [groupActionOpen, setGroupActionOpen] = useState(false);
  const [deleteModaOpen, setdeleteModaOpen] = useState(false);
  const [suspendModaOpen, setSuspendModaOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [showSuccessDeletion, setshowSuccessDeletion] = useState(false);
  const [showSuccessSuspend, setShowSuccessSuspend] = useState(false);
  const [showSuccessEmail, setShowSuccessEmail] = useState(false);
  const [showSuccessResume, setShowSuccessResume] = useState(false);
  const [showSuccessRestoration, setShowSuccessRestoration] = useState(false);
  const [showResumeOption, setshowResumeOption] = useState(false);
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState<string>("");
  const [isCompanySuspended, setisCompanySuspended] = useState({ value: false, id: 0 });
  const [deleteTextColor, setDeleteTextColor] = useState("#EB5757");
  const [restoreModalOpen, setRestoreModalOpen] = useState({ value: false, id: 0 });
  const groupActionRef = useRef<any>();

  const dispatch: AppDispatch | any = useDispatch();

  const { companyForEdit } = useSelector((state: StoreState) => ({
    companyForEdit: state.company.companyForEdit,
  }));

  const handleOpenResumeModal = () => {
    if (companyForEdit?.isSuspended === true) {
      setisCompanySuspended({ value: true, id: 0 });
      setshowResumeOption(true);
    }
  };
  const openRestoreModalHandler = () => {
    setRestoreModalOpen({ ...restoreModalOpen, value: true });
  };
  useEffect(() => {
    if (companyForEdit.isSuspended) {
      setisCompanySuspended({ value: true, id: 0 });
    } else {
      setisCompanySuspended({ value: false, id: 0 });
    }
  }, [companyForEdit.isSuspended]);

  // console.log(isCompanySuspended, "is company suspended or not");
  const handleOutsideClickGroupAction = (event: any) => {
    if (groupActionRef.current && !groupActionRef.current.contains(event.target)) {
      setGroupActionOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickGroupAction);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickGroupAction);
    };
    // eslint-disable-next-line
  }, []);
  const handleReason = (event: any) => {
    setReason(event.target.value);
  };

  const handleCancel = () => {
    if (suspendModaOpen) {
      setSuspendModaOpen(false);
      setMessage("");
    } else if (deleteModaOpen) {
      setdeleteModaOpen(false);
    } else if (emailModalOpen) {
      setEmailModalOpen(false);
    } else if (showResumeOption) {
      setshowResumeOption(false);
      setMessage("");
    } else if (restoreModalOpen.value) setRestoreModalOpen({ ...restoreModalOpen, value: false, id: 0 });
  };
  const { id } = useParams();
  const handleConfirmation = () => {
    if (suspendModaOpen) {
      //suspend selected countries

      let selectedCompanyIdsForSuspension = [companyForEdit._id];
      dispatch(companyActions.suspendSelectedCompanies(selectedCompanyIdsForSuspension, reason))
        .then(() => {
          setReason("");
        })
        .then(() => {
          dispatch(
            companyActions.getCompanyById({
              id: id,
              limit: size,
              searchName: logSearchText,
              startDate: selectedDate.startDate,
              endDate: selectedDate.endDate,
            })
          ).then(() => {
            setcompanyLoading(true);
          });
        });

      setSuspendModaOpen(false);
      setMessage("");
      setShowSuccessSuspend(true);
      setGroupActionOpen(false);
    } else if (showResumeOption) {
      //resume selected companies
      let selectedCompanyIdsForResume = [companyForEdit._id];
      dispatch(companyActions.resumeSuspendedCompanies(selectedCompanyIdsForResume)).then(() => {
        dispatch(
          companyActions.getCompanyById({
            id: id,
            limit: size,
            searchName: logSearchText,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
          })
        ).then(() => {
          setcompanyLoading(true);
        });
      });
      setshowResumeOption(false);
      setMessage("");
      setShowSuccessResume(true);
      setGroupActionOpen(false);
    } else if (deleteModaOpen) {
      // delete selected companies

      let selectedCompanyIdsForDeletion = [companyForEdit._id];
      dispatch(companyActions.deleteSelectedCompanies(selectedCompanyIdsForDeletion)).then(() => {
        dispatch(
          companyActions.getCompanyById({
            id: id,
            limit: size,
            searchName: logSearchText,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
          })
        ).then(() => {
          setcompanyLoading(true);
        });
      });
      setdeleteModaOpen(false);
      setshowSuccessDeletion(true);
      setGroupActionOpen(false);
    } else if (emailModalOpen) {
      let selectedCompaniesToSendEmail = [companyForEdit.email];
      dispatch(companyActions.sendEmailToCompany(selectedCompaniesToSendEmail, { subject: emailSubject, message: emailMessage })).then(() => {
        setEmailSubject("");
        setEmailMessage("");
      });
      setEmailModalOpen(false);
      setShowSuccessEmail(true);
      setGroupActionOpen(false);
    } else if (restoreModalOpen.value === true) {
      const selectedIdsForRestoration = [companyForEdit._id];
      dispatch(deleteCompanyAction.restoreDeletedCompanies(selectedIdsForRestoration)).then(() => {
        dispatch(
          companyActions.getCompanyById({
            id: id,
            limit: size,
            searchName: logSearchText,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
          })
        ).then(() => {
          setcompanyLoading(true);
        });
      });
      setRestoreModalOpen({ ...restoreModalOpen, value: false, id: 0 });
      setGroupActionOpen(false);
      setShowSuccessRestoration(true);
    }
  };
  useEffect(() => {
    //selected company is able to delete if its on free plan, expired trial or paused subscription
    let status = companyForEdit.subscription.currentPlan.status;
    if (status === "Paused" || status === "Expired" || status === "Free") {
      setDeleteTextColor("#EB5757");
    } else {
      setDeleteTextColor("#828282");
    }
    // eslint-disable-next-line
  }, [groupActionOpen, companyForEdit.subscription.currentPlan.status]);

  const handleSuccessConfirmation = () => {
    if (showSuccessDeletion) {
      setshowSuccessDeletion(false);
    } else if (showSuccessEmail) {
      setShowSuccessEmail(false);
    } else if (showSuccessSuspend) {
      setShowSuccessSuspend(false);
    } else if (showSuccessRestoration) {
      setShowSuccessRestoration(false);
    } else if (showSuccessResume) {
      setShowSuccessResume(false);
    }
  };
  useEffect(() => {
    if (companyForEdit.isDeleted) {
      setRestoreModalOpen({ ...restoreModalOpen, value: false });
    } else {
      setRestoreModalOpen({ ...restoreModalOpen, value: true });
    }
    // eslint-disable-next-line
  }, [companyForEdit.isDeleted]);
  const { collapsed } = useSidebar();

  return (
    <div className="side-company-info h-100">
      <div className="action-wrap" ref={groupActionRef}>
        <span onClick={() => setGroupActionOpen(true)}>
          <img src={actionIcon} alt="actions" />
          <img src={actionIcon} alt="actions" />
          <img src={actionIcon} alt="actions" />
        </span>
        {groupActionOpen ? (
          <CompanyActionModal
            suspendModalOpen={suspendModaOpen}
            emailModalOpen={emailModalOpen}
            deleteModaOpen={deleteModaOpen}
            showResumeOption={showResumeOption}
            restoreModalOpen={restoreModalOpen.value}
            openDeleteModalHandler={() => setdeleteModaOpen(true)}
            openEmailModalHandler={() => setEmailModalOpen(true)}
            openSuspendModalHandler={() => setSuspendModaOpen(true)}
            openResumeHandler={handleOpenResumeModal}
            reason={reason}
            handleSuccessConfirmation={handleSuccessConfirmation}
            deletedCompanyView={companyForEdit.isDeleted}
            handleReason={handleReason}
            message={message}
            setMessage={setMessage}
            setEmailMessage={setEmailMessage}
            setEmailSubject={setEmailSubject}
            emailMessage={emailMessage}
            emailSubject={emailSubject}
            onCancel={handleCancel}
            onConfirmation={handleConfirmation}
            isCompanySuspended={isCompanySuspended.value}
            textDeleteColor={deleteTextColor}
            style={{ top: "-10px", postition: "absolute" }}
            left={"1rem"}
            companyOverview
            openRestoreModalHandler={openRestoreModalHandler}
          />
        ) : null}
        <div style={{ position: "relative" }}>
          {showSuccessDeletion ? (
            <SuccessMessage
              style={{ width: collapsed ? "280px" : "250px", left: !collapsed ? "-205px" : "-220px" }}
              setshowSuccessCompletion={setshowSuccessDeletion}
              message="Company deleted successfully "
            />
          ) : (
            ""
          )}
          {showSuccessSuspend ? (
            <SuccessMessage
              style={{ width: collapsed ? "280px" : "250px", left: !collapsed ? "-205px" : "-220px" }}
              setshowSuccessCompletion={setShowSuccessSuspend}
              message="Company suspended successfully "
            />
          ) : null}
          {showSuccessEmail ? (
            <SuccessMessage
              style={{ width: collapsed ? "280px" : "250px", left: !collapsed ? "-205px" : "-220px" }}
              setshowSuccessCompletion={setShowSuccessEmail}
              message="The email was successfully sent"
            />
          ) : null}
          {showSuccessResume ? (
            <SuccessMessage
              style={{ width: collapsed ? "280px" : "250px", left: !collapsed ? "-205px" : "-220px" }}
              setshowSuccessCompletion={setShowSuccessResume}
              message="Company resumed successfully"
            />
          ) : null}
          {showSuccessRestoration ? (
            <SuccessMessage
              style={{ width: collapsed ? "280px" : "250px", left: !collapsed ? "-205px" : "-220px" }}
              setshowSuccessCompletion={setShowSuccessRestoration}
              message="Company restored successfully"
            />
          ) : null}
        </div>
      </div>

      {/* <div style={{ width: "90% !important" }}> */}

      {/* </div> */}

      <div className="company-logo">{companyInfo.image ? <img src={companyInfo.image} width={100} height={100} alt="logo" /> : ""}</div>
      <div className="company-location">
        <div className="name">{companyInfo.name}</div>
        <div className="site">{companyInfo.country}</div>
      </div>
      <div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Business ID</span>
          <span className="right-side">{companyInfo.businessId}</span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Plan</span>
          <span className="right-side">{companyInfo.plan}</span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Status</span>
          <span className="right-side" style={{ color: companyInfo.isSuspended ? "#F2994A" : "#4f4f4f" }}>
            {refactorSuspendStatus(companyInfo.status, companyInfo.isSuspended)}
          </span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Industry</span>
          <span className="right-side">{companyInfo.industry}</span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap" style={{ flexWrap: "wrap" }}>
          <span className="left-side">Email</span>
          <span className="right-side">{companyInfo.email}</span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Phone</span>
          <span className="right-side">{companyInfo.phone}</span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Register Date</span>

          <span className="right-side">{moment(companyInfo.registerDate).format("DD MMM YYYY")}</span>
        </div>
        <div className="d-flex justify-content-between pr-4 details-wrap">
          <span className="left-side">Last Activity</span>
          <span className="right-side">{moment(companyInfo.lastActivity).format("DD MMM YYYY HH:mm")}</span>
        </div>
      </div>
      <hr style={{ width: "241px" }} />
      <div className="list-wrap pb-3">
        <div className={`${selectedOption === "overview" ? "active" : ""}`} onClick={() => setSelectedOption("overview")}>
          Overview
        </div>
        <div
          className={`${selectedOption === "subscription" || selectedOption.includes("subscriptionInvoice") ? "active" : ""}`}
          onClick={() => setSelectedOption("subscription")}
        >
          Subscription
        </div>
        <div
          className={`${selectedOption === "sites" || selectedOption.includes("sites_single") ? "active" : ""}`}
          onClick={() => setSelectedOption("sites")}
        >
          Sites
        </div>
        <div
          className={`${selectedOption === "users" || selectedOption.includes("users_single") ? "active" : ""}`}
          onClick={() => setSelectedOption("users")}
        >
          Users
        </div>{" "}
        <div
          className={`${selectedOption === "roles" || selectedOption.includes("roles_single") ? "active" : ""}`}
          onClick={() => setSelectedOption("roles")}
        >
          Roles
        </div>
        <div
          className={`${selectedOption === "forms" || selectedOption.includes("forms_single") ? "active" : ""}`}
          onClick={() => setSelectedOption("forms")}
        >
          Forms
        </div>
        <div
          className={`${selectedOption === "reports" || selectedOption.includes("reports_single") ? "active" : ""}`}
          onClick={() => setSelectedOption("reports")}
        >
          Reports
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
