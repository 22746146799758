import CustomPagination from "../../companies/CustomPagination";
import SingleDummyChart from "../charts/SingleChart";
import searchIcon from "../../../assets/images/siteSearchIcon.svg";
import Skeleton from "react-loading-skeleton";
import sortArrow from "../../../assets/images/sortArrow.svg";

function UserDetails({
  setSelectedOption,
  userTableHeader,
  users,
  page,
  size,
  total,
  setpage,
  setsize,
  setsearchText,
  handleSearchUsers,
  searchText,
  userChartsData,
  chartsLoading,
  selectedHeader,
  handleArrowSortClick,
  handleSort,
}: {
  setSelectedOption: any;
  userTableHeader: any;
  users: any;
  page: number;
  size: number;
  total: number;
  setpage: any;
  setsize: any;
  setsearchText: any;
  handleSearchUsers: any;
  searchText: any;
  userChartsData: any;
  chartsLoading: any;
  selectedHeader: any;
  handleArrowSortClick: any;
  handleSort: any;
}) {
  return (
    <div>
      <>
        <div className="main-overview-wrap">
          <div className="title-subscription">Users</div>
          <button className="back-btn" onClick={() => window.history.back()}>
            Back
          </button>
        </div>
        <div className="mt-4 user-chart-wrap">
          {!chartsLoading && userChartsData && userChartsData?.userChartToShow ? (
            <SingleDummyChart
              title={`Total ${userChartsData?.userChartToShow[0]?.title}`}
              analyticsXaxisData={userChartsData?.xAxisData}
              className=" shadow-lg mt-3   w-100 "
              totalResult={userChartsData?.userChartToShow[0]?.value}
              color="#6FCF97"
              selectedItem={userChartsData?.userChartToShow[0]}
              dateToShow={true}
            />
          ) : (
            <div className="bg-white circle p-2">
              <div className="d-flex justify-content-between align-items-center">
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
              <Skeleton count={8} />
            </div>
          )}
        </div>
        <div className="payment-card-wrap mt-2 w-100">
          <div className="sites-search-wrap ml-auto mt-3" style={{ width: "137px" }}>
            <img src={searchIcon} alt="searchIcon" />
            <input
              type="text"
              placeholder="Search in users"
              value={searchText}
              onChange={(e: any) => {
                setsearchText(e.target.value);
                handleSearchUsers(e.target.value);
              }}
              className="sites-searchBar"
            />
          </div>
          <div className="mt-2 table-responsive">
            <table className="table-main table user-table">
              <thead>
                <tr className="table-head">
                  {userTableHeader.map((item: any, index: number) => {
                    return (
                      <th key={index}>
                        <span onClick={() => handleSort(item)}> {item.label}</span>
                        {item.label === selectedHeader.label ? (
                          <span className="ml-1">
                            <img
                              id="user-sort-icon"
                              onClick={handleArrowSortClick}
                              src={sortArrow}
                              alt="sort-icon"
                              style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99" }}
                            />
                          </span>
                        ) : null}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {users?.map((item: any, index: number) => {
                  return (
                    <tr
                      className="table-row"
                      key={index}
                      style={{ color: item.isDeleted ? "#EB5757" : "#4F4F4F" }}
                      onClick={() => {
                        setSelectedOption(`users_single_${item._id}`);
                      }}
                    >
                      <td>{item.userBuisnessId}</td>
                      <td>{item.fullName}</td>
                      <td>{item.email}</td>
                      <td>{item?.roleName === "" ? item?.role : item?.roleName}</td>
                      <td>{item.loginAttempts}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <CustomPagination page={page} setSize={setsize} setpage={setpage} size={size} total={total} />
          </div>
        </div>
      </>
    </div>
  );
}

export default UserDetails;
