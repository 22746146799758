import { useEffect, useRef } from "react";
import { Collapse, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import NavToggler from "../../assets/images/icon-nav-toggler.png";
import { Link, useNavigate } from "react-router-dom";
import BrandLogo from "../../assets/images/brand-logo.svg";
import notificationIcon from "../../assets/images/notificationIcon.svg";
import subscriptionIcon from "../../assets/images/subscription.svg";
import contentIcon from "../../assets/images/contentIcon.svg";
import companiesIcon from "../../assets/images/companies.svg";
import securityIcon from "../../assets/images/securityIcon.svg";
import integrationIcon from "../../assets/images/integrationIcon.svg";
import supportIcon from "../../assets/images/supportIcon.svg";
import settingIcon from "../../assets/images/setting.svg";
import usersIcon from "../../assets/images/usersIcon.svg";
import analysisIcon from "../../assets/images/analysisIcon.svg";
import avatar from "../../assets/images/avatar.svg";
import { useSidebar } from "../../Pages/useSidebarProvider";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

import * as authActions from "../.././redux/auth/authAction";
import { refactoredSelectedOption } from "../../helper/helperFunction";

function useOutsideAlerter(ref: any, collapsed?: any, setCollapsed?: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref && ref?.current && !ref?.current?.contains(event.target)) {
        if (!collapsed) {
          setCollapsed(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, collapsed, setCollapsed]);
}
function Header() {
  let wrapperRef = useRef<any>();
  const dispatch: AppDispatch | any = useDispatch();

  const { collapsed, setCollapsed, selectedItem } = useSidebar();
  // const [collapsed, setCollapsed] = useState(false);
  useOutsideAlerter(wrapperRef, collapsed, setCollapsed);
  const toggleNavbar = () => setCollapsed(!collapsed);

  //logout
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate("/");
  };

  return (
    <>
      <header
        style={{
          zIndex: "1000",
          height: "65px",
        }}
        ref={wrapperRef}
      >
        <div>
          <Navbar color="solid" light className="navbar">
            <NavbarToggler onClick={toggleNavbar} className="d-flex align-items-center ">
              <img src={NavToggler} alt="nav-toggler" />
              <NavbarBrand href="/" className="ml-3">
                <img src={BrandLogo} alt="Tamam Logo" />
              </NavbarBrand>
            </NavbarToggler>
            {/* <h4 className="selected-option"> {`COMPANIES${id ? `/ ${selectedOption?.toLowerCase()?.includes("overview")?selectedOption.toUpperCase():""}` : ""}`}</h4> */}

            <h4 className="selected-option"> {`COMPANIES${refactoredSelectedOption(selectedItem)}`}</h4>
            <div>
              <img src={notificationIcon} alt="notifications" />
              <button onClick={handleLogout} className="logout-btn mx-2">
                Logout
              </button>
            </div>

            <Collapse isOpen={!collapsed} navbar>
              <h4 className="mt-2" style={{ marginLeft: "30px" }}>
                CONTROL PANEL
              </h4>
              <Nav navbar className="mt-4">
                <NavItem className={window.location.href.indexOf("/dashboard") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={analysisIcon} alt="IconCompanies" />
                    </span>
                    DASHBOARD
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/companies") || window.location.href.indexOf("/company") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/companies" onClick={() => setCollapsed(false)}>
                    <span className="nav-item-icon">
                      <img src={companiesIcon} alt="IconCompanies" />
                    </span>
                    COMPANIES{" "}
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/subscription") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={subscriptionIcon} alt="IconCompanies" />
                    </span>
                    SUBSCRIPTIONS
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/content") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={contentIcon} alt="IconCompanies" />
                    </span>
                    CONTENT
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/analysis") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={analysisIcon} alt="IconCompanies" />
                    </span>
                    ANALYSIS
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/integration") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={integrationIcon} alt="IconCompanies" />
                    </span>
                    INTEGRATION
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/security") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={securityIcon} alt="IconCompanies" />
                    </span>
                    SECURITY
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/support") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={supportIcon} alt="IconCompanies" />
                    </span>
                    SUPPORT
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/settings") > -1 ? "active" : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={settingIcon} alt="IconCompanies" />
                    </span>
                    SETTINGS
                  </Link>
                </NavItem>
                <NavItem className={window.location.href.indexOf("/users") > -1 ? "active " : ""}>
                  <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                    <span className="nav-item-icon">
                      <img src={usersIcon} alt="IconCompanies" />
                    </span>
                    USERS
                  </Link>
                </NavItem>
                <div className="d-flex  align-items-center justify-content-start w-100 pt-5 text-start px-4">
                  <span className="avatar-wrap">
                    <img src={avatar} alt="avatar" />
                  </span>
                  {/* <small className="mx-2">
                    <span>
                      Hi, <strong>John</strong>
                      <img src={avatar} alt="avatar"/>
                    </span>
                  </small> */}
                </div>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </header>
    </>
  );
}

export default Header;
