import React, { useRef, useState } from "react";
// import dropdownIcon from "../../assets/images/dropdownIcon.svg";
function CustomSingleSelect({
  handleSelectedItem,
  options,
  selectedOption,
  users,
  maxHeight,
}: {
  handleSelectedItem: any;
  options: any;
  selectedOption: any;
  users?: boolean;
  maxHeight?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<any>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="custom-multi-select" ref={selectRef}>
      <div className="select-field" onClick={toggleDropdown}>
        <span className="px-2" style={{ marginBottom: "10px" }}>
          {selectedOption}
        </span>
        <div className={`dropdown-icon ${isOpen ? "open" : ""}`}>
          {/* <img src={dropdownIcon} alt="dropdown" style={{ marginBottom: "10px" }} /> */}
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-dropdown" style={{ padding: "0px !important", height: "100% !important" }}>
          <>
            {options?.length > 0 ? (
              <>
                <div className="options-container pt-2" style={{ maxHeight: maxHeight }}>
                  {" "}
                  {/* Container for options */}
                  {options?.map((option: any, index: number) => (
                    <label key={index} className="option-label px-3">
                      {" "}
                      {/* Added class for styling */}
                      <div onClick={() => handleSelectedItem(option, index)} className="d-flex" style={{ alignItems: "center", cursor: "pointer" }}>
                        {!users ? (
                          <span>{`Dispatch Rule No.${index + 1}`}</span>
                        ) : (
                          <span>
                            {option?.businessId} &nbsp; {option?.userName}
                          </span>
                        )}
                      </div>
                    </label>
                  ))}
                </div>
              </>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "#4f4f4f",
                  width: "100%",
                  height: "100%",
                  marginTop: "1rem",
                }}
              >
                No Options
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}

export default CustomSingleSelect;
